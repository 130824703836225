import React, { Component } from "react";
import { connect } from "react-redux";
import { locus, auth } from "../actions";
import TransitionTable from "./transitiontable";
import { Row, Col, Container, Tabs, Tab, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { CSVLink } from "react-csv";
import "./transitionrisk.css";
import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Dropdown,
  Accordion,
} from "semantic-ui-react";
import { CSVReader } from "react-papaparse";
import upload from "../assets/upload.png";

const buttonRef = React.createRef();
let csvcards = [];
let selectedList = [];

const sample = process.env.REACT_APP_SAMPLE_EXCEL_FILE;

const columns = [
  {
    title: "Company Name",
    key: "company_name",
    dataIndex: "company_name",
  },
  {
    title: "Portfolio Claim On Revenue",
    dataIndex: "portfolio_claim_on_revenue",
    key: "portfolio_claim_on_revenue",
  },
  {
    title: "Carbon FootPrint",
    dataIndex: "carbon_footprint",
    key: "carbon_footprint",
  },
  {
    title: "Weighted Average Carbon Intensity",
    dataIndex: "weighted_average_carbon_intensity",
    key: "weighted_average_carbon_intensity",
  },
  {
    title: "Total Carbon Emission Ownership Weight on Market Cap",
    dataIndex: "total_carbon_emmision_ownership_weight_on_market_cap",
    key: "total_carbon_emmision_ownership_weight_on_market_cap",
  },
  {
    title: "Carbon Intensity",
    dataIndex: "carbon_intensity",
    key: "carbon_intensity",
  },
];
const csvData = [
  [
    "Company Name",
    "Investment (in USD)",
    "NAV%",
    "Market Cap (in USD)",
    "Revenue(in USD)",
    "Scope 1 and 2 GHG emission (in t CO2e)",
    "Region",
    "Sector",
  ],
];

class TransitionRisk extends Component {
  state = {
    portfolio_value: "",
    portfolio_name: "",
    selectedTags: [],
    allTags: [],
    csv: "",
    locations: [],
    loading: false,
    modalOpen: false,
  };

  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleFileLoad = (data) => {
    csvcards = [];
    for (let i = 1; i < data.length - 1; i++) {
      console.log("filename", data);

      if ((data[i].data[5] = " ")) {
        data[i].data[5] = 0;
      }

      csvcards.push({
        company_name: data[i].data[0],
        investment_in_usd: data[i].data[1],
        nav_in_perc: data[i].data[2],
        market_cap_in_usd: data[i].data[3],
        revenue_in_usd: data[i].data[4],
        scope_1_and_2_GHG_emission: data[i].data[5],
        region: data[i].data[6],
        sector: data[i].data[7],
      });
    }
    this.setState({ allTags: csvcards }, () =>
      console.log("multiple", this.state.allTags)
    );
  };

  handelOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };
  addtoSelected(asset) {
    selectedList.push(asset);
    var array = [...this.state.allTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ allTags: array });
    }
    this.setState({ selectedTags: selectedList, locations: selectedList });
  }
  remove(asset) {
    var array = [...this.state.selectedTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array, locations: array }, () =>
        console.log("remove", this.state.selectedTags)
      );
    }
    this.state.allTags.push(asset);
  }
  addAll = () => {
    var array = [...this.state.allTags];
    this.setState({ selectedTags: array, locations: array });
  };
  clearAll = () => {
    this.setState({ selectedTags: "", locations: "", allTags: "" });
  };

  handleTransition = (data) => {
    let formdata = new FormData();
    formdata.append("portfolio_name", this.state.portfolio_name);
    formdata.append("portfolio_value", this.state.portfolio_value);
    formdata.append("companies_data", JSON.stringify(data));

    this.props.getTransition(formdata);
  };
  handleChange = (e) => {
    console.log("csv", e.target.files[0]);
    this.setState({ csv: e.target.files[0] });
  };

  handleFileTransition = () => {
    let formdata = new FormData();
    formdata.append("portfolio_name", this.state.portfolio_name);
    formdata.append("portfolio_value", this.state.portfolio_value);
    formdata.append("uploaded_file", this.state.csv);

    this.props.getTransition(formdata);
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
    });

  onSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    formdata.append("companies_data", JSON.stringify(this.state.locations));

    formdata.append("portfolio_name", this.state.portfolio_name);
    formdata.append("portfolio_value", this.state.portfolio_value);

    this.props.getTransition(formdata);
  };

  render() {
    console.log("env", this.props.errors);

    var csvtags = [];
    if (csvcards.length > 0) {
      for (let i = 0; i < this.state.allTags.length; i++) {
        csvtags.push(
          <Label style={{ display: "block" }}>
            {this.state.allTags[i]["company_name"]}{" "}
            <Icon
              name="plus"
              onClick={() => this.addtoSelected(this.state.allTags[i])}
              style={{ float: "right" }}
            />
          </Label>
        );
      }
    }
    return (
      <>
        <Container
          fluid
          style={{ backgroundColor: "#18202F" }}
          className="sticky-top"
        >
          <Row>
            <br />
            <Breadcrumb>
              <Breadcrumb.Item>Transition Risk</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <Tabs defaultActiveKey="carbon">
          <Tab eventKey="carbon" title="Carbon Module" id="tritab1">
            <Tabs defaultActiveKey="input">
              <Tab title="Input Data" eventKey="input" id="subtab1">
                <Row style={{ padding: "1%" }}>
                  <Col lg={12} className="transitiontable">
                    <h5 class="head1">Input Data</h5>
                    <Row>
                      <Col lg={8}></Col>
                      <Col lg={2}>
                        {" "}
                        <div>
                          <label></label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Portfolio Value (in USD)"
                            value={this.state.portfolio_value}
                            onChange={(e) =>
                              this.setState({ portfolio_value: e.target.value })
                            }
                          />
                          {!this.state.portfolio_value ? (
                            <p style={{ color: "#e85151" }}>Required Field</p>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div>
                          <label id="pflabel2"></label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Portfolio Name"
                            value={this.state.portfolio_name}
                            onChange={(e) =>
                              this.setState({ portfolio_name: e.target.value })
                            }
                          />
                          {!this.state.portfolio_name ? (
                            <p style={{ color: "#e85151" }}>Required Field</p>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row></Row>

                    {this.props.transition.error ? (
                      <p style={{ color: "red" }}>
                        {this.props.transition.error}
                      </p>
                    ) : null}
                    <TransitionTable
                      handleRisk={this.handleTransition}
                      portfolio_value={this.state.portfolio_value}
                    />
                  </Col>
                </Row>

                <Row style={{ padding: "1%" }}>
                  <Col lg={12}>
                    <Row>
                      <Col lg={10}>
                        <p className="trparanote">
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                              color: "#18202F",
                            }}
                          >
                            Note 1:{" "}
                          </span>{" "}
                          NAV% equal to Investment Value of the Company /
                          Portfolio Value.
                          <br />
                          <br />
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                              color: "#18202F",
                            }}
                          >
                            Note 2:{" "}
                          </span>{" "}
                          If Scope 1 and 2 GHG Emission of the Company is not
                          available, the User can leave this fill blank. Please
                          provide additional data for “Region” and “Sector”,
                          then BlueSwan platform will project the Scope 1 and 2
                          GHG Emission for the User. The result will be shown in
                          the output data table once the user clicks the
                          “Submit” button for this “Input Data Table".
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
              <Tab title="Upload CSV" eventKey="csv" id="subtab2">
                <Row style={{ padding: "1%" }}>
                  <Col lg={8} sm={12} xs={12}>
                    <h3>
                      <span
                        className={"dark-mode-primary-color"}
                        style={{
                          color: "#18202F",
                          fontSize: "22px",
                          fontWeight: "400",
                          fontFamily: "montserrat",
                        }}
                      >
                        Upload CSV <Icon name="file alternate" />
                        <Button
                          style={{
                            color: "#18202F",
                            backgroundColor: "#355ed814",
                          }}
                        >
                          <span className={"dark-mode-primary-color"}>
                            <a href={sample} style={{ color: "#18202F" }}>
                              Download Sample CSV
                            </a>
                          </span>
                        </Button>
                      </span>
                    </h3>

                    {this.props.transition.error ? (
                      <p style={{ color: "red" }}>
                        {this.props.transition.error}
                      </p>
                    ) : null}

                    <div>
                      <Form.Field
                        id="forminpt3"
                        control={Input}
                        value={this.state.portfolio_name}
                        onChange={(e) =>
                          this.setState({ portfolio_name: e.target.value })
                        }
                        style={{ border: "0px" }}
                        placeholder="Portfolio Name"
                      />

                      <Form.Field
                        id="forminpt2"
                        control={Input}
                        value={this.state.portfolio_value}
                        onChange={(e) =>
                          this.setState({ portfolio_value: e.target.value })
                        }
                        style={{ border: "0px" }}
                        placeholder="Portfolio Value (in USD)"
                      />
                    </div>
                    <br />
                    <br />
                    <br />
                    <div>
                      <label
                        style={{
                          color: "#18202F",
                          fontSize: "22px",
                          fontWeight: "400",
                          fontFamily: "montserrat",
                        }}
                      >
                        File Input
                      </label>
                      <Input
                        id="fileslct"
                        type="file"
                        ref={(input) => (this.filesInput = input)}
                        name="file"
                        onChange={this.handleChange}
                      />
                    </div>

                    {/* <Button
                  style={{
                    backgroundColor: "#f88b8b",
                    color: "white",
                    float: "right",
                  }}
                  onClick={this.handlevideoOpen}
                >
                  <Icon name="lightbulb" />
                  How to Upload
                </Button>

                    <Button
                      style={{
                        color: "#18202F",
                        backgroundColor: "#f6f8fa",
                        border: "0.5px solid #18202F",
                      }}
                    >
                      <span className={"dark-mode-primary-color"}>
                        <a href={sample}>Download Sample CSV</a>
                      </span>
                    </Button>

                    <br />
                    <br />

                    <CSVReader
                      ref={buttonRef}
                      onFileLoad={this.handleFileLoad}
                      onError={this.handleOnError}
                      noClick
                      noDrag
                    >
                      {({ file }) => (
                        <aside
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "black",
                              height: 45,
                              lineHeight: 2.5,
                              marginTop: 0,
                              marginBottom: 0,
                              paddingLeft: 5,
                              paddingTop: 3,
                              width: "80%",
                              borderRadius: 0,
                              backgroundColor: "transparent",
                            }}
                          >
                            <Header
                              class="asset"
                              as="h5"
                              style={{ padding: "10px" }}
                            >
                              <span className="dark-mode-primary-color">
                                No file selected
                              </span>
                            </Header>
                            <button
                              style={{
                                float: "right",
                                backgroundColor: "transparent",
                                border: "0px",
                                fontSize: "10px",
                                color: "grey",
                                marginTop: "-50px",
                              }}
                              onClick={this.handleRemoveFile}
                            >
                              <Icon
                                name="close"
                                style={{ color: "white", padding: "8px" }}
                                size="big"
                              />
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={this.handleOpenDialog}
                            style={{
                              borderRadius: 0,
                              marginLeft: 0,
                              marginRight: 0,
                              width: "10%",

                              paddingRight: 0,
                              border: 0,
                              borderWidth: 1,
                              borderColor: "black",
                              borderStyle: "solid",
                              backgroundColor: "white",
                              color: "grey",

                              height: "45px",
                            }}
                          >
                            <Image
                              src={upload}
                              style={{
                                padding: "10%",
                                width: "30px",
                                height: "30px",
                              }}
                              color="grey"
                              centered
                            />
                          </button>
                        </aside>
                      )}
                    </CSVReader>
                    <br />
                    <br />
                    <Grid.Row>
                      <Grid.Column
                        className="csvbox"
                        style={{ width: "42.5%" }}
                      >
                        <h3>All Assets</h3>
                        <div class="border">{csvtags}</div>
                      </Grid.Column>
                      <Grid.Column
                        style={{ width: "12.5%" }}
                        verticalAlign="middle"
                      >
                        <Button
                          onClick={this.addAll}
                          style={{
                            position: "absolute",
                            top: "60%",
                            padding: "1%",
                            marginLeft: "1%",
                            backgroundColor: "#18202F",
                            color: "white",
                            fontSize: "0.9rem",
                          }}
                        >
                          Add All
                        </Button>
                        <Button
                          onClick={this.clearAll}
                          style={{
                            position: "absolute",
                            top: "65%",
                            padding: "1%",
                            marginLeft: "1%",
                            backgroundColor: "#18202F",
                            color: "white",
                            fontSize: "0.9rem",
                          }}
                        >
                          Clear All
                        </Button>
                      </Grid.Column>
                      <Grid.Column
                        className="csvbox"
                        style={{ width: "42.5%" }}
                      >
                        <h3>Selected Assets</h3>
                        <div class="border">
                          {this.state.selectedTags.length > 0
                            ? this.state.selectedTags.map((asset, index) => (
                                <Label key={index} style={{ display: "block" }}>
                                  {asset["company_name"]}
                                  <Icon
                                    name="close"
                                    onClick={() => this.remove(asset)}
                                  />
                                </Label>
                              ))
                            : null}
                        </div>
                       
                      </Grid.Column>
                        </Grid.Row>*/}
                    <br />
                    <br />
                    <Button
                      disabled={
                        this.state.portfolio_name.length < 1 ||
                        this.state.portfolio_value.length < 1
                      }
                      style={{
                        backgroundColor: "#18202F",
                        marginLeft: "40%",
                        color: "white",
                      }}
                      onClick={this.handleFileTransition}
                      primary
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Tab>
          {/*<Tab
            eventKey="other"
            title="Other Environmental Metrics"
            id="tritab2"
          ></Tab>*/}
        </Tabs>
        <Modal open={this.state.modalOpen} onClose={this.handleClose} closeIcon>
          <Modal.Header className="portfolio-header">
            Create Carbon Module Project
          </Modal.Header>
          <Modal.Content scrolling></Modal.Content>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,
    transition: state.locus.transition,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTransition: (formdata) => {
      dispatch(locus.getTransition(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransitionRisk);
