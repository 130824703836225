// ChangelogPage.jsx
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Container } from "react-bootstrap";

const ChangelogPage = () => (
  <Container>
    <br />
    <p
      style={{
        textAlign: "center",
        fontSize: "56px",

        marginTop: "1%",
      }}
    >
      Change log
    </p>

    <VerticalTimeline layout={"2-columns"}>
      <VerticalTimelineElement
        className="vertical-timeline-element--update"
        contentArrowStyle={{ borderRight: "7px solid  #18202F" }}
        date="May 2024"
        iconStyle={{
          background: "#18202F",
          color: "#fff",
        }}
      >
        <h3 className="vertical-timeline-element-title">
          Version 3.0 - Feature Update
        </h3>
        <div
          style={{
            backgroundColor: "rgba(51, 182, 121, 0.2)",

            border: "2px solid green",
            padding: "10px",
            marginBottom: "20px",
          }}
        >
          <h4>Added Features</h4>
          <ul
            style={{
              listStyleType: "disc",
              paddingLeft: "20px",
              lineHeight: "2em",
            }}
          >
            <li>Updated user interface</li>
            <li>Building level flood adaptation (Mitigation)</li>
            <li>Allows three (3) different levels of flood mitigation.</li>
            <li>Integrated into both Hazard and Financial analysis.</li>
            <li>Applicable for Rainfall and River Flood</li>
            <li>
              Building footprint database generated covering 1.3 billion
              buildings globally integrating Microsoft, Google and HK footprint
              data.
            </li>
            <li>
              New damage functions specific to asset types (infrastructure) and
              regions
            </li>
            <li>Ability to add basement as an asset level variable.</li>
            <li>Ability to share projects within an organization.</li>
          </ul>
        </div>

        {/* Improvements Section */}
        <div
          style={{
            backgroundColor: "rgba(255, 235, 59, 0.2)", // Yellow with opacity
            // Green with opacity
            border: "2px solid #18202F",
            padding: "10px",
            marginBottom: "20px",
          }}
        >
          <h4>Improvements</h4>
          <ul
            style={{
              listStyleType: "disc",
              paddingLeft: "20px",
              lineHeight: "2em",
            }}
          >
            <li>
              Enhancements to calculation of business interruption days for
              Floods and Typhoons
            </li>
          </ul>

          <h4>Hazard Resolution:</h4>
          <ul
            style={{
              listStyleType: "disc",
              paddingLeft: "20px",
              lineHeight: "2em",
            }}
          >
            <li>Storm Surge</li>
            <li>30m Globally (FABDEM)</li>
            <li>Rainfall Flood</li>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                lineHeight: "2em",
              }}
            >
              <li>Malaysia, Singapore, Mongolia, Turkey - 30m</li>
              <li>Eastern Coast of Australia – 5m</li>
              <li>London, UK – 2m</li>
            </ul>
          </ul>
        </div>

        {/* Added Features Section */}
      </VerticalTimelineElement>
    </VerticalTimeline>
  </Container>
);

export default ChangelogPage;
