import React, { Component } from "react";
import {
  Header,
  Icon,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Progress,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
  Card,
} from "semantic-ui-react";
import whiteswan from "../assets/logo1.png";
import { Layout, Menu } from "antd";
import { auth } from "../actions";
import { connect } from "react-redux";

import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Nav, NavItem, Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";
import Profile from "../assets/profile.png";
import "./sidebar.css";

import "react-pro-sidebar/dist/css/styles.css";

class SideNavbar extends Component {
  handleLogout = () => {
    this.props.logout();
  };
  handlelink = (value) => {
    this.props.history.push(value);
  };
  render() {
    const { Header, Content, Footer, Sider } = Layout;

    let loc = window.location.href.split("/")[3];

    console.log("locsx", loc);
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={{ marginTop: "0" }}
      >
        <div className="logo" />
        {loc ? (
          <Menu theme="dark" mode="inline">
            <Menu.Item key="1" style={{ marginTop: "15%" }}>
              <Image src={whiteswan} size="medium" centered />
            </Menu.Item>
            <Menu.Item
              key="2"
              style={{ marginTop: "35%" }}
              className={loc == "project" ? "ant-menu-item-selected" : null}
            >
              <NavItem>
                <NavLink to="/project">
                  <i className="iconsminds-file" />
                  <span id="menu.main">Project</span>
                </NavLink>
              </NavItem>
            </Menu.Item>
            {loc == "dashboard" ? (
              <Menu.Item
                key="3"
                className={loc == "dashboard" ? "ant-menu-item-selected" : null}
              >
                <NavItem>
                  <NavLink to="/dashboard">
                    <i className="iconsminds-dashboard" />
                    <span id="menu.main">Dashboard</span>
                  </NavLink>
                </NavItem>
              </Menu.Item>
            ) : null}
            <Menu.Item
              key="4"
              className={loc == "location" ? "ant-menu-item-selected" : null}
            >
              <NavItem>
                <NavLink to="/location">
                  <i className="iconsminds-home" />
                  <span id="menu.main">Physical Risk</span>
                </NavLink>
              </NavItem>
            </Menu.Item>
            <Menu.Item
              key="5"
              className={loc == "realestate" ? "ant-menu-item-selected" : null}
            >
              <NavItem>
                <NavLink to="/realestate">
                  <i className="iconsminds-building" />
                  <span id="menu.main">Real Estate</span>
                </NavLink>
              </NavItem>
            </Menu.Item>
            {/*  <Menu.Item key="6" >
          <NavItem>
                      <NavLink to="/invoice" >
                        <i className="iconsminds-invoice"/><span id="menu.main">Invoice</span>
                      </NavLink>
                    </NavItem>
    </Menu.Item>*/}
            <Menu.Item>
              <NavItem>
                <NavLink to="/transition">
                  <i className="iconsminds-pantone" />
                  <span id="menu.main">Transition Risk</span>
                </NavLink>
              </NavItem>
            </Menu.Item>
            <Menu.Item
              key="8"
              className={loc == "methodology" ? "ant-menu-item-selected" : null}
            >
              <NavItem>
                <NavLink to="/methodology">
                  {/*<embed src="../assets/Methodology.pdf#toolbar=0"></embed>*/}
                  <i className="iconsminds-methodology" />
                  <span id="menu.main"> Methodology</span>
                </NavLink>
              </NavItem>
            </Menu.Item>
            <Menu.Item key="9">
              <NavItem>
                <a
                  to={{ pathname: "https://docs.intensel.net/" }}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className={loc == "apidocs" ? "ant-menu-item-selected" : null}
                >
                  <i className="iconsminds-pantone" />
                  <span id="menu.main">Api docs</span>
                </a>
              </NavItem>
            </Menu.Item>
            <Menu.Item key="10">
              <NavItem>
                <Button className="shadow-soft" onClick={this.handleLogout}>
                  <i className="iconsminds-logout" />
                  <span id="menu.main">Log Out</span>
                </Button>
              </NavItem>
            </Menu.Item>

            {/*<Menu.Item key="10">
              <NavItem className="logo" style={{ marginTop: "60%" }}>
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Powered By
                </p>
                <Image src={logo} size="small" style={{ padding: "7.5%" }} />
              </NavItem>
            </Menu.Item>*/}
          </Menu>
        ) : null}
      </Sider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.auth.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(auth.logout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNavbar);
