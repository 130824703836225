const initialState = {
  locus: [],
  errors: {},
  isLoading: true,
  assets: [],
  permissions: [],
  name: [],
  company_name: [],
  company_location: [],
  point: [],
  indices: [],
  index: [],
  transition: [],
  sbti: [],
  modify_transition: [],
  notification: [],
  property: [],
  keyword: [],
  faqs: [],
  map: [],
  map_response: [],
  download_map: [],
  download_map_value: [],
  dataset: [],
};

export default function locus(state = initialState, action) {
  switch (action.type) {
    case "LOCATION_ADDING":
      return { ...state, isLoading: true };
    case "CREATE_MAP":
      return { ...state, isLoading: true };
    case "ADD_LOCATION":
      localStorage.setItem("variables", JSON.stringify(action.locus.variables));
      localStorage.setItem(
        "property_types",
        JSON.stringify(action.locus.property_types)
      );

      return { ...state, locus: action.locus, isLoading: false };
    case "MAP_ADD":
      return { ...state, map: action.map, isLoading: false };
    case "ADD_INDEX":
      return { ...state, index: action.index, isLoading: false };
    case "GET_TRANSITION":
      return { ...state, transition: action.transition, isLoading: false };
    case "MODIFY_TRANSITION":
      return { ...state, modify_transition: action.modify_transition };
    case "GET_SBTI":
      return { ...state, sbti: action.sbti, isLoading: false };
    case "NOTIFICATION_SUCCESS":
      return { ...state, notification: action.notification, isLoading: false };
    case "GENERATE_SUCCESS":
      return { ...state, map_response: action.map_response, isLoading: false };
    case "DOWNLOAD_MAP_SUCCESS":
      return { ...state, download_map: action.download_map, isLoading: false };
    case "DOWNLOAD_MAP_VALUE_SUCCESS":
      return {
        ...state,
        download_map_value: action.download_map_value,
        isLoading: false,
      };
    case "DATASET_SUCCESS":
      return {
        ...state,
        dataset: action.dataset,
        isLoading: false,
      };
    case "ADD_NONBUILDING":
      return { ...state, locus: action.locus, isLoading: false };
    case "POST_INDICES":
      return { ...state, indices: action.indices };
    case "FETCH_LOCATION":
      return { ...state, locus: action.locus, isLoading: true };
    case "FETCH_COORDINATES":
      return { ...state, point: action.point };
    case "FETCH_SEARCH":
      return { ...state, name: action.name };
    case "FETCH_COMPANY_SEARCH":
      return { ...state, company_name: action.company_name };
    case "FETCH_COMPANY_LOCATION":
      return { ...state, company_location: action.company_location };
    case "PROPERTY_RISK":
      return { ...state, property: action.property, isLoading: false };
    case "GET_PROPERTY_RISK":
      return { ...state, property: action.property, isLoading: false };
    case "KEYWORD":
      return { ...state, keyword: action.keyword, isLoading: false };
    case "FAQS":
      return { ...state, faqs: action.faqs, isLoading: false };
    case "FETCH_PERMISSION":
      return { ...state, permissions: action.permissions };
    case "FETCH_ASSET":
      return { ...state, assets: action.assets };
    case "AUTHENTICATION_ERROR":
    case "LOCATION_FAIL":
      return { ...state, errors: action.data, locus: null, isLoading: false };
    case "MAP_FAIL":
      return { ...state, errors: action.data, map: null, isLoading: false };
    case "PERMISSION_FAIL":
      return { ...state, errors: action.data, permissions: null };
    case "SEARCH_FAIL":
      return { ...state, errors: action.data, name: null };
    case "COMPANY_SEARCH_FAIL":
      return { ...state, errors: action.data, company_name: null };
    case "COMPANY_LOCATION_FAIL":
      return { ...state, errors: action.data, company_location: null };
    case "NONBUILDING_FAIL":
      return { ...state, errors: action.data, locus: null, isLoading: false };
    case "COORDINATES_FAIL":
      return { ...state, errors: action.data, point: null };
    case "INDICES_FAIL":
      return { ...state, errors: action.data, indices: null };
    case "NOTIFICATION_FAIL":
      return { ...state, errors: action.data, notification: null };
    case "GENERATE_FAIL":
      return { ...state, errors: action.data, map_response: null };
    case "DOWNLOAD_MAP_FAIL":
      return { ...state, errors: action.data, download_map: null };
    case "DOWNLOAD_MAP_VALUE_FAIL":
      return { ...state, errors: action.data, download_map_value: null };
    case "DATASET_FAIL":
      return { ...state, errors: action.data, dataset: null };
    case "INDEX_FAIL":
      return { ...state, errors: action.data, index: null };
    case "SBTI_FAIL":
      return { ...state, errors: action.data, index: null };
    case "FAQS_FAIL":
      return { ...state, errors: action.data, faqs: null };
    case "PROPERTY_RISK_FAIL":
      return { ...state, errors: action.data, property: null };
    case "GET_PROPERTY_RISK_FAIL":
      return { ...state, errors: action.data, property: null };
    case "KEYWORD_FAIL":
      return { ...state, errors: action.data, keyword: null };
    case "TRANSITION_FAIL":
      return { ...state, errors: action.data, transition: null };
    case "MODIFY_TRANSITION_FAIL":
      return { ...state, errors: action.data, modify_transition: null };
    default:
      return state;
  }
}
