import * as React from "react";
import { useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker, Popup, FlyToInterpolator, Layer, Source } from "react-map-gl";
import data from "../constants/menu";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax

const parkLayer = {
  id: "3d-buildings",
  source: "composite",
  "source-layer": "building",
  filter: ["==", "extrude", "true"],
  type: "fill-extrusion",
  minzoom: 15,
  paint: {
    "fill-extrusion-color": "#aaa",

    // Use an 'interpolate' expression to
    // add a smooth transition effect to
    // the buildings as the user zooms in.
    "fill-extrusion-height": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "height"],
    ],
    "fill-extrusion-base": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "min_height"],
    ],
    "fill-extrusion-opacity": 0.9,
  },
};

const PropertyMap = (props) => {
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 450,
    latitude: props.data[0].lat,
    longitude: props.data[0].lon,
    zoom: 15.5,
  });

  let feature = [];

  for (let i = 0; i < props.data.length; i++) {
    let locations = [];

    feature.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [props.data[i].lon, props.data[i].lat],
      },
      properties: {
        address: props.data[i].name,
        flood_score: props.data[i].flood_score,
        typhoon_score: props.data[i].typhoon_score,
      },
    });
  }

  var stores = {
    type: "FeatureCollection",
    features: feature,
  };

  /**
   * Assign a unique id to each store. You'll use this `id`
   * later to associate each point on the map with a listing
   * in the sidebar.
   */
  stores.features.forEach(function (store, i) {
    store.properties.id = i;
  });

  const listings = stores.features.map((listing, index) => {
    return (
      <a onClick={() => handlezoom(listing.geometry)}>
        {listing.properties.address}
      </a>
    );
  });

  const handlezoom = (geometry) => {
    setViewport({
      ...viewport,
      zoom: 18,
      latitude: geometry.coordinates[1],
      longitude: geometry.coordinates[0],
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  let html = [];
  for (let i = 0; i < stores.features.length; i++) {
    html.push(
      <tr>
        <td>{stores.features[i].properties.address.slice(0, 20)}</td>
        <td>{stores.features[i].properties.loss} mil $</td>
      </tr>
    );
  }

  const markers = stores.features.map((marker, index) => (
    <Marker
      key={`marker-${index}`}
      longitude={marker.geometry.coordinates[0]}
      latitude={marker.geometry.coordinates[1]}
      onClick={() => handlezoom(marker.geometry)}
    ></Marker>
  ));

  const popup = stores.features.map((popup, index) => (
    <Popup
      longitude={popup.geometry.coordinates[0]}
      latitude={popup.geometry.coordinates[1]}
      anchor="top"
    >
      <div>
        <h5>Asset & Risk Scores</h5>
        <table>
          <tr>
            <td>Asset Name</td>
            <td>Flood Scores</td>
            <td>Typhoon Scores</td>
          </tr>
          <tr>
            <td>{popup.properties.address}</td>
            <td>{popup.properties.flood_score}</td>
            <td>{popup.properties.typhoon_score}</td>
          </tr>
        </table>
      </div>
    </Popup>
  ));

  return (
    <div>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="450px"
        mapStyle="mapbox://styles/mapbox/light-v10"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        mapboxApiAccessToken="pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw"
      >
        {markers}
        {popup}
        <Layer {...parkLayer} />
      </ReactMapGL>
    </div>
  );
};
export default PropertyMap;
