import React, { Component } from "react";

import { Row, Col } from "react-bootstrap";
import { ResponsiveBar } from "@nivo/bar";
import { Column } from "@ant-design/charts";

import { Button } from "semantic-ui-react";

import { Modal } from "semantic-ui-react";

let data = [];
let colors = [];
let config = {};

export default class IndexYEARDonut extends Component {
  state = {
    activeIndex: 0,
    piedata: "",
    key_finding: false,
    analysis: "rcp",
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
      piedata: "",
    });
  };
  handleKeyFindings = () => {
    this.setState({
      key_finding: true,
    });
  };
  handleClose = () => {
    this.setState({
      key_finding: false,
    });
  };

  render() {
    this.state.piedata = this.props.data;
    this.state.analysis = this.props.analysis;

    let x = 0;
    let y = 0;
    let z = 0;
    let w = 0;
    let a = 0;
    let b = 0;
    let c = 0;
    let max = 0;

    if (this.state.analysis == "rcp" && this.state.piedata) {
      x = this.state.piedata["company"]["year_loss_for_scenario"]["2020"];
      y = this.state.piedata["company"]["year_loss_for_scenario"][`2030`];
      z = this.state.piedata["company"]["year_loss_for_scenario"][`2050`];
      a = this.state.piedata["index"]["year_loss_for_scenario"]["2020"];
      b = this.state.piedata["index"]["year_loss_for_scenario"][`2030`];
      c = this.state.piedata["index"]["year_loss_for_scenario"][`2050`];
      max = this.state.piedata["index"]["year_max"];

      if (x > 1000 || y > 1000 || z > 1000) {
        data = [
          {
            name: "Index",
            Year: "2020",
            Loss: parseFloat(a.toFixed(2)),
          },

          {
            name: "Index",
            Year: "2030",
            Loss: parseFloat(b.toFixed(2)),
          },

          {
            name: "Index",
            Year: "2050",
            Loss: parseFloat(c.toFixed(2)),
          },
        ];
        config = {
          data,
          isGroup: true,
          seriesField: "Year",
          xField: "name",

          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            position: "top",

            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8cb0f8", "#18202F"],
        };
      } else {
        data = [
          {
            name: "Index",
            Year: "2020",
            Loss: parseFloat(a.toFixed(2)),
          },

          {
            name: "Index",
            Year: "2030",
            Loss: parseFloat(b.toFixed(2)),
          },

          {
            name: "Index",
            Year: "2050",
            Loss: parseFloat(c.toFixed(2)),
          },
        ];
        config = {
          data,
          isGroup: true,
          seriesField: "Year",
          xField: "name",

          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            position: "top",

            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8cb0f8", "#18202F"],
        };
      }
    } else if (
      this.state.analysis === "ssp" &&
      this.state.piedata["rcp_constant"]["2100"]
    ) {
      x = this.state.piedata["rcp_constant"]["2020"];
      y = this.state.piedata["rcp_constant"][`2030`];
      z = this.state.piedata["rcp_constant"][`2050`];
      w = this.state.piedata["rcp_constant"]["2100"];
      max = this.state.piedata["rcp_max"];

      if (x > 1000 || y > 1000 || z > 1000 || w > 1000) {
        data = [
          //{name:'Exposure',value:a.toFixed(2),dot:a.toFixed(2)},
          {
            Year: "2020",
            Loss: parseFloat(x.toFixed(2)),
          },
          {
            Year: "2030",
            Loss: parseFloat(y.toFixed(2)),
          },
          {
            Year: "2050",
            Loss: parseFloat(z.toFixed(2)),
          },
          {
            Year: "2100",
            Loss: parseFloat((w / 1000).toFixed(2)),
          },
        ];
        config = {
          data,
          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          xField: "Year",

          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            // 可手动配置 label 数据标签位置
            position: "top",
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8CB0F8", "#18202F", "#256EFF"],
        };
      } else {
        data = [
          {
            Year: "2020",
            Loss: parseFloat(x.toFixed(2)),
          },
          {
            Year: "2030",
            Loss: parseFloat(y.toFixed(2)),
          },
          {
            Year: "2050",
            Loss: parseFloat(z.toFixed(2)),
          },
          {
            Year: "2100",
            Loss: parseFloat(w.toFixed(2)),
          },
        ];
        config = {
          data,
          yAxis: {
            title: {
              text: "Loss in Million USD",
            },
            max: max + 0.2 * max,
          },
          xField: "Year",

          yField: "Loss",
          minColumnWidth: 20,
          maxColumnWidth: 20,
          legend: {
            layout: "vertical",
            position: "right",
          },
          label: {
            // 可手动配置 label 数据标签位置
            position: "top",
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
              fill: "#000000",
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          colorField: "Year",
          seriesField: "Year",

          color: ["#f47a7b", "#8CB0F8", "#18202F", "#256EFF"],
        };
      }
    }

    return (
      <div>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          {config.data && config.data.length > 0 ? (
            <Col
              md={12}
              xs={12}
              sm={12}
              className="graph"
              style={{ height: "35vh" }}
            >
              {this.state.analysis == "ssp" ? (
                <Column {...config} />
              ) : (
                <Column {...config} />
              )}
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}
