import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Progress,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
} from "semantic-ui-react";

/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl";

let feature = [];
let locations = [];

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";

class TyphoonClusterMap extends Component {
  constructor(props) {
    super(props);
    this.worldmapContainer = React.createRef();
  }

  componentDidMount() {
    if (this.worldmapContainer.current && this.props.data) {
      const map = new mapboxgl.Map({
        container: this.worldmapContainer.current,
        /*style: {
          version: 8,
          sources: {
            "raster-tiles": {
              type: "raster",
              tiles: [
                "https://intensel-tiles.s3.amazonaws.com/hazard/typhoon/rcp/85_2050/viridis/{z}/{x}/{y}.png",
              ],
              scheme: "tms",
              tileSize: 256,
            },
          },
          layers: [
            {
              id: "simple-tiles",
              type: "raster",
              source: "raster-tiles",
              minzoom: 0,
              maxzoom: 22,
            },
          ],
        },*/
        style: "mapbox://styles/mapbox/light-v10",
        center: [
          this.props.data[0].location.longitude,
          this.props.data[0].location.latitude,
        ],

        zoom: 2,
        scrollZoom: true,
      });

      feature = [];
      let hazard = this.props.hazard;

      let n = this.props.data.length;

      for (let i = 0; i < n; i++) {
        locations.push({
          name: this.props.data[i].location.name,
          lat: this.props.data[i].location.latitude,
          long: this.props.data[i].location.longitude,
          total_loss: this.props.data[i].data.total_loss,
          percentage: this.props.data[i].data.percentage,
          top_hazard_string: this.props.data[i].data.top_hazard_string,
          hazard_loss: this.props.data[i].data,
        });

        feature.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              this.props.data[i].location.longitude,
              this.props.data[i].location.latitude,
            ],
          },
          properties: {
            locations: locations[i],
          },
        });
      }

      var stores = {
        type: "FeatureCollection",
        features: feature,
      };

      /**
       * Assign a unique id to each store. You'll use this `id`
       * later to associate each point on the map with a listing
       * in the sidebar.
       */
      stores.features.forEach(function (store, i) {
        store.properties.id = i;
        let hazard_s = store.properties.locations.hazard_loss[hazard];
        if (hazard_s >= 100) {
          store.properties["marker-color"] = "#a4222b";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s < 100 && hazard_s > 76) {
          store.properties["marker-color"] = "#c92128";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s < 75 && hazard_s > 51) {
          store.properties["marker-color"] = "#ffae3a";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s < 50 && hazard_s > 26) {
          store.properties["marker-color"] = "#fce97f";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s < 25 && hazard_s > 1) {
          store.properties["marker-color"] = "#f9ffb8";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s >= 0 && hazard_s < 1) {
          store.properties["marker-color"] = "#fdffe5";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        }
      });

      console.log("stores", stores);

      function addMarkers() {
        /* For each feature in the GeoJSON object above: */
        stores.features.forEach(function (marker) {
          /* Create a div element for the marker. */
          var el = document.createElement("div");
          /* Assign a unique `id` to the marker. */
          el.id = "worldmarker-" + marker.properties.id;
          /* Assign the `marker` class to each marker for styling. */
          el.className = "worldmarker";
          map.on("idle", () => {
            if (map.getZoom() <= 4) {
              el.style.backgroundColor = "#00008b";
            } else {
              el.style.backgroundColor = marker.properties["marker-color"];
            }
          });

          // el.style.width =
          // marker.properties.locations.hazard_loss[hazard] / 3 + "px";
          //el.style.height =
          //marker.properties.locations.hazard_loss[hazard] / 3 + "px";

          /**
           * Create a marker using the div element
           * defined above and add it to the map.
           **/

          new mapboxgl.Marker(el, { offset: [0, -23] })
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);

          /**
           * Listen to the element and when it is clicked, do three things:
           * 1. Fly to the point
           * 2. Close all other popups and display popup for clicked store
           * 3. Highlight listing in sidebar (and remove highlight for all other listings)
           **/
          el.addEventListener("click", (e) => {
            /* Fly to the point */
            map.flyTo(marker);
            /* Close all other popups and display popup for clicked store 
                    createPopUp(marker);
                    /* Highlight listing in sidebar */
          });
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });
          el.addEventListener("mouseenter", (e) => {
            console.log("popup", marker.properties.locations);
            const coordinates = marker.geometry.coordinates;
            const string = marker.properties.locations;

            popup
              .setLngLat(coordinates)
              .setHTML(
                `Name: ${string.name.slice(
                  0,
                  25
                )}<br>Total Loss: $ ${string.total_loss.toFixed(
                  2
                )} Mil <br>Total Loss(in %): ${string.percentage.toFixed(
                  2
                )}<br>Top Risk: ${
                  string.top_hazard_string
                }<br>${hazard} Risk: ${string.hazard_loss[hazard].toFixed(2)}`
              )
              .addTo(map);
          });
          el.addEventListener("mouseleave", (e) => {
            popup.remove();
          });
        });
      }
      map.on("load", () => {
        map.addSource("places", {
          type: "geojson",
          data: stores,
        });

        map.addLayer({
          id: "places",
          type: "geojson",
          source: "places",
          layout: {
            // Make the layer visible by default.
            visibility: "visible",
          },
        });

        map.addSource("raster-tiles", {
          type: "raster",
          tiles: [
            "https://intensel-tiles.s3.amazonaws.com/hazard/typhoon/rcp/85_2050/viridis/{z}/{x}/{y}.png",
          ],
          scheme: "tms",
          tileSize: 256,
        });
        map.addLayer({
          id: "Typhoon",
          type: "raster",
          source: "raster-tiles",
          layout: {
            // Make the layer visible by default.
            visibility: "visible",
          },
          minzoom: 0,
          maxzoom: 22,
        });

        /* map.addSource("radar", {
          type: "image",
          url: "https://intensel-tiles.s3.amazonaws.com/hazard/extreme_heat/rcp/85_2050/plasma/{z}/{x}/{y}.png",
          coordinates: [
            [-80.425, 46.437],
            [-71.516, 46.437],
            [-71.516, 37.936],
            [-80.425, 37.936],
          ],
        });
        map.addLayer({
          id: "radar-layer",
          type: "raster",
          source: "radar",
          paint: {
            "raster-fade-duration": 0,
          },
        });*/

        addMarkers();
      });
      map.on("idle", () => {
        /* let mark = document.getElementsByClassName("worldmarker");
        

        if (map.getZoom() <=4) {
          for (let i = 0; i < mark.length; i++) {
            mark[i].style.backgroundColor = "#00008b";
          }
        }*/
        // If these two layers were not added to the map, abort
        if (!map.getLayer("Typhoon")) {
          return;
        }

        // Enumerate ids of the layers.
        const toggleableLayerIds = ["Typhoon"];

        // Set up the corresponding toggle button for each layer.
        for (const id of toggleableLayerIds) {
          // Skip layers that already have a button set up.
          if (document.getElementById(id)) {
            continue;
          }

          // Create a link.
          const link = document.createElement("a");
          link.id = id;
          link.href = "#";
          link.textContent = id;
          link.className = "active";

          // Show or hide layer when the toggle is clicked.
          link.onclick = function (e) {
            const clickedLayer = this.textContent;
            e.preventDefault();
            e.stopPropagation();

            const visibility = map.getLayoutProperty(
              clickedLayer,
              "visibility"
            );

            // Toggle layer visibility by changing the layout object's visibility property.
            if (visibility === "visible") {
              map.setLayoutProperty(clickedLayer, "visibility", "none");
              this.className = "";
            } else {
              this.className = "active";
              map.setLayoutProperty(clickedLayer, "visibility", "visible");
            }
          };

          const layers = document.getElementById("mapmenu");
          layers.appendChild(link);
        }
      });
    }
  }

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column computer={16}>
            <nav id="mapmenu"></nav>

            <div ref={this.worldmapContainer} className="map-container2" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
      </Grid>
    );
  }
}
export default TyphoonClusterMap;
