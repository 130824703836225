import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Menu,
  Select,
  Dropdown,
  Icon,
  Table,
  Label,
  Modal,
  Popup,
  StepTitle,
  Progress,
} from "semantic-ui-react";
import logo from "../assets/logo.png";
import "./project.css";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import { Row, Col, Card, Tabs, Tab, Breadcrumb } from "react-bootstrap";
import Spinner from "./loader";
import CsvDownloadButton from "react-json-to-csv";
import dropbox from "../assets/dropbox.png";
import team1 from "../assets/team_1.png";
import team2 from "../assets/team_2.png";

import pr1 from "../assets/pr1.png";
import pr2 from "../assets/pr2.png";
import pr3 from "../assets/pr3.png";

import var_eg from "../assets/var_eg.jpg";
//import Report from "./report";
import { Popconfirm } from "antd";
import "antd/dist/antd.css";
import pptxgen from "pptxgenjs";
import { runppt } from "./ppt";
import { changeTitle } from "../redux/actions";
import { Redirect } from "react-router-dom";
import HorizontalTimeline from "react-horizontal-timeline";
import TransitionProject from "./transition_project";
import RealEstateProject from "./realestate_project";
import Physical_project from "./physical_project";
import BenchmarkProject from "./benchmark_project";
import ProjectBarComponent from "./project_bar_component";
import MapProject from "./MapProject";

let options = [];

const VALUES = [
  "2021-1-02",
  "2021-1-01",
  "2021-2-01",
  "2021-3-01",
  "2021-4-01",
  "2021-5-01",
  "2021-6-01",
  "2021-7-01",
  "2021-8-01",
  "2021-9-01",
  "2021-10-01",
  "2021-11-01",
  "2021-12-01",
];

const ProjectOptions = [
  { key: "physical", value: "physical", text: "Physical Risk" },
  { key: "transition", value: "transition", text: "Transition Risk" },
  { key: "realestate", value: "realestate", text: "Real Estate" },
];
const StatusOptions = [
  { key: "active", value: "Active", text: "Active" },
  { key: "finished", value: "Finished", text: "Finished" },
];
const csv = [];
const othersOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "type", value: "type", text: "type" },
  { key: "Area", value: "Area", text: "Area" },
  { key: "Ownership", value: "ownership", text: "Ownership" },
  { key: "floors", value: "floors", text: "floors" },
  {
    key: "Construction Cost",
    value: "construction_cost",
    text: "Replacement Value",
  },
  { key: "With Content", value: "with_content", text: "With Content" },
  { key: "Revenue", value: "revenue", text: "Revenue" },
];
let variableOptions = [];
const rcpOptions = [
  { key: "0.0", value: "0.0", text: "0.0" },
  { key: "2.6", value: "2.6", text: "2.6" },
  { key: "4.5", value: "4.5", text: "4.5" },
  { key: "8.5", value: "8.5", text: "8.5" },
];
const yearOptions = [
  { key: "2020", value: "2020", text: "2020" },
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
];
const PortfolioLossOptions = [
  {
    key: "Rainfall Flood Loss",
    value: "Rainfall Flood Loss",
    text: "Rainfall Flood Loss",
  },
  {
    key: "River Flood Loss",
    value: "River Flood Loss",
    text: "River Flood Loss",
  },
  {
    key: "Storm Surge Loss",
    value: "Storm Surge Loss",
    text: "Storm Surge Loss",
  },
  { key: "Typhoon Loss", value: "Typhoon Loss", text: "Typhoon Loss" },
  {
    key: "Temprature/heat Loss",
    value: "Temprature/heat Loss",
    text: "Temprature/heat Loss",
  },
  /*{
    key: "Solar Production Loss",
    value: "Solar Production Loss",
    text: "Solar Production Loss",
  },
  {
    key: "Wind Production Loss",
    value: "Wind Production Loss",
    text: "Wind Production Loss",
  },
  {
    key: "Hydro Production Loss",
    value: "Hydro Production Loss",
    text: "Hydro Production Loss",
  },
  {
    key: "Wheat Production Loss",
    value: "Wheat Production Loss",
    text: "Wheat Production Loss",
  },
  {
    key: "Rice Production Loss",
    value: "Rice Production Loss",
    text: "Rice Production Loss",
  },
  {
    key: "Maize Production Loss",
    value: "Maize Production Loss",
    text: "Maize Production Loss",
  },
  {
    key: "Fruit Production Loss",
    value: "Fruit Production Loss",
    text: "Fruit Production Loss",
  },
  {
    key: "Vegetable Production Loss",
    value: "Vegetable Production Loss",
    text: "Vegetable Production Loss",
  },*/
];

class Project extends Component {
  state = {
    project_type: "physical",
  };

  handleProject = (key) => {
    this.setState({ project_type: key }, () =>
      console.log("project type", this.state.project_type)
    );
  };

  render() {
    return (
      <>
        <Container fluid style={{ backgroundColor: "#18202F" }}>
          <Row>
            <br />
            <Breadcrumb style={{ paddingLeft: "1.5%" }}>
              <Breadcrumb.Item>Projects</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <br />
        <br />
        <Tabs
          defaultActiveKey="physical"
          onSelect={this.handleProject}
          style={{ paddingLeft: "1.5%" }}
        >
          <Tab title="Physical Risk" eventKey="physical">
            {this.state.project_type === "physical" ? (
              <Physical_project />
            ) : null}
          </Tab>
          <Tab title="Transition Risk" eventKey="transition">
            {this.state.project_type === "transition" ? (
              <TransitionProject />
            ) : null}
          </Tab>
          <Tab title="Maps" eventKey="map_module">
            {this.state.project_type === "map_module" ? <MapProject /> : null}
          </Tab>
          {/* <Tab title="Real Estate" eventKey="realestate">
            {this.state.project_type === "realestate" ? (
              <RealEstateProject />
            ) : null}
          </Tab>
         <Tab title="Benchmark" eventKey="benchmark">
            {this.state.project_type === "benchmark" ? (
              <BenchmarkProject />
            ) : null}
            </Tab>*/}
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    locus: state.locus.locus,
    csv: state.project.csv,
    project: state.project.project,
    ppt: state.project.ppt,
    map: state.project.map,
    overall_map: state.project.overall_map,
    link: state.project.link,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: () => {
      dispatch(locus.getLocations());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    addProject: (formdata) => {
      dispatch(project.addProject(formdata));
    },
    getCSV: (formdata) => {
      dispatch(project.getCSV(formdata));
    },
    getLoss: (formdata) => {
      dispatch(project.getLoss(formdata));
    },
    getSummary: (formdata) => {
      dispatch(project.getSummary(formdata));
    },
    changeTitle: (title) => {
      dispatch(changeTitle(title));
    },
    getProjects: () => {
      dispatch(project.getProjects());
    },
    deleteProject: (formdata) => {
      dispatch(project.deleteProject(formdata));
    },
    getPptReport: (formdata) => {
      dispatch(project.getPptReport(formdata));
    },
    getSatelliteMap: (formdata) => {
      dispatch(project.getSatelliteMap(formdata));
    },
    getMap: (formdata) => {
      dispatch(project.getMap(formdata));
    },
    Dropbox: (formdata) => {
      dispatch(project.Dropbox(formdata));
    },
    getProjectList: (physical_risk) => {
      dispatch(project.getProjectList(physical_risk));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Project);
