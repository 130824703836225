import React, { Component } from "react";
import logo from "../assets/logo_land.png";
import { auth } from "../actions";
import "../assets/css/animate.css";
import "../assets/css/LineIcons.2.0.css";
import "../assets/css/style.css";
import "../assets/css/bootstrap-4.5.0.min.css";
import "../assets/css/default.css";
import { Redirect } from "react-router-dom";
import hero from "../assets/images/header-hero.png";
import heroLeft from "../assets/images/header-hero-left.png";
import heroRight from "../assets/images/header-hero-right.png";
import customersCorporationsIcon from "../assets/images/customers-corporations-icon.png";
import customersFinancialInstitutionsIcon from "../assets/images/customers-financial-institutions-icon.png";
import customersRealEstateInvestorsIcon from "../assets/images/customers-real-estate-investors-icon.png";
import customersCommunitiesIcon from "../assets/images/customers-communities-icon.png";
import shape from "../assets/images/services-shape.svg";
import shape1 from "../assets/images/services-shape-1.svg";
import shape2 from "../assets/images/services-shape-2.svg";
import shape3 from "../assets/images/services-shape-3.svg";
import saasGraphics from "../assets/images/saas graphics.png";
import saasAdvantageIcon1 from "../assets/images/saas-advantage-icon-1.png";
import saasAdvantageIcon2 from "../assets/images/saas-advantage-icon-2.png";
import saasAdvantageIcon3 from "../assets/images/saas-advantage-icon-3.png";
import saasAdvantageIcon4 from "../assets/images/saas-advantage-icon-4.png";
import extremeheat from "../assets/images/extreme-heat.png";
import flood from "../assets/images/flood.png";
import typhoons from "../assets/images/typhoons.png";
import { Navbar, Nav, Button, Card } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import styles from "./home.module.css";

class Home extends Component {
  dataSourceDialogueBox(text, w = "100%") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={w}
        height="95.947"
        viewBox="0 0 277.771 95.947"
      >
        <g
          id="local_conditions"
          data-name="local conditions"
          transform="translate(12688 -928.425)"
        >
          <g
            id="Group_12741"
            data-name="Group 12741"
            transform="translate(-12688.001 928.425)"
          >
            <path
              id="Path_171493"
              data-name="Path 171493"
              d="M822.781,268.357H571.2c-7.232,0-13.094,8.218-13.094,18.355v75.546c0,2.276,2.243,2.845,2.826.72,3.314-12.081,9.82-28.812,20.941-32.06H822.781c7.232,0,13.1-8.218,13.1-18.355V286.712C835.876,276.575,830.013,268.357,822.781,268.357Z"
              transform="translate(-558.105 -268.357)"
              fill="#18202F"
            />
          </g>
          <text
            id="Local_Conditions-2"
            data-name="Local Conditions"
            transform="translate(-12647 968)"
            fill="#fff"
            font-size="20"
            font-family="Poppins-Bold, Poppins"
            font-weight="700"
          >
            <tspan x="0" y="0">
              {text}
            </tspan>
          </text>
        </g>
      </svg>
    );
  }
  render() {
    if (auth.isUserAuthenticated()) {
      return <Redirect to="/location" />;
    }

    return (
      <>
        <div>
          <header className="header-area">
            <Navbar className={`container ${styles.navbar}`}>
              <Navbar.Brand href="index.html">
                <img src={logo} alt="Intensel" height="50" width="150" />
              </Navbar.Brand>
              <Nav className="ml-auto">
                <Nav.Link
                  href="https://intenselglobal.com/how-it-works/"
                  className={styles.navlink}
                >
                  How it works
                </Nav.Link>
                <Nav.Link
                  href="https://intenselglobal.com/solutions/"
                  className={styles.navlink}
                >
                  Solutions
                </Nav.Link>
                <Nav.Link
                  href="https://intenselglobal.com/"
                  className={styles.navlink}
                >
                  Product
                </Nav.Link>
                <Nav.Link
                  href="https://intenselglobal.com"
                  className={styles.navlink}
                >
                  Saas
                </Nav.Link>
                <Nav.Link href="/register" className={styles.navlink}>
                  <Button
                    style={{
                      padding: "5px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    Sign Up
                  </Button>
                </Nav.Link>
              </Nav>
            </Navbar>

            <div id="home" className="header-hero bg_cover">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="header-hero-content">
                      <h4
                        style={{ fontSize: "48px" }}
                        className="header-title wow fadeInUp"
                        data-wow-duration="1.3s"
                        data-wow-delay="0.5s"
                      >
                        Asia's Leading Climate Risk Software Solution
                      </h4>

                      <h3
                        className="header-sub-title wow fadeInUp"
                        data-wow-duration="1.3s"
                        data-wow-delay="0.2s"
                      ></h3>
                      <p
                        style={{ fontSize: "20px" }}
                        className="text wow fadeInUp"
                        data-wow-duration="1.3s"
                        data-wow-delay="0.8s"
                      >
                        Software as a service to measure portfolio and asset
                        level risks related to climate change
                      </p>
                      <a
                        href="/login"
                        style={{ fontWeight: "600" }}
                        className={`main-btn wow fadeInUp ${styles.signInButton}`}
                        data-wow-duration="1.3s"
                        data-wow-delay="1.1s"
                      >
                        Log In
                      </a>
                      <a
                        href="https://intenselglobal.com/#demo"
                        style={{ fontWeight: "600" }}
                        className={`main-btn wow fadeInUp ${styles.signUpButton}`}
                        data-wow-duration="1.3s"
                        data-wow-delay="1.1s"
                      >
                        Request a Demo
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="header-hero-image text-center wow fadeIn"
                      data-wow-duration="1.3s"
                      data-wow-delay="1.4s"
                    >
                      <img src={hero} alt="hero" className={styles.hero} />
                      <img
                        src={heroLeft}
                        alt="hero"
                        className={styles.heroLeft}
                      />
                      <img
                        src={heroRight}
                        alt="hero"
                        className={styles.heroRight}
                      />
                    </div>
                  </div>
                </div>
                <div className="col" style={{ display: "inline-block" }}></div>
              </div>
              <div id="particles-1" className="particles"></div>
            </div>
          </header>

          <section id="features" className="services-area pt-120">
            <div className="container">
              <div className="justify-content-center">
                <div className="section-title text-center pb-40">
                  <h3 className="title">
                    Who we{" "}
                    <span
                      className={styles.intenselBlue}
                      style={{ fontWeight: "inherit" }}
                    >
                      serve?
                    </span>
                  </h3>
                </div>
                <div className={styles.sectionBody}>
                  <ul className={styles.customers}>
                    <li>
                      <img src={customersCorporationsIcon} /> CORPORATIONS
                    </li>
                    <li>
                      <img src={customersFinancialInstitutionsIcon} /> FINANCIAL
                      INSTITUTIONS
                    </li>
                    <li>
                      <img src={customersRealEstateInvestorsIcon} /> REAL ESTATE
                      INVESTORS
                    </li>
                    <li>
                      <img src={customersCommunitiesIcon} /> COMMUNTIES
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div className="justify-content-center">
                <div className="section-title text-center pb-40">
                  <h3 className="title">
                    Why Intensel is{" "}
                    <span
                      className={styles.intenselOrange}
                      style={{ fontWeight: "inherit" }}
                    >
                      better product?
                    </span>
                  </h3>
                </div>
                <div className={`${styles.sectionBody} ${styles.whyIntensel}`}>
                  <Card>
                    <Card.Body>
                      <Card.Title className={styles.whyIntenselCardTitleOdd}>
                        1
                      </Card.Title>
                      <Card.Text>
                        Granular & Accurate Climate Analytics
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title className={styles.whyIntenselCardTitleEven}>
                        2
                      </Card.Title>
                      <Card.Text>Integrated Financial Analysis</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title className={styles.whyIntenselCardTitleOdd}>
                        3
                      </Card.Title>
                      <Card.Text>Real-time Analytics</Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title className={styles.whyIntenselCardTitleEven}>
                        4
                      </Card.Title>
                      <Card.Text>Interactive</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div
                  className={`${styles.sectionBody} ${styles.whyIntenselAdvantages} ${styles.feature}`}
                >
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>PRECISE</Card.Title>
                      <Card.Text>
                        <p className={styles.subtitle}>
                          Scientifically Rigorous
                        </p>
                        <p className={styles.advantageText}>
                          In-house scientific simulations with peer-reviewed
                          dynamical and data-driven models.
                        </p>
                        <p className={styles.subtitle}>Expert Validation</p>
                        <p className={styles.advantageText}>
                          Validation & verification integrated throughout the
                          modeling chain.
                        </p>
                        <p className={styles.subtitle}>Data Transparency</p>
                        <p className={styles.advantageText}>
                          Transparency in all modeling components, from metrics
                          to scoring to loss. No black boxes
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>PREDICTIVE</Card.Title>
                      <Card.Text>
                        <p className={styles.subtitle}>Forward-Looking</p>
                        <p className={styles.advantageText}>
                          Latest predictive climate models; not a
                          backward-looking catastrophic risk model approach
                        </p>
                        <p className={styles.subtitle}>
                          Quantified Uncertainty
                        </p>
                        <p className={styles.advantageText}>
                          Measure and provide known sources of modeling
                          uncertainty.
                        </p>
                        <p className={styles.subtitle}>
                          Scenario-based Ability
                        </p>
                        <p className={styles.advantageText}>
                          Ability to traverse a range of emissions and response
                          scenarios.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>EXTENDABLE</Card.Title>
                      <Card.Text>
                        <p className={styles.subtitle}>Data Access</p>
                        <p className={styles.advantageText}>
                          Flexible data access via API and SaaS applications—
                          all delivered at enterprise-grade
                        </p>
                        <p className={styles.subtitle}>Downscaled</p>
                        <p className={styles.advantageText}>
                          Downscaling methods optimized for portfolio & facility
                          analyses.
                        </p>
                        <p className={styles.subtitle}>Expanding Catalog</p>
                        <p className={styles.advantageText}>
                          We are rapidly expanding climate metrics tuned to
                          customer needs.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div
                className={`${styles.sectionBody} ${styles.whyIntenselDataSources}`}
              >
                <div className="section-title text-center pb-40">
                  <h4 className="title">Data Sources</h4>
                </div>
                <ul>
                  <li className={styles.whyIntenselDataSourcesItem}>
                    <div>
                      {this.dataSourceDialogueBox("Global Climate Models")}
                    </div>
                    <ul>
                      <li>Sea - Level Rise</li>
                      <li>Higher Temperatures </li>
                      <li>Storm Intensification</li>
                      <li>Altered Rain Patterns</li>
                    </ul>
                  </li>
                  <li className={styles.whyIntenselDataSourcesItem}>
                    <div>{this.dataSourceDialogueBox("Historical data")}</div>
                    <ul>
                      <li>Reanalysis Data</li>
                      <li>Satellite Observations</li>
                      <li>In-Situ Observations</li>
                    </ul>
                  </li>
                  <li className={styles.whyIntenselDataSourcesItem}>
                    <div>{this.dataSourceDialogueBox("Additional Models")}</div>
                    <ul>
                      <li>Severe Weather</li>
                      <li>Hyrdrology</li>
                      <li>Wildlife</li>
                      <li>Evapotranspiration</li>
                      <li>Global EQ Hazard</li>
                    </ul>
                  </li>
                  <li className={styles.whyIntenselDataSourcesItem}>
                    <div>{this.dataSourceDialogueBox("Local Conditions")}</div>
                    <ul>
                      <li>Elevation</li>
                      <li>Land Use</li>
                      <li>Vegatation</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="section-title text-center pb-40">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80%"
                  height="59.061"
                  viewBox="0 0 1255.143 59.061"
                >
                  <g id="arrow" transform="translate(13911.39 1081.248)">
                    <path
                      id="Path_171518"
                      data-name="Path 171518"
                      d="M-13908.979-1078.954s-4.515,27.88,15.931,27.88h591.1l17.936,24.929,18.268-24.929"
                      fill="none"
                      stroke="#18202F"
                      stroke-linecap="round"
                      stroke-width="4"
                    />
                    <path
                      id="Path_171519"
                      data-name="Path 171519"
                      d="M-13263.747-1078.954s4.515,27.88-15.931,27.88h-591.1l-18.178,25.424-17.8-25.424"
                      transform="translate(605.001)"
                      fill="none"
                      stroke="#18202F"
                      stroke-linecap="round"
                      stroke-width="4"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={`${styles.sectionBody} ${styles.whyIntenselSimulationOfFuturePerils}`}
              >
                <div className="section-title text-center pb-40">
                  <h4 className="title">Simulation Of Future Perils</h4>
                </div>
                <ul>
                  <li>
                    Every 20 Years
                    <br />
                    2020-2100
                  </li>
                  <li>
                    Any Location
                    <br />
                    Worldwide
                  </li>
                  <li>
                    Multiple Carbon
                    <br />
                    Scenarios
                  </li>
                </ul>
              </div>

              <div className="justify-content-center">
                <div className="section-title text-center pb-20">
                  <h3 className="title">
                    INTENSEL{" "}
                    <span
                      className={styles.intenselBlue}
                      style={{ fontWeight: "inherit" }}
                    >
                      Climate Solutions
                    </span>
                  </h3>
                </div>
                <div className={`${styles.text} ${styles.sectionBody}`}>
                  <p>
                    As businesses, investors and governments explore the
                    transition to a net-zero world,
                  </p>
                  <p>
                    Intensel is committed to creating climate solutions to
                    support real time, accurate decision making
                  </p>
                </div>
                <div className={`${styles.features} ${styles.sectionBody}`}>
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>
                        Physical Climate-Related Risk Metrics (TCFD Approach)
                      </Card.Title>
                      <Card.Text>
                        {/* Extensive climate change metrics
                            including Climate Value-at-Risk,
                            carbon management assessment,
                            carbon emissions, clean tech
                            metrics and fossil fuel screens. */}
                        <br />
                        <ul>
                          <li>Portfolio Physical Climate Risk</li>
                          <li>Key Risk Identifications</li>
                          <li>Asset Level Exposure To Climate</li>
                          <li>Supply Chain Risk Exposure</li>
                        </ul>
                      </Card.Text>
                      <Card.Link
                        href="https://intenselglobal.com/how-it-works/"
                        className="mt-auto"
                      >
                        <Button style={{ color: "white" }}>Explore more</Button>
                      </Card.Link>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>Climate Value-At-Risk</Card.Title>
                      <Card.Text>
                        {/* Extensive climate change metrics
                            including Climate Value-at-Risk,
                            carbon management assessment,
                            carbon emissions, clean tech
                            metrics and fossil fuel screens */}
                        <br />
                        <ul>
                          <li>
                            Climate-Related Potential Losses For The Portfolio
                          </li>
                          <li>
                            Climate-Related Potential Losses On Company And
                            Asset Level
                          </li>
                          <li>
                            Climate Credit Spread Estimation On Portfolio And
                            Company Level
                          </li>
                        </ul>
                      </Card.Text>
                      <Card.Link
                        href="https://intenselglobal.com/how-it-works/"
                        className="mt-auto"
                      >
                        <Button style={{ color: "white" }}>Explore more</Button>
                      </Card.Link>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>Stress-Test Scenarios</Card.Title>
                      <Card.Text>
                        {/* Extensive climate change metrics
                            including Climate Value-at-Risk,
                            carbon management assessment,
                            carbon emissions, clean tech
                            metrics and fossil fuel screens */}
                        <br />
                        <ul>
                          <li>Forward-Time Analysis</li>
                          <li>RCP Related climate Scenarios</li>
                          <li>Return Period Risk And Dollar loss Exposure</li>
                        </ul>
                      </Card.Text>
                      <Card.Link
                        href="https://intenselglobal.com/how-it-works/"
                        className="mt-auto"
                      >
                        <Button style={{ color: "white" }}>Explore more</Button>
                      </Card.Link>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>Transitional Risk (on request)</Card.Title>
                      <Card.Text>
                        {/* Extensive climate change metrics
                            including Climate Value-at-Risk,
                            carbon management assessment,
                            carbon emissions, clean tech
                            metrics and fossil fuel screens */}
                        <br />
                        <ul>
                          <li>Emission Estimate Using Satellite Data</li>
                          <li>Footprint Portfolio Level Estimation</li>
                          <li>
                            Relative Or Attribution Analysis Versus Benchmarks
                          </li>
                          <li>Price Risk Assessment</li>
                          <li>Emission Gap Assessment</li>
                          <li>Contribution By Sector And Asset/Company</li>
                        </ul>
                      </Card.Text>
                      <Card.Link
                        href="https://intenselglobal.com/how-it-works/"
                        className="mt-auto"
                      >
                        <Button style={{ color: "white" }}>Explore more</Button>
                      </Card.Link>
                    </Card.Body>
                  </Card>
                  {/* <Card>
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>CLIMATE DATA & ANALYTICS</Card.Title>
                            <Card.Text>
                            Extensive climate change metrics
                            including Climate Value-at-Risk,
                            carbon management assessment,
                            carbon emissions, clean tech
                            metrics and fossil fuel screens.
                            <br/>
                            <ul>
                                <li>State of the art model</li>
                                <li>Broad coverage across equities, fixed income and real estate</li>
                            </ul>
                            </Card.Text>
                            <Card.Link href="http://google.com" className="mt-auto"><Button>Explore more</Button></Card.Link>
                        </Card.Body>
                    </Card> */}
                </div>
              </div>

              <div className="justify-content-center">
                <div className="section-title text-center pb-40">
                  <h3 className="title">
                    <span
                      className={styles.intenselOrange}
                      style={{ fontWeight: "inherit" }}
                    >
                      Intensel
                    </span>{" "}
                    SaaS advantage
                  </h3>
                </div>
                <div className={`${styles.sectionBody} ${styles.text}`}>
                  <p>
                    We provide real-time to calculate portfolio and asset
                    financial value-at-risk(VAR) accurate climate analytics
                  </p>
                </div>
                <div
                  className={`${styles.sectionBody} ${styles.intenselSaasAdvantage}`}
                >
                  <div>
                    <div className={styles.intenselSaasAdvantageItem}>
                      <img src={saasAdvantageIcon1} />
                      <p>
                        Enables reporting and disclosure consistent with the
                        TCFD framework.
                      </p>
                    </div>
                    <div className={styles.intenselSaasAdvantageItem}>
                      <img src={saasAdvantageIcon2} />
                      <p>
                        Reports transition, physical risks & opportunities in
                        the financial terms to empower decision - making.
                      </p>
                    </div>
                    <div className={styles.intenselSaasAdvantageItem}>
                      <img src={saasAdvantageIcon3} />
                      <p>
                        Powered by transparent methodology and rigorous science.
                      </p>
                    </div>
                    <div className={styles.intenselSaasAdvantageItem}>
                      <img src={saasAdvantageIcon4} />
                      <p>
                        Provides global coverage with property/asset to
                        portfolio-level analysis, multiple climate scenarios
                        covering present day to the year 2100.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img src={saasGraphics} />
                  </div>
                </div>
              </div>

              <div className="justify-content-center">
                <div className="section-title text-center pb-40">
                  <h3 className="title">Climate risk</h3>
                </div>
                <div className={`${styles.sectionBody} ${styles.climateRisk}`}>
                  <div>
                    <div className={styles.climateRiskItem}>
                      <img src={extremeheat} />
                      <p class={styles.climateRiskItemDescription}>
                        Extreme heat is a period of high heat and humidity with
                        temperatures above 90 degrees for at least two to three
                        days. In extreme heat your body works extra hard to
                        maintain a normal temperature, which can lead to death.
                        <br />
                        <a href="https://intenselglobal.com/how-it-works/">
                          Learn more
                        </a>
                      </p>
                      <p class={styles.climateRiskItemName}>EXTREME HEAT</p>
                    </div>
                    <div className={styles.climateRiskItem}>
                      <img src={flood} />
                      <p class={styles.climateRiskItemDescription}>
                        A flood is an overflow of water on normally dry ground.
                        This is most commonly due to an overflowing river, a dam
                        break, snowmelt, or heavy rainfall. Less commonly
                        happening are tsunamis, storm surge.
                        <br />
                        <a href="https://intenselglobal.com/how-it-works/">
                          Learn more
                        </a>
                      </p>
                      <p class={styles.climateRiskItemName}>FLOOD</p>
                    </div>
                    <div className={styles.climateRiskItem}>
                      <img src={typhoons} />
                      <p class={styles.climateRiskItemDescription}>
                        A typhoon is a mature tropical cyclone that develops
                        between 180° and 100°E in the Northern Hemisphere. It is
                        the most active tropical cyclone basin on Earth,
                        accounting for almost one-third of the world's annual
                        tropical cyclones.
                        <br />
                        <a href="https://intenselglobal.com/how-it-works/">
                          Learn more
                        </a>
                      </p>
                      <p class={styles.climateRiskItemName}>TYPHOONS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br />
        <br />
        <footer>
          <div className="container">
            <div>
              <i class="simple-icon-location-pin" />
              <span>
                Unit 1058, 10/F Building 19W, No.19 Science Park West Avenue
                <br /> Hong Kong Science Park, Hong Kong
              </span>
            </div>
            <div>
              <i class="simple-icon-envelope" />
              <span>
                <span style={{ fontWeight: "bold" }}>Email Us</span>
                <br />
                <a href="mailto:info@intensel.net">info@intensel.net</a>
              </span>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default Home;
