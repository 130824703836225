import React, { useEffect, useRef } from "react";
/* eslint import/no-webpack-loader-syntax: off */

import mapboxgl from "!mapbox-gl";
import { Grid } from "semantic-ui-react";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";

const ClusterMap = ({ data, hazard }) => {
  const worldmapContainer = useRef(null);

  useEffect(() => {
    if (worldmapContainer.current && data) {
      const map = new mapboxgl.Map({
        container: worldmapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [data[0].location.longitude, data[0].location.latitude],
        zoom: 2,
        scrollZoom: true,
      });

      const features = data.map((item, i) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.location.longitude, item.location.latitude],
        },
        properties: {
          id: i,
          locations: {
            name: item.location.name,
            lat: item.location.latitude,
            long: item.location.longitude,
            total_loss: item.data.total_loss,
            percentage: item.data.percentage,
            top_hazard_string: item.data.top_hazard_string,
            hazard_loss: item.data,
          },
        },
      }));

      const stores = {
        type: "FeatureCollection",
        features: features,
      };

      stores.features.forEach((store) => {
        let hazard_s = store.properties.locations.hazard_loss[hazard];
        if (hazard_s >= 100) {
          store.properties["marker-color"] = "#a4222b";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s > 76) {
          store.properties["marker-color"] = "#c92128";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s > 51) {
          store.properties["marker-color"] = "#ffae3a";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s > 26) {
          store.properties["marker-color"] = "#fce97f";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s > 1) {
          store.properties["marker-color"] = "#f9ffb8";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        } else if (hazard_s >= 0) {
          store.properties["marker-color"] = "#fdffe5";
          store.properties["marker-symbol"] = "rocket";
          store.properties["marker-size"] = "medium";
        }
      });

      function addMarkers() {
        stores.features.forEach((marker) => {
          const el = document.createElement("div");
          el.id = "worldmarker-" + marker.properties.id;
          el.className = "worldmarker";
          map.on("idle", () => {
            if (map.getZoom() <= 1) {
              el.style.backgroundColor = marker.properties["marker-color"];
              el.style.border = "2px solid #000000";
            } else {
              el.style.backgroundColor = marker.properties["marker-color"];
              el.style.border = "2px solid #000000";
            }
          });

          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);

          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });

          el.addEventListener("mouseenter", () => {
            const coordinates = marker.geometry.coordinates;
            const string = marker.properties.locations;

            popup
              .setLngLat(coordinates)
              .setHTML(
                `Name: ${string.name.slice(
                  0,
                  25
                )}<br>Total Loss: $ ${string.total_loss.toFixed(
                  2
                )} Mil <br>Total Loss(in %): ${string.percentage.toFixed(
                  2
                )}<br>Top Risk: ${
                  string.top_hazard_string
                }<br>${hazard} Score: ${string.hazard_loss[hazard].toFixed(2)}`
              )
              .addTo(map);
          });

          el.addEventListener("mouseleave", () => {
            popup.remove();
          });
        });
      }

      map.on("load", () => {
        map.addSource("places", {
          type: "geojson",
          data: stores,
        });

        map.addLayer({
          id: "places",
          type: "geojson",
          source: "places",
          layout: {
            visibility: "visible",
          },
        });

        addMarkers();
      });
    }
  }, [data, hazard]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={16}>
          <div ref={worldmapContainer} className="map-container2" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
    </Grid>
  );
};

export default ClusterMap;
