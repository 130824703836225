import React, { Component } from "react";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import TransitionTable from "./transitiontable";
import ModifyTransitionTable from "./modifytransitiontable";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import { Table } from "antd";

const columns = [
  {
    title: "Company Name",
    key: "company_name",
    dataIndex: "company_name",
    width: "15%",
  },
  {
    title: "Projected Scope 1 and 2 GHG Emission (in t CO2e)",
    dataIndex: "projected_scope_1_and_2_GHG_emission",
    key: "projected_scope_1_and_2_GHG_emission",
    width: "15%",
  },

  {
    title: "Weighted Average Carbon Intensity (in t CO2e/$M Revenue)",
    dataIndex: "weighted_average_carbon_intensity",
    key: "weighted_average_carbon_intensity",
    width: "15%",
  },
  {
    title: "Total Carbon Emission (in t CO2e)",
    dataIndex: "total_carbon_emission",
    key: "total_carbon_emission",
    width: "15%",
  },
  {
    title: "Carbon FootPrint ( in tCO2e/$m invested)",
    dataIndex: "carbon_footprint",
    key: "carbon_footprint",
    width: "15%",
  },
  {
    title: "Carbon Intensity (in tCO2e/$m revenue)",
    dataIndex: "carbon_intensity",
    key: "carbon_intensity",
    width: "15%",
  },
];

class EditTransition extends Component {
  state = {
    portfolio_value: "",
    portfolio_name: "",
    companies_data: "",
  };

  componentDidMount() {
    let formdata = new FormData();
    formdata.append(
      "portfolio_name",
      this.props.location.state.carbon_project_name
    );
    this.props.getCarbonProjectData(formdata);
    this.props.getCarbonProject(formdata);
  }
  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.carbon_project_data != this.props.carbon_project_data) {
      this.setState({
        portfolio_value: this.props.carbon_project_data.portfolio_value,
        portfolio_name: this.props.carbon_project_data.portfolio_name,
        companies_data: this.props.carbon_project_data.companies_data,
      });
    }
  }

  handleTransition = (data) => {
    console.log("modify", data);
    let formdata = new FormData();
    formdata.append("portfolio_name", this.state.portfolio_name);
    formdata.append("portfolio_value", this.state.portfolio_value);
    formdata.append("companies_data", JSON.stringify(data));
    this.props.ModifyTransition(formdata);
  };

  render() {
    return (
      <Container fluid>
        <br />
        <Tabs defaultActiveKey="carbon">
          <Tab title="Carbon Module" eventKey="carbon">
            <Row>
              <Col lg={12} className="transitiontable">
                <h2> Input Data </h2>
                <Row>
                  <Col lg={8}> </Col>
                  <Col lg={2}>
                    <div>
                      <label> Portfolio Value </label>
                      <input
                        type="text"
                        class="form-control"
                        value={this.state.portfolio_value}
                        onChange={(e) =>
                          this.setState({ portfolio_value: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div>
                      <label> Portfolio Name </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder={
                          this.props.carbon_project_data.portfolio_name
                        }
                        value={this.props.carbon_project_data.portfolio_name}
                        onChange={(e) =>
                          this.setState({ portfolio_name: e.target.value })
                        }
                        disabled
                      />
                    </div>
                  </Col>
                </Row>

                {this.state.companies_data ? (
                  <ModifyTransitionTable
                    handleRisk={this.handleTransition}
                    portfolio_value={this.state.portfolio_value}
                    data={this.state.companies_data}
                  />
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg={10} className="transition">
                {this.props.carbon_project.table_data ? (
                  <>
                    <h2> Output Data </h2>
                    <Table
                      columns={columns}
                      dataSource={this.props.carbon_project.table_data}
                    />
                  </>
                ) : null}
                <Row style={{ border: "1px solid black" }}>
                  <Col
                    style={{
                      borderRight: "1px solid black",
                      backgroundColor: "#18202F",
                      padding: "1%",
                    }}
                  >
                    <p style={{ color: "white" }}> Portfolio Total </p>
                  </Col>
                  <Col lg={2} style={{ borderLeft: "1px solid black" }}>
                    <p>
                      {
                        this.props.carbon_project
                          .projected_scope_1_and_2_portfolio
                      }
                    </p>
                  </Col>
                  <Col style={{ borderLeft: "1px solid black", padding: "1%" }}>
                    <p>
                      {
                        this.props.carbon_project
                          .weighted_average_carbon_intensity_portfolio
                      }
                    </p>
                  </Col>
                  <Col style={{ borderLeft: "1px solid black", padding: "1%" }}>
                    <p style={{ marginLeft: "2%" }}>
                      {
                        this.props.carbon_project
                          .total_carbon_emission_portfolio
                      }
                    </p>
                  </Col>
                  <Col style={{ borderLeft: "1px solid black", padding: "1%" }}>
                    <p> {this.props.carbon_project.carbon_footprint} </p>
                  </Col>
                  <Col style={{ borderLeft: "1px solid black", padding: "1%" }}>
                    <p> {this.props.carbon_project.carbon_intensity} </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tab>
          <Tab title="Other Environment Metric" eventKey="other"></Tab>
        </Tabs>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,

    carbon_project_data: state.project.carbon_project_data,
    carbon_project: state.project.carbon_project,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ModifyTransition: (formdata) => {
      dispatch(locus.ModifyTransition(formdata));
    },
    getCarbonProjectData: (formdata) => {
      dispatch(project.getCarbonProjectData(formdata));
    },
    getCarbonProject: (formdata) => {
      dispatch(project.getCarbonProject(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTransition);
