import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import "./location.css";
import { loadModules } from "esri-loader";
import { CSVReader } from "react-papaparse";
import logo from "../assets/logo.png";
import home from "../assets/home.png";
import add from "../assets/images/add.png";
import sample from "../assets/image.png";
import ll1 from "../assets/logo_land.png";
import search from "../assets/search.png";
import { Accordion, Card } from "react-bootstrap";

import upload from "../assets/upload.png";
import hsi from "../assets/hsi.png";
import sp from "../assets/sp.png";
import dax from "../assets/dax.png";
import dawjones from "../assets/dowjones.png";
import nasdaq from "../assets/nasdaq.png";
import euro from "../assets/euro.png";
import nifty from "../assets/nifty.png";
import nikkie from "../assets/nikkei.png";

import toastr from "toastr";

import { connect } from "react-redux";
import { locus, auth } from "../actions";
import { Checkbox, Typography, Radio, Select } from "antd";

import { company } from "../actions";
import Suggestion from "./suggestion";
import PortfolioSpinner from "./loader";
import GenerateMap from "./generate_map";
import "toastr/build/toastr.min.css";
import { CSVLink } from "react-csv";
import { changeTitle } from "../redux/actions";
import { Redirect } from "react-router-dom";
import { Tabs, Tab, Row, Col, Breadcrumb, Container } from "react-bootstrap";
import video from "../assets/video.mp4";
import video1 from "../assets/video.gif";

import TransitionTable from "./transitiontable";

import { Table } from "antd";

let mapcards = [];

const scenarioOptions = ["0.0", "2.6", "4.5", "8.5"];
const periodOptions = ["100", "250"];
const yearOptions = ["2020", "2030", "2050"];

const othersOptions = [
  { key: "Flood", value: "River Flood", text: "Flood" },
  { key: "Rainfall", value: "Rainfall", text: "Rainfall" },

  { key: "Drought Index", value: "Drought Index", text: "Drought Index" },
  //{ key: "Landslide", value: "Landslide", text: "Landslide" },
  { key: "Storm Surge", value: "Storm Surge", text: "Storm Surge" },

  { key: "Extreme heat", value: "Extreme heat", text: "Extreme heat" },
  { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
];

class CreateMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  state = {
    upload: "",
    index: "",
    file: false,
    locations: [],
    query: "",
    availableDatasets: {},
    selectedVariable: "",

    farmVariables: [],
    energyVariables: [],
    option: "",
    variables: [],
    buildingVariables: [],
    confirmationOpen: false, // state to control confirmation modal

    overwrite: "false",
    company: [],
    portfolio: "",
    selectedOption: null,
    newlocations: [],
    modalOpen: false,
    videoOpen: false,
    loading: false,
    long: 34.0,
    lat: 39.0,
    zoom: 1,
    continent: "Continents",
    selectedTags: [],
    allTags: [],
    buildingselectedTags: [],
    allbuildingTags: [],
    optional: "",
    infoOpen: false,
    buildinginfoOpen: false,
    restrictOpen: false,
    address: "",
    companyloading: false,
    search_loading: false,
    buildingmodalOpen: false,
    search_type: "",
    index: "HSI",
    modalIndicesOpen: false,
    portfolio_value: "",
    portfolio_name: "",
    modalCompanyOpen: false,
    modalFileOpen: false,
    csv: "",
    selectedScenarios: [],
    selectedYears: [],
    distance: "1",
    return_period: "",
    selections: [],
  };

  handleSelectChange = (value) => {
    this.setState({ selections: value });
  };

  handleDistanceChange = (e) => {
    const newDistance = e.target.value;
    this.setState({ distance: newDistance });
    localStorage.setItem("distance", newDistance);
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleIndex = (e) => {
    console.log("checked", e.target.id);

    if (this.state.index == e.target.id) {
      this.setState({ index: "" });
    } else {
      this.setState({ index: e.target.id }, () =>
        this.props.getIndices(this.state.index)
      );
    }
  };
  handleFileChange = (e) => {
    console.log("csv", e.target.files[0]);
    this.setState({ csv: e.target.files[0] });
  };
  handleDeleteMarker = (id) => {
    // Your delete marker logic in the parent component
    console.log(`Deleting marker with ID: ${id}`);
  };
  handleChange = (e, { value }) => {
    this.setState({ selectedOption: value }, () =>
      console.log("selectedoption", this.state.selectedOption)
    );
  };
  onSubmit = (e) => {
    console.log("onSubmit triggered");
    if (e) e.preventDefault();

    // let defaultvar = ["Rainfall Flood"];
    //let variables = defaultvar.concat(this.state.variables);

    let formdata = new FormData();

    formdata.append("name", this.state.portfolio);
    formdata.append("latitude", this.state.locations[0].latitude);
    formdata.append("longitude", this.state.locations[0].longitude);
    formdata.append(
      "portfolio_variables",
      JSON.stringify([this.state.selectedVariable])
    );
    //formdata.append("scenarios", JSON.stringify(this.state.selectedScenarios));
    //formdata.append("years", JSON.stringify(this.state.selectedYears));
    formdata.append("distance", this.state.distance);
    formdata.append("duration_data", JSON.stringify(this.state.selections));

    this.setState({ loading: true }, () => {
      this.props.createMap(formdata);
    });
  };

  handleVariableChange = (e) => {
    console.log(e.target.value); // Check what value is being passed
    this.setState({ selectedVariable: e.target.value });
  };

  handleOpenConfirmation = () => {
    this.setState({ confirmationOpen: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ confirmationOpen: false, modalOpen: false });
  };

  handleConfirm = () => {
    this.onSubmit();

    this.setState({ confirmationOpen: false });
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () =>
    this.setState({
      modalOpen: false,
    });

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataset !== this.props.dataset) {
      console.log("Dataset changed:", prevProps.dataset, this.props.dataset);
      this.setState({ availableDatasets: this.props.dataset }, () =>
        console.log("available datasets", this.state.availableDatasets)
      );
    }
  }

  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
    mapcards = [];
  }

  handleLogout = () => {
    this.props.logout();
  };
  onChange = (checkedValues) => {
    console.log("checked variables", checkedValues);
    this.setState({ variables: checkedValues });
  };
  handleScenarioChange = (checkedValues) => {
    console.log("checked scenarios", checkedValues);

    this.setState({ selectedScenarios: checkedValues });
  };
  handleYearChange = (checkedValues) => {
    console.log("checked year", checkedValues);

    this.setState({ selectedYears: checkedValues });
  };
  handlePeriodChange = (checkedValues) => {
    this.setState({ return_period: checkedValues });
  };

  handleMapDataChange = (newMapData) => {
    this.setState({ locations: newMapData }, () => {
      console.log("location", this.state.locations);
      this.fetchAvailableDatasets();
    });
  };

  fetchAvailableDatasets = () => {
    const { locations } = this.state;
    if (locations.length > 0) {
      let formdata = new FormData();
      formdata.append("latitude", locations[0].latitude);
      formdata.append("longitude", locations[0].longitude);

      this.props.fetchdataset(formdata);
    }
  };

  handleAsset = (address, latitude, longitude) => {
    mapcards.push({
      name: address,
      latitude: latitude,
      longitude: longitude,
    });
    this.setState({ locations: mapcards }, () =>
      console.log("location map", this.state.locations, mapcards.length)
    );
  };

  render() {
    console.log("selection", this.state.selections);
    const { value, continent } = this.state;
    const distanceOptions = ["1", "3", "5"];
    const { selectedVariable, availableDatasets } = this.state;

    return (
      <div>
        <Container fluid style={{ backgroundColor: "#18202F" }}>
          <Row>
            <br />
            <Breadcrumb style={{ paddingLeft: "0.5%" }}>
              <Breadcrumb.Item>Map Module</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>

        <br />

        <Container fluid style={{ padding: "0.7%" }}>
          <Row className="Location_Content">
            <Col>
              <Tabs
                className="location"
                defaultActiveKey="Asset"
                onSelect={this.handleLocation}
              >
                <Tab title="Map" eventKey="Asset" className="Map">
                  <br />

                  <GenerateMap
                    lat={this.state.lat}
                    long={this.state.long}
                    zoom={this.state.zoom}
                    onMapDataChange={this.handleMapDataChange}
                  />

                  <Button
                    primary
                    onClick={this.handleOpen}
                    style={{
                      fontFamily: "montserrat",
                      fontSize: "15px",
                      borderRadius: 0,
                      marginLeft: "auto",
                      float: "right",
                      backgroundColor: "#355ed814",
                      color: "#18202F",
                    }}
                  >
                    PROCEED
                  </Button>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
        <Modal
          open={this.state.confirmationOpen}
          onClose={this.handleCloseConfirmation}
          closeIcon
        >
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <p>
              Do you want to proceed further? The map will be charged
              separately.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseConfirmation}>No</Button>
            <Button onClick={this.handleConfirm} primary>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.restrictOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Error
          </Modal.Header>
          <Modal.Content>
            {/*<video autoPlay loop muted id="video">
                <source src={video} type="video/mp4"/>
              </video>*/}
            <p>
              You are only allowed to add{" "}
              {this.props.permissions.assets_allowed} assets for Analysis
            </p>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          className="group-wrapper"
        >
          <Modal.Header className="portfolio-header">
            Create Portfolio
          </Modal.Header>
          <Modal.Content
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "0rem",
              paddingBottom: "0rem",
            }}
          >
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Portfolio Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Select Variables for Analysis
                  </Label>
                  <br />
                  <Radio.Group onChange={this.handleVariableChange}>
                    <Radio value="Rainfall Flood">Rainfall Flood</Radio>
                    <Radio value="Storm Surge">Storm Surge</Radio>
                  </Radio.Group>

                  {selectedVariable &&
                    availableDatasets[
                      selectedVariable.toLowerCase().replace(" ", "_")
                    ] && (
                      <div className="dataset-container">
                        <Label
                          style={{
                            backgroundColor: "white",
                            color: "#18202F",
                            fontSize: "14px",
                            padding: "0px",
                          }}
                          className="dataset-label"
                        >
                          Available Datasets for {selectedVariable}
                        </Label>

                        {/* Handle Rainfall Flood and Storm Surge with Dropdowns */}
                        <div className="select-container">
                          {/* Rainfall Flood Dropdown */}
                          {selectedVariable === "Rainfall Flood" && (
                            <div className="select-item">
                              <Select
                                mode="multiple"
                                placeholder="Select Datasets"
                                className="select-dropdown"
                                onChange={(value) =>
                                  this.handleSelectChange(value)
                                }
                              >
                                {availableDatasets.rainfall_flood.map(
                                  (option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </Select.Option>
                                  )
                                )}
                              </Select>
                            </div>
                          )}

                          {/* Storm Surge Dropdown */}
                          {selectedVariable === "Storm Surge" && (
                            <div className="select-item">
                              <Select
                                mode="multiple"
                                placeholder="Select Datasets"
                                className="select-dropdown"
                                onChange={(value) =>
                                  this.handleSelectChange(value)
                                }
                              >
                                {availableDatasets.storm_surge.map((option) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.key}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                  <br />
                  {/* <Radio.Group
                    onChange={this.handleVariableChange}
                    value={selectedVariable}
                  >
                    <Row>
                      <Col lg={4}>
                        <div className="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Rainfall Flood"
                          />
                          <div className="single_variable_card">
                            <div className="variable_card_content">
                              <div className="variable_card_icon">
                                <div className="variable_icon variable_icon6"></div>
                              </div>
                              <div className="variable_card_title">
                                <h3>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Rainfall Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="2"
                            value="Storm Surge"
                          />
                          <div className="single_variable_card">
                            <div className="variable_card_content">
                              <div className="variable_card_icon">
                                <div className="variable_icon variable_icon6"></div>
                              </div>
                              <div className="variable_card_title">
                                <h3>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Storm Surge
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                  </Radio.Group>

                  {selectedVariable.includes("Rainfall Flood") &&
                    availableDatasets["rainfall_flood"] && (
                      <div>
                        <Label
                          style={{
                            backgroundColor: "white",
                            color: "#18202F",
                            fontSize: "14px",
                            padding: "0px",
                          }}
                        >
                          Available Datasets for Rainfall Flood
                        </Label>
                        <Checkbox.Group>
                          <Row>
                            {availableDatasets["rainfall_flood"].map(
                              (option) => (
                                <Col span={6} key={option}>
                                  <Checkbox
                                    value={option}
                                  >{`1:${option}`}</Checkbox>
                                </Col>
                              )
                            )}
                          </Row>
                        </Checkbox.Group>
                      </div>
                    )}

                  {selectedVariable.includes("Storm Surge") &&
                    availableDatasets["storm_surge"] && (
                      <div>
                        <Label
                          style={{
                            backgroundColor: "white",
                            color: "#18202F",
                            fontSize: "14px",
                            padding: "0px",
                          }}
                        >
                          Available Datasets for Storm Surge
                        </Label>
                        <Checkbox.Group>
                          <Row>
                            {availableDatasets["storm_surge"].map((option) => (
                              <Col span={6} key={option}>
                                <Checkbox
                                  value={option}
                                >{`1:${option}`}</Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </div>
                    )} */}

                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Distance Options
                  </Label>
                  <br />
                  <Radio.Group
                    options={distanceOptions.map((option) => ({
                      label: `${option} km`,
                      value: option,
                    }))}
                    value={this.state.distance}
                    onChange={this.handleDistanceChange}
                  />
                  <br />

                  {/*<Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Scenario Options
                  </Label>
                  <Checkbox.Group onChange={this.handleScenarioChange}>
                    <Row>
                      {scenarioOptions.map((option) => (
                        <Col
                          span={6}
                          key={option}
                          style={{ marginBottom: "8px" }}
                        >
                          <Checkbox value={option}>{option}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                  <br />
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Year Options
                  </Label>
                  <Checkbox.Group onChange={this.handleYearChange}>
                    <Row>
                      {yearOptions.map((option) => (
                        <Col
                          span={8}
                          key={option}
                          style={{ marginBottom: "8px" }}
                        >
                          <Checkbox value={option}>{option}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>*/}

                  <br />
                </div>
              )}

              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <PortfolioSpinner />
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.handleOpenConfirmation}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,
    locus: state.locus,
    company: state.company.company,
    permissions: state.locus.permissions,
    name: state.locus.name,
    point: state.locus.point,
    indices: state.locus.indices,
    transition: state.locus.transition,
    company_name: state.locus.company_name,
    company_location: state.locus.company_location,
    auth: state.auth,
    map: state.locus.map,
    dataset: state.locus.dataset,
  };
};
const mapDispatchToPros = (dispatch) => {
  return {
    createMap: (formdata) => {
      dispatch(locus.createMap(formdata));
    },
    logout: () => {
      dispatch(auth.logout());
    },
    fetchdataset: (formdata) => {
      dispatch(locus.Dataset(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToPros)(CreateMap);
