import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Select,
  Table,
  Checkbox,
  Accordion,
  Dropdown,
  Card,
} from "semantic-ui-react";
import rainfall from "../assets/rainfall_mt.png";
import flood from "../assets/flood_mt.png";
import fire from "../assets/fire_mt.png";
import IndexRCPDonut from "./index_rcpdonut";
import IndexYEARDonut from "./index_yeardonut";
import drought from "../assets/drought_mt.png";
import storm from "../assets/storm_mt.png";
import heat from "../assets/heat_mt.png";
import wind from "../assets/wind_mt.png";
import { Column } from "@ant-design/charts";

import bgsummary from "../assets/summary-bg.png";
import divimg from "../assets/divimg.png";
import concimg from "../assets/concimg.png";

import tr1 from "../assets/toprow1.png";
import tr2 from "../assets/toprow2.png";
import tr3 from "../assets/toprow3.png";
import tr4 from "../assets/toprow4.png";
import tr5 from "../assets/toprow5.png";

import MapChart from "./MapChart";
import CompanyMap from "./companymap";

import { Tooltip, Progress } from "antd";

import { ResponsiveBar } from "@nivo/bar";
import index from "../assets/crisk.png";
import { linearGradientDef } from "@nivo/core";
import { connect } from "react-redux";

import { project, auth, dashboard, locus, asset } from "../actions";
import Building from "./building";

import "./dashboard.css";

import {
  Tabs,
  Tab,
  Row,
  Col,
  Container,
  tooltip,
  ThemeProvider,
} from "react-bootstrap";
//import Detail from "./detail";
import Circle from "react-circle";

import CompanyRCPDonut from "./company_rcpdonut";
import legend from "../assets/legend_heatmap.png";

import CompanyYEARDonut from "./company_yeardonut";

import CompanyHeatmap from "./company_heatmap";

import BarComponent from "./barcomponent";
import Progress_bar from "./progress_bar";
import LossComponent from "./losscomponent";
import DemoColumn from "./year_donut";

import Map from "./map";
import WorldMap from "./worldmap1";
import RiskMap from "./riskmap";
import CandleStick from "./candlestick";
import PopupMap from "./map1";
import CompanyEditableTable from "./company_table";
import EditableLossTable from "./losstable";
import EditableValuationTable from "./valuationtable";
import EditableClimateTable from "./climatetable";
import MdryearDonut from "./mdryeardonut";
import MdrrcpDonut from "./mdrrcpdonut";
import SingleMdryearDonut from "./singlemdryeardonut";
import SingleMdrrcpDonut from "./singlemdrrcpdonut";

import SummaryMap from "./summarymap";
import LossPercentComponent from "./loss_percent_component";

let data = [];
let options = [];
let Item = [];
let singledata = [];
let data01 = [];
let flat_data = [];

const ContinentOptions = [];
const FinanceOptions = [
  { key: "financial", value: true, text: "Financial" },
  { key: "percent", value: false, text: "Percentage" },
];
const ContentOptions = [
  { key: "add", value: true, text: "Add Content" },
  { key: "remove", value: false, text: "Remove Content" },
];
const AnalysisOptions = [
  { key: "rcp", value: "rcp", text: "RCP" },
  { key: "ssp", value: "ssp", text: "SSP" },
];
const HazardOptions = [
  { key: "River Flood", value: "river_flood", text: "River Flood" },
  { key: "Rainfall Flood", value: "rainfall_flood", text: "Rainfall Flood" },

  { key: "Drought", value: "drought", text: "Drought" },
  { key: "Landslide", value: "landslide", text: "Landslide" },
  { key: "Storm Surge", value: "storm_surge", text: "Storm Surge" },

  { key: "Extreme heat", value: "extreme_heat", text: "Extreme heat" },
  { key: "Typhoon", value: "typhoon", text: "Typhoon" },
  { key: "Snow Melt", value: "snow_melt", text: "Snow Melt" },
  { key: "Sea Level Rise", value: "sea_level_rise", text: "Sea Level Rise" },
  { key: "Wild Fire", value: "wild_fire", text: "Wild Fire" },
];

const RcpOptions = [
  { key: 2.6, value: 2.6, text: "2.6" },
  { key: 4.5, value: 4.5, text: "4.5" },
  { key: 8.5, value: 8.5, text: "8.5" },
];
let VariableOptions = [];
let AssetOptions = [];
const YearOptions = [
  { key: 2030, value: 2030, text: 2030 },
  { key: 2050, value: 2050, text: 2050 },
];
const YearOptions_ssp = [
  { key: 2030, value: 2030, text: 2030 },
  { key: 2050, value: 2050, text: 2050 },
  { key: 2100, value: 2100, text: 2100 },
];
const basementOptions = [
  { key: "yes", value: "yes", text: "yes" },
  { key: "no", value: "no", text: "no" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const constructionOptions = [
  { key: "wood", value: "wood", text: "wood" },
  { key: "concrete", value: "concrete", text: "concrete" },
  { key: "masonry", value: "masonry", text: "masonry" },
  { key: "xs home", value: "xs home", text: "xs home" },
  { key: "light metal", value: "light metal", text: "light metal" },
  { key: "steel", value: "steel", text: "steel" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const IndexOptions = [
  { key: "DAX", value: "DAX", text: "DAX" },
  { key: "DOWJONES", value: "DOWJONES", text: "DOWJONES" },
  { key: "EURO", value: "EURO", text: "EURO" },
  { key: "HSI", value: "HSI", text: "HSI" },
  { key: "S&P500", value: "S&P500", text: "S&P500" },
  { key: "NIFTY", value: "NIFTY", text: "NIFTY" },
];
const storiesOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: "3 or More", value: "3 or More", text: "3 or More" },
  { key: "unknown", valeu: "unknown", text: "unknown" },
];
const occupancyOptions = [
  { key: "res", value: "res", text: "res" },
  { key: "com", value: "com", text: "com" },
  { key: "ind", value: "ind", text: "ind" },
  { key: "unknown", value: "unknown", text: "unknown" },
];
const tableOptions = [
  { key: "Top 3", value: 3, text: "Top 3" },
  { key: "Top 5", value: 5, text: "Top 5" },
  { key: "Top 10", value: 10, text: "Top 10" },
];
const CountOptions = [
  { key: "Top 3", value: "Top 3", text: "Top 3" },
  { key: "Top 5", value: "Top 5", text: "Top 5" },
  { key: "All", value: "All", text: "All" },
];
let ParameterOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "loss", value: "loss", text: "Overall Loss" },
];
let PropertyOptions = [];

class CompanyDashboard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeIndex: 0,
    lossopen: false,
    index: "",
    heatopen: false,
    articleopen: false,
    percentopen: false,
    project: "",
    variable: "River Flood",
    rcp: 8.5,
    year: 2050,
    analysis: "rcp",
    modalOpen: false,
    feedback: "",
    asset_table: "",
    table: 3,
    risk: "",
    single_asset: "",
    activeItemName: "",
    single_asset_overall: "",
    singel_asset_chart: "",
    single_asset_loss: "",
    single_asset_progress: "",
    single_asset_cone_chart: "",
    RcpData: "",
    YearData: "",
    basement: "yes",
    construction: "wood",
    stories: "1",
    occupancy: "res",
    per_sq_m_value: 40000,
    scatter: "",
    yearDetail: "",
    detailed: false,
    global: false,
    singleYearDetail: "",
    portfolio_losses: "",
    portfolio_losses_flat: "",
    overall: "",
    heatmap: "",
    losses: "",
    hazard_continent: "",
    hazard_map_data_with_continent: "",

    data: "",
    financial: true,
    mapVariable: "FL8570",
    yearDetail_single: "",
    assets: [],
    mapAsset: "",
    table_condition: false,
    oldname: "",
    newname: "",
    area: "",
    type: "",
    valuation: "",
    count: "All",
    singlePercentage: "",
    parameter: "loss",
    Million: true,
    exposure_variable: "Total",
    property_type: "NA",
    long: "",
    lat: "",
    zoom: 14,

    activeIndex: -1,
    percentage: "",
    variables: "",
    property_types: "NA",
    country_loss: "",
    countryloss: "",
    hazard_map: "typhoon",
    with_content: false,
    ssp: 8.5,
    defaultbuilding: "",
    continent: "",
    riskOpen: false,
    worldmap: false,
    risk_2020: "",
    losses_2020: "",
    filterOpen: false,
    hazard: "",
    floors: "",
    ownership: "",
    construction_cost: "",
    revenue: "",
    Cvar: false,
    FilterInfo: false,
    key_finding_data: "",
    hazard_index: "",
    key_finding_1: false,
    key_finding_2: false,
    key_finding_3: false,
    card_finding_1: false,
    card_finding_2: false,
    card_finding_3: false,
    card_finding_4: false,
    card_finding_5: false,
    info_table: false,
    single_asset_hazard: "",
    single_asset_hazard_2020: "",
  };

  handleHazard = (e, { value }) => {
    this.setState({
      hazard_map: value,
    });
  };

  handleContinent = (e, { value }) => {
    console.log("Continents", value);
    this.setState({
      continent: value,
    });
  };

  handleKeyFindings_1 = () => {
    this.setState({
      key_finding_1: true,
    });
  };
  handleKeyFindings_2 = () => {
    this.setState({
      key_finding_2: true,
    });
  };
  handleKeyFindings_3 = () => {
    this.setState({
      key_finding_3: true,
    });
  };
  handleCardFindings_1 = () => {
    this.setState({
      card_finding_1: true,
    });
  };
  handleCardFindings_2 = () => {
    this.setState({
      card_finding_2: true,
    });
  };
  handleCardFindings_3 = () => {
    this.setState({
      card_finding_3: true,
    });
  };
  handleCardFindings_4 = () => {
    this.setState({
      card_finding_4: true,
    });
  };
  handleKeyFindings_4 = () => {
    this.setState({
      info_table: true,
    });
  };
  handleCardFindings_5 = () => {
    this.setState({
      card_finding_5: true,
    });
  };
  handleKeyFindings_5 = () => {
    this.setState({
      info_table: true,
    });
  };

  handleCardClose = () => {
    this.setState({
      card_finding_1: false,
      card_finding_2: false,
      card_finding_3: false,
      card_finding_4: false,
      card_finding_5: false,
    });
  };

  handleKeyClose = () => {
    this.setState({
      key_finding_1: false,
      key_finding_2: false,
      key_finding_3: false,
      info_table: false,
    });
  };
  FilterInfo = () => {
    this.setState({
      FilterInfo: true,
    });
  };
  FilterInfoClose = () => {
    this.setState({
      FilterInfo: false,
    });
  };
  handleCvar = () => {
    this.setState({
      Cvar: true,
    });
  };
  handleLossOpen = () => {
    this.setState({
      lossopen: true,
    });
  };
  handleArticleOpen = () => {
    this.setState({
      articleopen: true,
    });
  };
  handlePercentOpen = () => {
    this.setState({
      percentopen: true,
    });
  };
  handleHeatOpen = () => {
    this.setState({
      heatopen: true,
    });
  };
  handleRiskOpen = () => {
    this.setState({
      riskOpen: true,
    });
  };
  RiskClose = () => {
    this.setState({
      riskOpen: false,
    });
  };
  handleFilterOpen = () => {
    this.setState({
      filterOpen: true,
    });
  };
  filterClose = () => {
    this.setState({
      filterOpen: false,
    });
  };
  lossClose = () => {
    this.setState({
      lossopen: false,
    });
  };
  percentClose = () => {
    this.setState({
      percentopen: false,
    });
  };
  heatClose = () => {
    this.setState({
      heatopen: false,
    });
  };
  articleClose = () => {
    this.setState({
      articleopen: false,
    });
  };
  CvarClose = () => {
    this.setState({
      Cvar: false,
    });
  };

  componentDidMount() {
    let formdata = new FormData();
    if (
      this.props.location.state !== null &&
      this.props.location.state.project_name.project
    ) {
      console.log("company state", this.props.location.state);

      formdata.append(
        "project_name",
        this.props.location.state.project_name.project
      );
    } else if (
      this.props.location.state !== null &&
      this.props.location.state.project_name
    ) {
      console.log("company state", this.props.location.state);

      formdata.append("project_name", this.props.location.state.project_name);
    }

    formdata.append("scenario", "8.5");
    formdata.append("year", "2050");
    formdata.append("analysis_type", "rcp");
    this.props.addBenchmark(formdata);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.benchmark !== this.props.benchmark) {
      this.setState({
        yearDetail: this.props.benchmark.year_scenario_loss,
        asset_table: this.props.benchmark.table_data,
        heatmap: this.props.benchmark.heatmap,
        hazard_index: this.props.benchmark.hazard_values,
        hazard: this.props.benchmark.hazard_values_company,
        hazard_continent: this.props.benchmark.hazard_map_continents,
        hazard_map_data_with_continent: this.props.benchmark
          .hazard_map_data_with_continent,
        continent: this.props.benchmark.hazard_map_continents[0],
      });
    }
  }

  Edit = (asset, newAsset) => {
    this.setState(
      {
        oldname: asset.name,
        newname: newAsset.name,
        area: newAsset.area,
        valuation: newAsset.valuation,
        type: newAsset.type.toLowerCase(),
        floors: newAsset.floors,
        ownership: newAsset.ownership,
        construction_cost: newAsset.construction_cost,
        revenue: newAsset.revenue,
      },
      this.EditAsset
    );
  };
  EditAsset() {
    let formdata = new FormData();
    formdata.append("project_name", this.state.project);
    formdata.append("oldname", this.state.oldname);
    formdata.append("newname", this.state.newname);
    formdata.append("area", this.state.area);
    formdata.append("type", this.state.type);
    formdata.append("valuation", this.state.valuation);
    formdata.append("floors", this.state.floors);
    formdata.append("ownership", this.state.ownership);
    formdata.append("construction_cost", this.state.construction_cost);
    formdata.append("revenue", this.state.revenue);

    this.props.updateAsset(formdata);
  }

  handleProject = (e, { value }) => {
    this.setState({ project: value }, () => console.log(this.state.project));
  };

  handleRCP = (e, { value }) => {
    this.setState({ rcp: value }, () => console.log(this.state.rcp));
  };
  handleVariable = (e, { value }) => {
    this.setState({ mapVariable: value });
  };

  handleYear = (e, { value }) => {
    this.setState({ year: value }, () => console.log(this.state.year));
  };
  handleLogout = () => {
    this.props.logout();
  };
  handleOpen = () => {
    if (this.state.hazard_continent) {
      for (let i = 0; i < this.state.hazard_continent.length; i++) {
        ContinentOptions.push({
          key: this.state.hazard_continent[i],
          value: this.state.hazard_continent[i],
          text: this.state.hazard_continent[i],
        });
      }
    }
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => this.setState({ modalOpen: false });

  handleSingleAsset = () => {
    if (this.state.single_asset.length > 0) {
      Item = this.state.single_asset.filter(
        (asset) => asset.asset_name == this.state.activeItemName
      );
      this.setState({ single_asset_overall: Item }, this.handleSingleChart);
    }
    if (this.state.analysis == "rcp") {
      this.setState({
        singleYearDetail: Item[0].year_chart_data.dollar,
        singlePercentage: Item[0].year_chart_data.percentage,
      });
    }
    if (this.state.analysis == "ssp") {
      this.setState({
        singleYearDetail: Item[0].year_chart_data.dollar,
        singlePercentage: Item[0].year_chart_data.percentage,
      });
    }
    if (this.state.assets.length > 0) {
      let Item = this.state.assets.filter(
        (asset) => asset.name == this.state.activeItemName
      );
      this.setState(
        {
          mapAsset: Item,
        },
        () => console.log("single asset map wala", this.state.mapAsset)
      );
    }
  };
  handleSingleChart = () => {
    console.log("fndfdgdgdsgdijgdos", this.state.single_asset_overall);
    singledata = [
      {
        name: "2020",
        "RCP2.6": 0,
        "RCP4.5": 0,
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart["2020"],
      },

      {
        name: "2030",
        "RCP2.6": this.state.single_asset_overall[0].overall_bar_chart[
          "2030_26"
        ],
        "RCP4.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2030_45"
        ],
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2030_85"
        ],
      },

      {
        name: "2050",
        "RCP2.6": this.state.single_asset_overall[0].overall_bar_chart[
          "2050_26"
        ],
        "RCP4.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2050_45"
        ],
        "RCP8.5": this.state.single_asset_overall[0].overall_bar_chart[
          "2050_85"
        ],
      },
    ];

    this.setState(
      {
        singel_asset_chart: singledata,
        single_asset_hazard: this.state.single_asset_overall[0].hazard_values,
        single_asset_hazard_2020: this.state.single_asset_overall[0]
          .hazard_values_2020,

        single_asset_loss: this.state.single_asset_overall[0].loss_bars,
        single_asset_loss_2020: this.state.single_asset_overall[0]
          .loss_bars_2020,
        //single_asset_progress: this.state.single_asset_overall[0].progress_bars,
        //single_asset_progress_2020:
        //this.state.single_asset_overall[0].progress_bars_2020,
        single_asset_cone_chart: this.state.single_asset_overall[0].cone_chart,
      },
      this.handleComparison
    );
  };

  handleComparison = () => {
    let RcpData = [
      {
        name: 2.6,
        variable: "OVERALL",
        index: this.state.single_asset_cone_chart.cone1_26["OVERALL"],
      },

      {
        name: 2.6,
        variable: "River Flood",
        index: this.state.single_asset_cone_chart.cone1_26.both["River Flood"],
      },
      {
        name: 2.6,
        variable: "Drought",
        index: this.state.single_asset_cone_chart.cone1_26.both["Drought"],
      },

      {
        name: 2.6,
        variable: "Storm Surge",
        index: this.state.single_asset_cone_chart.cone1_26.both["Storm Surge"],
      },
      {
        name: 2.6,
        variable: "LandSlide",
        index: this.state.single_asset_cone_chart.cone1_26.both["Landslide"],
      },

      {
        name: 2.6,
        variable: "Extreme Heat",
        index: this.state.single_asset_cone_chart.cone1_26.both["Extreme heat"],
      },
      {
        name: 2.6,
        variable: "Rainfall Flood",
        index: this.state.single_asset_cone_chart.cone1_26.both[
          "Rainfall Flood"
        ],
      },
      {
        name: 2.6,
        variable: "SnowMelt",
        index: this.state.single_asset_cone_chart.cone1_26.both["Snow Melt"],
      },
      {
        name: 2.6,
        variable: "Sea Level Rise",
        index: this.state.single_asset_cone_chart.cone1_26.both[
          "Sea Level Rise"
        ],
      },
      {
        name: 2.6,
        variable: "Typhoon",
        index: this.state.single_asset_cone_chart.cone1_26.both["Typhoon"],
      },
      {
        name: 4.5,
        variable: "OVERALL",
        index: this.state.single_asset_cone_chart.cone1_45["OVERALL"],
      },

      {
        name: 4.5,
        variable: "River Flood",
        index: this.state.single_asset_cone_chart.cone1_45.both["River Flood"],
      },
      {
        name: 4.5,
        variable: "Drought",
        index: this.state.single_asset_cone_chart.cone1_45.both["Drought"],
      },

      {
        name: 4.5,
        variable: "Storm Surge",
        index: this.state.single_asset_cone_chart.cone1_45.both["Storm Surge"],
      },
      {
        name: 4.5,
        variable: "LandSlide",
        index: this.state.single_asset_cone_chart.cone1_45.both["Landslide"],
      },

      {
        name: 4.5,
        variable: "Extreme Heat",
        index: this.state.single_asset_cone_chart.cone1_45.both["Extreme heat"],
      },
      {
        name: 4.5,
        variable: "Rainfall Flood",
        index: this.state.single_asset_cone_chart.cone1_45.both[
          "Rainfall Flood"
        ],
      },
      {
        name: 4.5,
        variable: "SnowMelt",
        index: this.state.single_asset_cone_chart.cone1_45.both["Snow Melt"],
      },
      {
        name: 4.5,
        variable: "Sea Level Rise",
        index: this.state.single_asset_cone_chart.cone1_45.both[
          "Sea Level Rise"
        ],
      },
      {
        name: 4.5,
        variable: "Typhoon",
        index: this.state.single_asset_cone_chart.cone1_45.both["Typhoon"],
      },
      {
        name: 8.5,
        variable: "OVERALL",
        index: this.state.single_asset_cone_chart.cone1_85["OVERALL"],
      },

      {
        name: 8.5,
        variable: "River Flood",
        index: this.state.single_asset_cone_chart.cone1_85.both["River Flood"],
      },
      {
        name: 8.5,
        variable: "Drought",
        index: this.state.single_asset_cone_chart.cone1_85.both["Drought"],
      },

      {
        name: 8.5,
        variable: "Storm Surge",
        index: this.state.single_asset_cone_chart.cone1_85.both["Storm Surge"],
      },
      {
        name: 8.5,
        variable: "LandSlide",
        index: this.state.single_asset_cone_chart.cone1_85.both["Landslide"],
      },

      {
        name: 8.5,
        variable: "Extreme Heat",
        index: this.state.single_asset_cone_chart.cone1_85.both["Extreme heat"],
      },
      {
        name: 8.5,
        variable: "Rainfall Flood",
        index: this.state.single_asset_cone_chart.cone1_85.both[
          "Rainfall Flood"
        ],
      },
      {
        name: 8.5,
        variable: "SnowMelt",
        index: this.state.single_asset_cone_chart.cone1_85.both["Snow Melt"],
      },
      {
        name: 8.5,
        variable: "Sea Level Rise",
        index: this.state.single_asset_cone_chart.cone1_85.both[
          "Sea Level Rise"
        ],
      },
      {
        name: 8.5,
        variable: "Typhoon",
        index: this.state.single_asset_cone_chart.cone1_85.both["Typhoon"],
      },
    ];
    let max = this.state.single_asset_cone_chart.year_max;

    let RcpConfig = {
      data: RcpData,
      isGroup: true,
      yAxis: {
        max: max + 0.2 * max,
      },
      xField: "variable",
      yField: "index",
      seriesField: "name",
      colorField: "name",

      legend: false,
      label: {
        position: "top",
      },
      color: ["#2077b4", "#FE6702", "#4da02d"],
    };
    if (this.state.analysis == "rcp") {
      let yearData = [
        {
          name: 2020,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_20["OVERALL"],
        },

        {
          name: 2020,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "River Flood"
          ],
        },
        {
          name: 2020,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_20.both["Drought"],
        },

        {
          name: 2020,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Storm Surge"
          ],
        },
        {
          name: 2020,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_20.both["Landslide"],
        },

        {
          name: 2020,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Extreme heat"
          ],
        },
        {
          name: 2020,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2020,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_20.both["Snow Melt"],
        },
        {
          name: 2020,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2020,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_20.both["Typhoon"],
        },
        {
          name: 2030,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_30["OVERALL"],
        },

        {
          name: 2030,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "River Flood"
          ],
        },
        {
          name: 2030,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_30.both["Drought"],
        },

        {
          name: 2030,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Storm Surge"
          ],
        },
        {
          name: 2030,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_30.both["Landslide"],
        },

        {
          name: 2030,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Extreme heat"
          ],
        },
        {
          name: 2030,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2030,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_30.both["Snow Melt"],
        },
        {
          name: 2030,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2030,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_30.both["Typhoon"],
        },
        {
          name: 2050,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_50["OVERALL"],
        },

        {
          name: 2050,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "River Flood"
          ],
        },
        {
          name: 2050,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_50.both["Drought"],
        },

        {
          name: 2050,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Storm Surge"
          ],
        },
        {
          name: 2050,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_50.both["Landslide"],
        },

        {
          name: 2050,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Extreme heat"
          ],
        },
        {
          name: 2050,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2050,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_50.both["Snow Melt"],
        },
        {
          name: 2050,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2050,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_50.both["Typhoon"],
        },
      ];
      let max = this.state.single_asset_cone_chart.scenario_max;

      let yearConfig = {
        data: yearData,
        color: ["#f47a7b", "#8cb0f8", "#18202F"],
        yAxis: {
          max: max + 0.2 * max,
        },
        legend: false,
        isGroup: true,
        xField: "variable",
        colorField: "name",

        yField: "index",
        seriesField: "name",

        label: {
          position: "top",
        },
      };
      this.setState({ YearData: yearConfig });
    } else if (this.state.analysis == "ssp") {
      let yearData = [
        {
          name: 2020,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_20.both["OVERALL"],
        },

        {
          name: 2020,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "River Flood"
          ],
        },
        {
          name: 2020,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_20.both["Drought"],
        },

        {
          name: 2020,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Storm Surge"
          ],
        },
        {
          name: 2020,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_20.both["Landslide"],
        },

        {
          name: 2020,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Extreme heat"
          ],
        },
        {
          name: 2020,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2020,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_20.both["Snow Melt"],
        },
        {
          name: 2020,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_20.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2020,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_20.both["Typhoon"],
        },
        {
          name: 2030,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_30.both["OVERALL"],
        },

        {
          name: 2030,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "River Flood"
          ],
        },
        {
          name: 2030,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_30.both["Drought"],
        },

        {
          name: 2030,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Storm Surge"
          ],
        },
        {
          name: 2030,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_30.both["Landslide"],
        },

        {
          name: 2030,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Extreme heat"
          ],
        },
        {
          name: 2030,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2030,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_30.both["Snow Melt"],
        },
        {
          name: 2030,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_30.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2030,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_30.both["Typhoon"],
        },
        {
          name: 2050,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_50.both["OVERALL"],
        },

        {
          name: 2050,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "River Flood"
          ],
        },
        {
          name: 2050,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_50.both["Drought"],
        },

        {
          name: 2050,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Storm Surge"
          ],
        },
        {
          name: 2050,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_50.both["Landslide"],
        },

        {
          name: 2050,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Extreme heat"
          ],
        },
        {
          name: 2050,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2050,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_50.both["Snow Melt"],
        },
        {
          name: 2050,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_50.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2050,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_50.both["Typhoon"],
        },
        {
          name: 2100,
          variable: "OVERALL",
          index: this.state.single_asset_cone_chart.cone3_100.both["OVERALL"],
        },

        {
          name: 2100,
          variable: "River Flood",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "River Flood"
          ],
        },
        {
          name: 2100,
          variable: "Drought",
          index: this.state.single_asset_cone_chart.cone3_100.both["Drought"],
        },

        {
          name: 2100,
          variable: "Storm Surge",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Storm Surge"
          ],
        },
        {
          name: 2100,
          variable: "LandSlide",
          index: this.state.single_asset_cone_chart.cone3_100.both["Landslide"],
        },

        {
          name: 2100,
          variable: "Extreme Heat",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Extreme heat"
          ],
        },
        {
          name: 2100,
          variable: "Rainfall Flood",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Rainfall Flood"
          ],
        },
        {
          name: 2100,
          variable: "SnowMelt",
          index: this.state.single_asset_cone_chart.cone3_100.both["Snow Melt"],
        },
        {
          name: 2100,
          variable: "Sea Level Rise",
          index: this.state.single_asset_cone_chart.cone3_100.both[
            "Sea Level Rise"
          ],
        },
        {
          name: 2100,
          variable: "Typhoon",
          index: this.state.single_asset_cone_chart.cone3_100.both["Typhoon"],
        },
      ];
      let max = this.state.single_asset_cone_chart.scenario_max;

      let yearConfig = {
        data: yearData,
        color: ["#f47a7b", "#8CB0F8", "#18202F", "#256EFF"],
        yAxis: {
          max: max + 0.2 * max,
        },
        legend: false,
        isGroup: true,
        xField: "variable",
        colorField: "name",

        yField: "index",
        seriesField: "name",

        label: {
          position: "top",
        },
      };
      this.setState({ YearData: yearConfig });
    }

    this.setState(
      {
        RcpData: RcpConfig,
      },
      () => console.log("yeardata", this.state.yearData)
    );
  };
  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleChangePro = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => console.log("analysis", this.state.analysis)
    );
  };
  handleTable = (value, key) => {
    this.setState({
      [key]: value,
    });
  };
  handleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };
  handleSingleExposure = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      this.handleSubmitPro
    );
  };

  handleFullScreen = () => {
    document.getElementById("HeatMap").requestFullscreen();
  };
  handleLossFullScreen = () => {
    document.getElementById("Loss").requestFullscreen();
  };
  handleIntFullScreen = () => {
    document.getElementById("Intensity").requestFullscreen();
  };

  handleClickOpen = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSubmitPro = () => {
    let formdata = new FormData();

    formdata.append("project_name", this.props.location.state.project_name);

    formdata.append("index_name", this.state.index);
    formdata.append("scenario", "8.5");
    formdata.append("year", "2050");
    formdata.append("analysis_type", "rcp");
    this.props.addBenchmark(formdata);

    this.setState({
      filterOpen: false,
    });
  };

  handleLocal = (e) => {
    this.setState(
      {
        localactive: true,
        globalactive: false,
      },
      this.handleSubmitPro
    );
  };

  handleGlobal = (e) => {
    this.setState(
      {
        globalactive: true,
        localactive: false,
      },
      this.handleSubmitPro
    );
  };
  handleFinance = (e) => {
    this.setState({
      financial: !this.state.financial,
    });
  };

  handleSsp = (e) => {
    this.setState({
      ssp: !this.state.ssp,
    });
  };

  handleWorld = (e) => {
    this.setState((prevState) => ({
      worldmap: !prevState.worldmap,
    }));
  };
  handleDetail = (e) => {
    this.setState((prevState) => ({
      detailed: !prevState.detailed,
    }));
  };
  handleContent = (e) => {
    localStorage.setItem("with_content", !this.state.with_content);
    this.setState({
      with_content: !this.state.with_content,
    });
  };
  handleAsset = (e, { value }) => {
    let selectedAsset = this.state.asset_table.filter(
      (asset) => asset.name == value
    );
    console.log("selectedAsset", selectedAsset[0].latitude);
    this.setState(
      {
        lat: selectedAsset[0].latitude,
        long: selectedAsset[0].longitude,
      },
      () => console.log("lat passing", this.state.lat, this.state.long)
    );
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  handleAccClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex }, () =>
      console.log("active", this.state.activeIndex)
    );
  };
  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
  }

  render() {
    this.state.variables = localStorage.getItem("variables");
    const isDarkMode = localStorage.getItem("theme") == "dark";

    if (this.props.isLoading) {
      return <div id="loading"> </div>;
    }

    return (
      <div>
        <br />

        <Container fluid={true}>
          <Row>
            <Col xs={12} sm={12} lg={12}>
              <Icon
                name="info"
                onClick={this.FilterInfo}
                style={{ cursor: "pointer", float: "right" }}
              />{" "}
              <Button
                style={{
                  backgroundColor: "rgb(255,171,0)",
                  color: "black",
                  float: "right",
                }}
                onClick={this.handleFilterOpen}
              >
                Advance Filter{" "}
              </Button>
              <Tabs
                className="dashboard"
                defaultActiveKey="overall"
                id="navtabav"
              >
                <Tab title="OVERALL" eventKey="overall" id="navtab">
                  <>
                    <br />
                    <Container fluid={true}>
                      <Row>
                        <Col className="firstshadow" lg={7} xs={12} sm={12}>
                          <Row>
                            <Col lg={6} xs={12} sm={12}>
                              <Row className="mainheading">
                                {" "}
                                <p>
                                  {" "}
                                  <span className={"dark-mode-theme-color-1"}>
                                    {" "}
                                    Year Loss for{" "}
                                    {this.state.analysis.toUpperCase()}{" "}
                                    {this.state.analysis == "rcp" ? (
                                      <span>{this.state.rcp}</span>
                                    ) : (
                                      <span>{this.state.ssp}</span>
                                    )}{" "}
                                  </span>
                                </p>{" "}
                              </Row>

                              {this.state.financial ? (
                                <CompanyYEARDonut
                                  data={this.state.yearDetail}
                                  rcp={this.state.rcp}
                                  year={this.state.year}
                                  analysis={this.state.analysis}
                                  ssp={this.state.ssp}
                                />
                              ) : (
                                <MdryearDonut
                                  rcp={this.state.rcp}
                                  year={this.state.year}
                                  data={this.state.percentage}
                                  analysis={this.state.analysis}
                                  ssp={this.state.ssp}
                                />
                              )}
                            </Col>{" "}
                            <Col lg={6} xs={12} sm={12}>
                              <Row className="mainheading">
                                {" "}
                                <p>
                                  {" "}
                                  <span className={"dark-mode-theme-color-1"}>
                                    {" "}
                                    Year Loss for{" "}
                                    {this.state.analysis.toUpperCase()}{" "}
                                    {this.state.analysis == "rcp" ? (
                                      <span>{this.state.rcp}</span>
                                    ) : (
                                      <span>{this.state.ssp}</span>
                                    )}{" "}
                                  </span>
                                </p>{" "}
                              </Row>

                              {this.state.financial ? (
                                <IndexYEARDonut
                                  data={this.state.yearDetail}
                                  rcp={this.state.rcp}
                                  year={this.state.year}
                                  analysis={this.state.analysis}
                                  ssp={this.state.ssp}
                                />
                              ) : (
                                <MdryearDonut
                                  rcp={this.state.rcp}
                                  year={this.state.year}
                                  data={this.state.percentage}
                                  analysis={this.state.analysis}
                                  ssp={this.state.ssp}
                                />
                              )}
                            </Col>{" "}
                          </Row>
                          <Row>
                            <Col lg={6} xs={12} sm={12}>
                              <Row className="mainheading">
                                {" "}
                                <p>
                                  {" "}
                                  <span className={"dark-mode-theme-color-1"}>
                                    {" "}
                                    {this.state.analysis.toUpperCase()} Loss for
                                    Year {this.state.year}{" "}
                                  </span>
                                </p>{" "}
                              </Row>

                              {this.state.financial ? (
                                <CompanyRCPDonut
                                  data={this.state.yearDetail}
                                  year={this.state.year}
                                  key_find={this.state.key_finding_data}
                                  analysis={this.state.analysis}
                                />
                              ) : (
                                <MdrrcpDonut
                                  year={this.state.year}
                                  data={this.state.percentage}
                                  key_find={this.state.key_finding_data}
                                  analysis={this.state.analysis}
                                />
                              )}
                            </Col>{" "}
                            <Col lg={6} xs={12} sm={12}>
                              <Row className="mainheading">
                                {" "}
                                <p>
                                  {" "}
                                  <span className={"dark-mode-theme-color-1"}>
                                    {" "}
                                    {this.state.analysis.toUpperCase()} Loss for
                                    Year {this.state.year}{" "}
                                  </span>
                                </p>{" "}
                              </Row>

                              {this.state.financial ? (
                                <IndexRCPDonut
                                  data={this.state.yearDetail}
                                  year={this.state.year}
                                  key_find={this.state.key_finding_data}
                                  analysis={this.state.analysis}
                                />
                              ) : (
                                <MdrrcpDonut
                                  year={this.state.year}
                                  data={this.state.percentage}
                                  key_find={this.state.key_finding_data}
                                  analysis={this.state.analysis}
                                />
                              )}
                            </Col>
                          </Row>{" "}
                        </Col>{" "}
                        <Col lg={1}></Col>
                        <Col lg={4} className="firstshadow">
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Climate Risk Hazard{" "}
                              </span>
                            </p>
                          </Row>
                          <Row>
                            <Col>
                              {this.state.hazard_index && this.state.hazard ? (
                                <Progress_bar
                                  newdata={this.state.hazard_index}
                                  hazard={this.state.hazard}
                                  variable={[
                                    "River Flood",
                                    "Typhoon",
                                    "Storm Surge",
                                    "Rainfall Flood",
                                    "Landslide",
                                    "Sea Level Rise",
                                    "Wild Fire",
                                    "Snow Melt",
                                    "Drought",
                                    "Extreme heat",
                                  ]}
                                  activeIndex={this.state.activeIndex}
                                />
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <hr
                                className="intense1"
                                style={{
                                  borderTop: "2px solid #FF8080",
                                  width: "20%",
                                }}
                              />
                              <hr
                                className="intense2"
                                style={{
                                  borderTop: "2px solid #18202F",
                                  width: "20%",
                                }}
                              />
                            </Col>
                            <Col>
                              Index <br />
                              Company
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                    <br />
                    <Container fluid={true}>
                      <br />

                      <Row>
                        {/*<Col
                          lg={7}
                          xs={12}
                          sm={12}
                          className="firstshadow"
                          style={{
                            height: "550px",
                            overflowX: "auto",
                            overflowY: "hidden",
                          }}
                        >
                          <CandleStick
                            data={this.state.overall.candle_stick_chart}
                          />
                        </Col>*/}
                        <Col
                          className="firstshadow"
                          lg={12}
                          xs={16}
                          sm={16}
                          style={{
                            maxWidth: "95.6%",
                            maxHeight: "550px",
                            overflowY: "auto",
                          }}
                        >
                          <Row className="mainheading">
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Climate Risk Heat Map{" "}
                              </span>
                            </p>
                          </Row>

                          <Row>
                            <Col lg={10} style={{ height: "450px" }}>
                              {this.state.heatmap ? (
                                <CompanyHeatmap
                                  data={this.state.heatmap}
                                  variable={[
                                    "River Flood",
                                    "Typhoon",
                                    "Storm Surge",
                                    "Rainfall Flood",
                                    "Landslide",
                                    "Sea Level Rise",
                                    "WildFire",
                                    "Snow Melt",
                                    "Drought",
                                    "Extreme heat",
                                  ]}
                                />
                              ) : null}
                            </Col>
                            <Col lg={2}>
                              <Image src={legend} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col
                          className="firstshadow"
                          lg={12}
                          xs={16}
                          sm={16}
                          style={{
                            maxWidth: "95.6%",
                            maxHeight: "550px",
                            overflowY: "auto",
                          }}
                        >
                          <Row className="mainheading">
                            {" "}
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Global Score/CVaR Maps
                              </span>
                            </p>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              {/* <Dropdown
                                placeholder="Year"
                                fluid
                                selection
                                options={yearOptions}
                                value={year}
                                onChange={(e, { value }) =>
                                  this.handleChange(value, "year")
                                }
                              />*/}
                            </Col>
                          </Row>
                          <CompanyMap />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col
                          className="firstshadow"
                          lg={12}
                          xs={16}
                          sm={16}
                          style={{
                            maxWidth: "95.6%",
                            maxHeight: "550px",
                            overflowY: "auto",
                          }}
                        >
                          <Row className="mainheading">
                            {" "}
                            <p>
                              <span className={"dark-mode-theme-color-1"}>
                                Asset Table{" "}
                              </span>
                            </p>
                          </Row>
                          <br />
                          <Row>
                            <Col>
                              {/* <Select
                                style={{ float: "right" }}
                                placeholder="Select"
                                options={tableOptions}
                                onChange={(e, { value }) =>
                                  this.handleTable(value, "table")
                                }
                                value={table}
                              />*/}

                              {this.state.asset_table ? (
                                <CompanyEditableTable
                                  data={this.state.asset_table}
                                  open={this.handleOpen}
                                  update={this.Edit}
                                />
                              ) : null}

                              {/*   <Tab title="Overall Loss" eventKey="Loss">
                                  <br />{" "}
                                  {this.state.table_condition && (
                                    <EditableLossTable
                                      data={this.state.asset_table}
                                      open={this.handleOpen}
                                      update={this.Edit}
                                      table={this.state.table}
                                    />
                                  )}
                                </Tab>
                                <Tab title="Climate  Score" eventKey="Climate">
                                  <br />{" "}
                                  {this.state.table_condition && (
                                    <EditableClimateTable
                                      data={this.state.asset_table}
                                      open={this.handleOpen}
                                      update={this.Edit}
                                      table={this.state.table}
                                    />
                                  )}
                                </Tab>
                                <Tab title="Value" eventKey="Value">
                                  <br />
                                  {this.state.table_condition && (
                                    <EditableValuationTable
                                      data={this.state.asset_table}
                                      open={this.handleOpen}
                                      update={this.Edit}
                                      table={this.state.table}
                                    />
                                  )}
                                  </Tab>*/}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </Tab>
                {/* <Tab title="DETAILED" eventKey="Detailed">
                  <Detail
                    rcp={this.state.rcp}
                    year={this.state.year}
                    project={this.state.project}
                    detailed={this.state.detailed}
                    handleDetail={this.handleDetail}
                    asset={this.state.asset_table}
                                  />
                </Tab>

                <Tab title="HAZARDS" eventKey="hazards">
                  <div className="note-hazards">
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#18202F",
                        float: "right",
                        marginRight: "50px",
                      }}
                    >
                      Hover-Over the icons to go through the information on the
                      hover wheel
                    </p>
                  </div>
                  <div class="parent_div">
                    <div class="circle_3">
                      <p class="text-center">
                        {" "}
                        <span
                          class="mytooltip tooltip-effect-1"
                          style={{ marginTop: "200px" }}
                        >
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="windimg"
                              src={wind}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span class="tooltip-content clearfix">
                            <span class="tooltip-text">
                              {" "}
                              <h2>Extreme Wind Speeds</h2> <br /> Maximum wind
                              speed at multiple return periods <br />
                              <br />
                              Fraction of building, contents and inventory
                              damaged by extreme wind events <br />
                              <br />
                              Annual average loss to building, contents and
                              inventory
                              <br />
                              Number of days of business interruption
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <p class="text-center">
                        {" "}
                        <span class="mytooltip tooltip-effect-1">
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="rainfallimg"
                              src={rainfall}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span class="tooltip-content clearfix">
                            <span class="tooltip-text">
                              <h2>Extreme Rainfall</h2>
                              <br />
                              Maximum daily precipitation across multiple return
                              periods
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <div>
                        <p class="text-center">
                          {" "}
                          <span class="mytooltip tooltip-effect-1">
                            {" "}
                            <span class="tooltip-item">
                              <img
                                class="fireimg"
                                src={fire}
                                style={{
                                  height: "50px",
                                  border: "2px solid #000",
                                }}
                              />
                            </span>{" "}
                            <span class="tooltip-content clearfix">
                              <span class="tooltip-text">
                                <h2>Wildfire Risk</h2>
                                <br />
                                The annual number of wildfires, which ignite and
                                grow to detectable size, <br />
                                that are expected in a 1 square km grid cell
                              </span>{" "}
                            </span>{" "}
                          </span>
                        </p>

                        <p class="text-center">
                          {" "}
                          <span class="mytooltip tooltip-effect-1">
                            {" "}
                            <span class="tooltip-item">
                              <img
                                class="stormimg"
                                src={storm}
                                style={{
                                  height: "50px",
                                  border: "2px solid #000",
                                }}
                              />
                            </span>{" "}
                            <span class="tooltip-content clearfix">
                              <span class="tooltip-text">
                                <h2>Hail And Thunderstorm Probability</h2>
                                <br />
                                Number of days per year where large hail (>2 in
                                / 5 cm in diameter) is possible
                                <br />
                                <br />
                                Number of days per year where environmental
                                conditions are conducive to severe thunderstorm
                                formation
                              </span>{" "}
                            </span>{" "}
                          </span>
                        </p>
                      </div>

                      <p class="text-center">
                        {" "}
                        <span class="mytooltip tooltip-effect-1">
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="droughtimg"
                              src={drought}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span class="tooltip-content clearfix">
                            <span class="tooltip-text">
                              <h2>Drought Frequency</h2>
                              <br />
                              Months per year where the rolling 3-month or
                              6-month average
                              <br />
                              <br />
                              Standardized Precipitation Evapotranspiration
                              Index (SPEI) is below -2
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <p class="text-center">
                        {" "}
                        <span class="mytooltip tooltip-effect-1">
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="floodimg"
                              src={flood}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span
                            class="tooltip-content clearfix"
                            style={{ marginBottom: "20px" }}
                          >
                            <span
                              class="tooltip-text"
                              style={{ marginBottom: "20px" }}
                            >
                              <h2>Flood Depth of Water</h2>
                              <br />
                              Flood depth of water across multiple return
                              periods
                              <br />
                              <br />
                              Percentage of the central square flooded across
                              multiple return periods
                              <br />
                              <br />
                              Fraction of building, contents and inventory
                              damaged by flood events
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>

                      <p class="text-center">
                        {" "}
                        <span
                          class="mytooltip tooltip-effect-1"
                          style={{ marginBottom: "50px" }}
                        >
                          {" "}
                          <span class="tooltip-item">
                            <img
                              class="heatimg"
                              src={heat}
                              style={{
                                height: "50px",
                                border: "2px solid #000",
                              }}
                            />
                          </span>{" "}
                          <span
                            class="tooltip-content clearfix"
                            style={{ marginBottom: "20px" }}
                          >
                            <span
                              class="tooltip-text"
                              style={{ marginBottom: "20px" }}
                            >
                              <h2>Extreme Heat</h2>
                              <br />
                              Days per year exceeding 35°C, 38°C and the
                              historical 99th percentile
                              <br />
                              <br />
                              Heating and cooling degree days
                              <br />
                              <br />
                              Days exceeding high (32°C) and dangerously high
                              (35°C) Wet-Bulb Globe Temperature
                            </span>{" "}
                          </span>{" "}
                        </span>
                      </p>
                      <div class="circle_2">
                        <div class="fillerdiv"></div>
                        <div class="circle_mt">
                          <div class="text_mt">HAZARDS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>*/}
              </Tabs>
            </Col>
          </Row>
        </Container>
        <Modal
          open={this.state.filterOpen}
          closeIcon
          onClose={this.filterClose}
        >
          <Modal.Header className="advance">
            <h2>Advanced Filter Options</h2>
            <p>Filtering your data for more insight</p>
          </Modal.Header>
          <Modal.Content className="Filter">
            {/* <Row>
              <Col lg={4}>
                <p>Projects</p>
                <Form.Select
                  placeholder="Project"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "project")
                  }
                  value={project}
                  options={options}
                  disabled={this.state.detailed}
                />
              </Col>

              <Col lg={4}>
                <p>Analysis Type</p>
                <Form.Select
                  placeholder="Analysis Type"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "analysis")
                  }
                  value={analysis}
                  options={AnalysisOptions}
                />
              </Col>

              <Col lg={4}>
                {this.state.analysis == "rcp" ? (
                  <>
                    {" "}
                    <p>RCP</p>
                    <Form.Select
                      placeholder="RCP"
                      onChange={(e, { value }) =>
                        this.handleChangePro(value, "rcp")
                      }
                      value={rcp}
                      options={RcpOptions}
                      disabled={this.state.detailed}
                    />
                  </>
                ) : (
                  <>
                    <p>SSP</p>
                    <Form.Select
                      placeholder="SSP"
                      onChange={(e, { value }) =>
                        this.handleChangePro(value, "ssp")
                      }
                      value={ssp}
                      options={RcpOptions}
                    />
                  </>
                )}
              </Col>
            </Row>*/}

            <Row>
              {/* <Col>
                <p>Count</p>
                <Form.Select
                  placeholder="count"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "count")
                  }
                  value={count}
                  options={CountOptions}
                  disabled={this.state.detailed}
                />
                </Col>*/}
              {/** <Col lg={4}>
                <p>Scenario Type</p>

                <Form.Select
                  placeholder="Scenario Type"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "financial")
                  }
                  value={this.state.financial}
                  options={FinanceOptions}
                />
              </Col>
              <Col lg={4}>
                <p>Year</p>
                {this.state.analysis == "rcp" ? (
                  <Form.Select
                    placeholder="Year"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "year")
                    }
                    value={year}
                    options={YearOptions}
                    disabled={this.state.detailed}
                  />
                ) : (
                  <Form.Select
                    placeholder="Year"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "year")
                    }
                    value={year}
                    options={YearOptions_ssp}
                    disabled={this.state.detailed}
                  />
                )}
              </Col>*/}
              <Col lg={4}>
                <p>Index Name</p>
                <Form.Select
                  placeholder="Index Name"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "index")
                  }
                  value={this.state.index}
                  options={IndexOptions}
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* <Row>
              <Col lg={4}>
                <p>Additional Factor</p>
                <Form.Select
                  placeholder="Additional Factor"
                  onChange={(e, { value }) =>
                    this.handleChangePro(value, "adjusted_gdp")
                  }
                  value={this.state.adjusted_gdp}
                  options={GdpOptions}
                />
              </Col>
            </Row>*/}
            <br />
            <br />
            {/* <Row>
              {Property != "NA" ? (
                <Col lg={4}>
                  <p>Property Type</p>
                  <Form.Select
                    placeholder="Property Types"
                    onChange={(e, { value }) =>
                      this.handleChangePro(value, "property_type")
                    }
                    value={property_type}
                    options={PropertyOptions}
                  />
                </Col>
              ) : null}
                  </Row>*/}
            <Button
              onClick={this.handleSubmitPro}
              style={{
                backgroundColor: "#18202F",
                color: "white",
                float: "right",
              }}
            >
              Apply Filter
            </Button>
            <br />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    project: state.project.project,
    benchmark: state.feedback.benchmark,
    building: state.feedback.building,
    detailyear: state.feedback.detailyear,
    isLoading: state.feedback.isLoading,
    assets: state.asset.asset,
    summary: state.feedback.summary,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addDashboard: (formdata) => {
      dispatch(dashboard.postDashboard(formdata));
    },
    addBenchmark: (formdata) => {
      dispatch(dashboard.postBenchmark(formdata));
    },
    getProjects: () => {
      dispatch(project.getProjects());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    getDetailByYear: (formdata) => {
      dispatch(dashboard.getDetailByYear(formdata));
    },
    getAssets: () => {
      dispatch(asset.getAssets());
    },
    updateAsset: (formdata) => {
      dispatch(asset.updateAsset(formdata));
    },
    getSummary: (formdata) => {
      dispatch(dashboard.getSummary(formdata));
    },
    sspDashboard: (formdata) => {
      dispatch(dashboard.sspDashboard(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard);
