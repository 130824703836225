import React, { Component, createRef } from "react";
import { Table, Switch, Radio, Row, Col, Button } from "antd";
import { Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { locus } from "../actions";
import { getBoundsOfDistance } from "geolib";

import "./intensel_map.css";
/* eslint import/no-webpack-loader-syntax: off */

import mapboxgl from "!mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";

class LegendControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl legend";
    const legendItems = [
      { color: "#faffff", label: "0 to 0.625" },
      { color: "#d3fad3", label: "0.625 to 1.25" },
      { color: "#acf5a8", label: "1.25 to 1.875" },
      { color: "#56c5b8", label: "1.875 to 2.5" },
      { color: "#0096c8", label: "2.5 to 3.125" },
      { color: "#00507d", label: "3.125 to 3.75" },
      { color: "#000a32", label: "3.75 to 4.375" },
      { color: "#000519", label: "4.375 to 5" },
      { color: "#000000", label: "5" },
    ];

    legendItems.forEach((item) => {
      const legendItem = document.createElement("div");
      legendItem.className = "legend-item";

      const colorBox = document.createElement("span");
      colorBox.className = "legend-color";
      colorBox.style.backgroundColor = item.color;

      const label = document.createElement("span");
      label.className = "legend-label";
      label.textContent = item.label;

      legendItem.appendChild(colorBox);
      legendItem.appendChild(label);
      this._container.appendChild(legendItem);
    });

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

class MyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [
        {
          key: "1",
          name: "City A",
          country_code: "CHN",
          rainfall_flood: 0.687032,
          gdp: 44.6213,
          populationDensity: 2788.9462,
          rainfall_flood_max: 2.72103,
          rainfall_flood_min: 0.30654,
        },
        // Add more data as needed
      ],
      modalVisible: false,
      filterModalVisible: false,
      selectedCity: null,
      mapStyle: "mapbox://styles/mapbox/satellite-v9",
      name: "",
      scenario: "85",
      year: "2050",
      return_period: "100",
      latitude: "",
      longitude: "",
      yearOptions: [],
      rcpOptions: [],
      showTable: false, // Flag to control table visibility
      mapBounds: null, // State variable to hold map bounds
    };
    this.rasterLayerAdded = false;
    this.mapContainer = createRef();
    this.map = null;
  }

  getDataForScenarioAndYear = (data, scenario, year) => {
    const scenarioKey = `${scenario}_${year}`;
    console.log("scenarioKey", scenarioKey);
    return {
      rainfall_flood_avg: data.rainfall_flood_avg.rcp[scenarioKey],
      rainfall_flood_max: data.rainfall_flood_max.rcp[scenarioKey],
      rainfall_flood_min: data.rainfall_flood_min.rcp[scenarioKey],
      name: data.name,
      gdp: data.gdp,
      populationDensity: data.population,
      country_code: data.country_code,
    };
  };

  updateDataSource = (dataSource, apiResponse, scenario, year) => {
    return dataSource.map((city) => {
      const newData = this.getDataForScenarioAndYear(
        apiResponse,
        scenario,
        year
      );
      return { ...city, ...newData };
    });
  };

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.name
    ) {
      let formdata = new FormData();
      formdata.append("name", this.props.location.state.name.name);

      this.setState({ name: this.props.location.state.name.name }, () => {
        this.props.generateMap(formdata);
      });
    }
    this.initializeMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mapBounds !== this.state.mapBounds && this.map) {
      this.map.setMaxBounds(this.state.mapBounds);
    }
    if (prevProps.map_response !== this.props.map_response) {
      const { map_response } = this.props;
      const yearOptions = map_response.years.map((year) => ({
        label: year,
        value: year,
      }));
      const rcpOptions = map_response.scenarios.map((scenario) => ({
        label: scenario,
        value: scenario,
      }));

      const updatedDataSource = this.updateDataSource(
        this.state.dataSource,
        map_response,
        this.state.scenario,
        this.state.year
      );

      this.setState(
        {
          dataSource: updatedDataSource,
          yearOptions,
          rcpOptions,
        },
        this.updateRasterLayer
      );

      if (
        map_response.coordinates.latitude &&
        map_response.coordinates.longitude
      ) {
        const bounds = getBoundsOfDistance(
          {
            latitude: map_response.coordinates.latitude,
            longitude: map_response.coordinates.longitude,
          },
          1000
        );

        this.setState({
          mapBounds: [
            [bounds[0].longitude, bounds[0].latitude],
            [bounds[1].longitude, bounds[1].latitude],
          ],
          latitude: map_response.coordinates.latitude,
          longitude: map_response.coordinates.longitude,
        });
      }

      if (
        prevState.scenario !== this.state.scenario ||
        prevState.year !== this.state.year ||
        prevState.return_period !== this.state.return_period
      ) {
        const updatedDataSource = this.updateDataSource(
          this.state.dataSource,
          this.props.map_response,
          this.state.scenario,
          this.state.year
        );
        this.setState(
          { dataSource: updatedDataSource },
          this.updateRasterLayer
        );
      }

      if (prevState.mapStyle !== this.state.mapStyle) {
        this.updateMapStyle();
      }
    }
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }

  initializeMap = () => {
    if (!this.map) {
      this.map = new mapboxgl.Map({
        container: this.mapContainer.current,
        style: this.state.mapStyle,
        center: [this.state.longitude, this.state.latitude],
        zoom: 17,
        maxbounds: this.state.mapBounds,
      });
      this.map.addControl(new mapboxgl.FullscreenControl());

      this.map.on("load", () => {
        this.addRasterLayer();
        this.addLegendControl();
      });
      this.map.on("click", this.handleMapHover);
    } else {
      this.updateMapStyle();
    }
  };

  updateMapStyle = () => {
    if (this.map) {
      this.map.setStyle(this.state.mapStyle);
      this.map.on("style.load", this.addRasterLayer);
    }
  };

  updateRasterLayer = () => {
    if (this.map) {
      if (this.map.getSource("wms-test-source")) {
        this.map.removeLayer("wms-test-layer");
        this.map.removeSource("wms-test-source");
      }
      this.addRasterLayer();
    }
  };

  addRasterLayer = () => {
    if (this.map.getSource("wms-test-source")) {
      this.map.removeLayer("wms-test-layer");
      this.map.removeSource("wms-test-source");
    }

    this.map.addSource("wms-test-source", {
      type: "raster",
      tiles: [
        "https://alpha-map.intensel.net/cgi-bin/mapserv?map=/data/conf/xyz.map&mode=tile&tilemode=gmap&tile={x}+{y}+{z}&layers=rasterr&format=image/png",
      ],
      tileSize: 256,
    });

    this.map.addLayer({
      id: "wms-test-layer",
      type: "raster",
      source: "wms-test-source",
      paint: {},
    });

    this.rasterLayerAdded = true;
  };
  addLegendControl = () => {
    const legend = new LegendControl();
    this.map.addControl(legend, "bottom-right");
  };

  /* addLegendControl = () => {
    const legend = new mapboxgl.Control();

    legend.onAdd = (map) => {
      const div = document.createElement("div");
      div.className = "mapboxgl-ctrl legend";
      const legendItems = [
        { color: "#faffff", label: "0 to 0.625" },
        { color: "#d3fad3", label: "0.625 to 1.25" },
        { color: "#acf5a8", label: "1.25 to 1.875" },
        { color: "#56c5b8", label: "1.875 to 2.5" },
        { color: "#0096c8", label: "2.5 to 3.125" },
        { color: "#00507d", label: "3.125 to 3.75" },
        { color: "#000a32", label: "3.75 to 4.375" },
        { color: "#000519", label: "4.375 to 5" },
        { color: "#000000", label: "5" },
      ];

      legendItems.forEach((item) => {
        const legendItem = document.createElement("div");
        legendItem.className = "legend-item";

        const colorBox = document.createElement("span");
        colorBox.className = "legend-color";
        colorBox.style.backgroundColor = item.color;

        const label = document.createElement("span");
        label.className = "legend-label";
        label.textContent = item.label;

        legendItem.appendChild(colorBox);
        legendItem.appendChild(label);
        div.appendChild(legendItem);
      });

      return div;
    };

    legend.onRemove = () => {
      if (legend._container) {
        legend._container.parentNode.removeChild(legend._container);
        this.map.off("move", this.onMove);
      }
      this.map = null;
    };

    this.map.addControl(legend, "bottom-right");
  };*/

  handleMapHover = (e) => {
    const { lng, lat } = e.lngLat;
    const bounds = this.map.getBounds();
    const width = this.map.getCanvas().width;
    const height = this.map.getCanvas().height;

    const params = {
      service: "WMS",
      version: "1.1.1",
      request: "GetFeatureInfo",
      layers: "raster",
      styles: "",
      srs: "EPSG:4326",
      bbox: bounds
        .toArray()
        .flat()
        .join(","),
      width: width,
      height: height,
      query_layers: "raster",
      x: Math.round(e.point.x),
      y: Math.floor(e.point.y),
      info_format: "geojson",
    };
    const url = `https://alpha-map.intensel.net/cgi-bin/mapserv?map=IND_MAPFILE&ISO3=IND&${Object.entries(
      params
    )
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const value = data["features"][0]["properties"]["value_0"];

        new mapboxgl.Popup()
          .setLngLat([lng, lat])
          .setHTML(`<p> Flood Depth at Location ${value.toFixed(2)}</p>`)
          .addTo(this.map);
      })
      .catch((error) => {
        console.error("Error fetching WMS GetFeatureInfo data:", error);
      });
  };

  removePopup = () => {
    this.popup.remove();
  };

  handleAnalyse = (record) => {
    this.setState({ selectedCity: record, modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  };

  handleMapStyleChange = (checked) => {
    const newStyle = checked
      ? "mapbox://styles/mapbox/streets-v11"
      : "mapbox://styles/mapbox/satellite-v9";
    this.setState({ mapStyle: newStyle });
  };

  handlePeriodChange = (checked) => {
    const returnPeriod = checked ? "100" : "250";
    this.setState({ return_period: returnPeriod });
  };

  handleScenarioChange = (checkedValues) => {
    this.setState({ selectedScenarios: checkedValues });
  };

  handleYearChange = (e) => {
    this.setState({ year: e.target.value });
  };

  handleRCPChange = (e) => {
    this.setState({ scenario: e.target.value });
  };

  openFilterModal = () => {
    this.setState({ filterModalVisible: true });
  };

  closeFilterModal = () => {
    this.setState({ filterModalVisible: false });
  };

  applyFilter = () => {
    const updatedDataSource = this.updateDataSource(
      this.state.dataSource,
      this.props.map_response,
      this.state.scenario,
      this.state.year
    );
    this.setState(
      {
        dataSource: updatedDataSource,
        filterModalVisible: false,
      },
      this.updateRasterLayer
    );
  };

  handleTableVisibility = (checked) => {
    this.setState({ showTable: checked });
  };

  render() {
    console.log("mapbound", this.state.mapBounds);
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Country Code",
        dataIndex: "country_code",
        key: "country_code",
      },
      {
        title: "Rainfall Flood Avg (in m)",
        dataIndex: "rainfall_flood_avg",
        key: "rainfall_flood_avg",
      },
      {
        title: "Rainfall Flood Max (in m)",
        dataIndex: "rainfall_flood_max",
        key: "rainfall_flood_max",
      },
      {
        title: "Rainfall Flood Min(in m)",
        dataIndex: "rainfall_flood_min",
        key: "rainfall_flood_min",
      },
      {
        title: "GDP(USD/capita)",
        dataIndex: "gdp",
        key: "gdp",
      },
      {
        title: "Population Density(per sqkm)",
        dataIndex: "populationDensity",
        key: "populationDensity",
        render: (text) => <span>{text} people/sq km</span>,
      },
    ];

    return (
      <div className="custom-table">
        <Button
          type="primary"
          onClick={this.openFilterModal}
          style={{
            float: "right",
            color: "black",
            backgroundColor: "rgb(255,171,0)",
            marginBottom: "16",
            fontFamily: "montserrat",
            border: "0px",
          }}
        >
          Filter Options
        </Button>
        <br />
        <br />
        {this.state.showTable && (
          <div>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              bordered
              pagination={false}
            />
            <br />
          </div>
        )}

        <Switch
          checkedChildren="Street View"
          unCheckedChildren="Satellite View"
          onChange={this.handleMapStyleChange}
          style={{ marginBottom: 16 }}
        />
        <Switch
          className="hide"
          checkedChildren="Show Table"
          unCheckedChildren="Hide Table"
          onChange={this.handleTableVisibility}
          style={{ marginBottom: 16, float: "right" }}
        />

        <div
          ref={this.mapContainer}
          style={{ width: "100%", height: "800px", display: "block" }}
        />
        <Modal
          open={this.state.filterModalVisible}
          onClose={this.closeFilterModal}
        >
          <Modal.Header>Filter Options</Modal.Header>
          <Modal.Content>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <p>Year Options</p>
                <Radio.Group
                  options={this.state.yearOptions}
                  defaultValue={this.state.year}
                  onChange={this.handleYearChange}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
              <Col span={8}>
                <p>Scenario Options</p>
                <Radio.Group
                  options={this.state.rcpOptions}
                  defaultValue={this.state.scenario}
                  onChange={this.handleRCPChange}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>

              <br />
              <Col span={8}>
                <p>Return Period</p>
                <Switch
                  checkedChildren="100"
                  unCheckedChildren="250"
                  onChange={this.handlePeriodChange}
                />
              </Col>
            </Row>
            <Row>
              <Col span={16}></Col>

              <Col span={8}>
                <Button key="cancel" onClick={this.closeFilterModal}>
                  Cancel
                </Button>
                <Button key="apply" type="primary" onClick={this.applyFilter}>
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    map_response: state.locus.map_response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateMap: (formdata) => {
      dispatch(locus.generateMap(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTable);
