import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Dropdown,
  Accordion,
} from "semantic-ui-react";
import "./location.css";
import { loadModules } from "esri-loader";
import { CSVReader } from "react-papaparse";
import logo from "../assets/logo.png";
import home from "../assets/home.png";
import add from "../assets/images/add.png";
import sample from "../assets/image.png";
import search from "../assets/search.png";
import upload from "../assets/upload.png";
import hsi from "../assets/hsi.png";
import sp from "../assets/sp.png";
import dax from "../assets/dax.png";
import dawjones from "../assets/dowjones.png";
import nasdaq from "../assets/nasdaq.png";
import euro from "../assets/euro.png";
import nifty from "../assets/nifty.png";
import nikkie from "../assets/nikkei.png";

import { connect } from "react-redux";
import { locus, auth } from "../actions";
import { Checkbox, Typography } from "antd";

import { company } from "../actions";
import Suggestion from "./suggestion";
import Spinner from "./spinner";
import LocationMap from "./location_map";
import RealEstateMap from "./realestate_map";
import { CSVLink } from "react-csv";
import { changeTitle } from "../redux/actions";
import { Redirect } from "react-router-dom";
import { Tabs, Tab, Row, Col, Container, Breadcrumb } from "react-bootstrap";
import video from "../assets/video.mp4";
import video1 from "../assets/video.gif";

import TransitionTable from "./transitiontable";

import { Table } from "antd";

const buttonRef = React.createRef();

const companyRef = React.createRef();

let mapcards = [];
let companycards = [];
let selectList = "";
let optionList = [];
let newmapcards = [];
let csvcards = [];
let buildingcsvcards = [];

let selectedList = [];

const othersOptions = [
  { key: "Flood", value: "River Flood", text: "Flood" },
  { key: "Rainfall", value: "Rainfall", text: "Rainfall" },

  { key: "Drought Index", value: "Drought Index", text: "Drought Index" },
  { key: "Landslide", value: "Landslide", text: "Landslide" },
  { key: "Storm Surge", value: "Storm Surge", text: "Storm Surge" },

  { key: "Extreme heat", value: "Extreme heat", text: "Extreme heat" },
  { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
];

let minedata = [];

const columns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Flood Scores",
    key: "flood_scores",
    dataIndex: "flood_scores",
  },
  {
    title: "Typhoon Scores",
    key: "typhoon_scores",
    dataIndex: "typhoon_scores",
  },
  {
    title: "GDP",
    key: "gdp",
    dataIndex: "gdp",
  },
  {
    title: "Population Density",
    key: "population",
    dataIndex: "population",
  },
];

const csvData = [
  ["name", "latitude", "longitude"],
  ["intensel", "0.00", "0.00"],
];
const ContinentOptions = [
  { key: "Asia", value: ["Asia", 100.6197, 34.04, 3], text: "Asia" },
  { key: "Europe", value: ["Europe", 15.2551, 54.526, 3], text: "Europe" },
  {
    key: "Australia",
    value: ["Australia", 133.7751, -25.2744, 3],
    text: "Australia",
  },
  { key: "America", value: ["America", -95.7129, 37.0902, 2], text: "America" },
  { key: "Africa", value: ["Africa", 34.5085, 8.7832, 3], text: "Africa" },
];

const indexOptions = [
  { key: "HSI", value: "HSI", text: "HSI" },
  { key: "S&P-500", value: "S&P-500", text: "S&P-500" },
  { key: "dax", value: "dax", text: "dax" },
  { key: "dowjones", value: "dowjones", text: "dowjones" },
  { key: "nasdaq", value: "nasdaq", text: "nasdaq" },
  { key: "eurostock", value: "eurostock", text: "eurostock" },
  { key: "nifty", value: "nifty", text: "nifty" },
  { key: "nikkie", value: "nikkie", text: "nikkie" },
];

const SearchOptions = [
  { key: "HTML Search", value: "html", text: "HTML Search" },
  { key: "Shallow Search", value: "shallow", text: "Shallow Search" },
  { key: "Deep Search", value: "deep", text: "Deep Search" },
];

class Realestate extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  state = {
    upload: "",
    file: false,
    locations: [],
    query: "",
    option: "",
    variables: [],
    buildingVariables: [],
    overwrite: "false",
    company: [],
    portfolio: "",
    selectedOption: null,
    newlocations: [],
    modalOpen: false,
    videoOpen: false,
    loading: false,
    long: 114.1838,
    lat: 22.2797,
    zoom: 16,
    continent: "Continents",
    selectedTags: [],
    allTags: [],
    buildingselectedTags: [],
    allbuildingTags: [],
    optional: "",
    infoOpen: false,
    buildinginfoOpen: false,
    restrictOpen: false,
    address: "",
    companyloading: false,
    buildingmodalOpen: false,
    search_type: "",
    index: "HSI",
    modalIndicesOpen: false,
    portfolio_value: "",
    portfolio_name: "",
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  handleIndex = (e) => {
    console.log("checked", e.target.id);

    if (this.state.index == e.target.id) {
      this.setState({ index: "" });
    } else {
      this.setState({ index: e.target.id }, () =>
        this.props.getIndices(this.state.index)
      );
    }
  };

  handleChange = (e, { value }) => {
    this.setState({ selectedOption: value }, () =>
      console.log("selectedoption", this.state.selectedOption)
    );
  };
  onSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    formdata.append("data", JSON.stringify(this.state.locations));

    formdata.append("project_name", this.state.portfolio);
    this.setState({ loading: true }, () => {
      this.props.PropertyRisk(formdata);
    });
  };
  onIndicesSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    formdata.append("index_name", this.state.index);
    formdata.append("portfolio_name", this.state.portfolio);

    this.setState({ loading: true }, () => {
      this.props.addIndex(formdata);
    });
  };
  onbuildingSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("value", JSON.stringify(this.state.locations));
    formdata.append("portfolio_name", this.state.portfolio);
    formdata.append("variables", JSON.stringify(this.state.variables));
    this.setState({ loading: true }, () => {
      this.props.addBuildingLocations(formdata);
    });
  };

  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleFileLoad = (data) => {
    csvcards = [];
    for (let i = 1; i < data.length - 1; i++) {
      if (data[i].data[3] == "") {
        data[i].data[3] = "NA";
      }
      if (data[i].data[5] == "") {
        data[i].data[5] = "NA";
      }
      if (
        data[i].data[4] == "" ||
        !this.state.optional.includes("Construction Cost")
      ) {
        data[i].data[4] = "NA";
      }
      if (data[i].data[6] == "" || !this.state.optional.includes("Area")) {
        data[i].data[6] = "NA";
      }
      if (data[i].data[7] == "" || !this.state.optional.includes("Valuation")) {
        data[i].data[7] = "NA";
      }
      if (data[i].data[8] == "" || !this.state.optional.includes("Floors")) {
        data[i].data[8] = "NA";
      }
      if (data[i].data[9] == "" || !this.state.optional.includes("Solar")) {
        data[i].data[9] = "NA";
      }
      if (data[i].data[10] == "" || !this.state.optional.includes("Wind")) {
        data[i].data[10] = "NA";
      }
      if (data[i].data[11] == "" || !this.state.optional.includes("Hydro")) {
        data[i].data[11] = "NA";
      }
      if (data[i].data[12] == "" || !this.state.optional.includes("Wheat")) {
        data[i].data[12] = "NA";
      }
      if (data[i].data[13] == "" || !this.state.optional.includes("Rice")) {
        data[i].data[13] = "NA";
      }
      if (data[i].data[14] == "" || !this.state.optional.includes("Maize")) {
        data[i].data[14] = "NA";
      }
      if (data[i].data[15] == "" || !this.state.optional.includes("Fruit")) {
        data[i].data[15] = "NA";
      }
      if (data[i].data[16] == "" || !this.state.optional.includes("Veg")) {
        data[i].data[16] = "NA";
      }
      if (
        data[i].data[17] == "" ||
        !this.state.optional.includes("Ownership")
      ) {
        data[i].data[17] = "NA";
      }

      console.log("filename", data);

      csvcards.push({
        name: data[i].data[0],
        latitude: data[i].data[1],
        longitude: data[i].data[2],
        revenue: data[i].data[3],
        property_type: data[i].data[5],
        country_code: "NA",
        construction_cost: data[i].data[4],
        area: data[i].data[6],
        valuation: data[i].data[7],
        stories: data[i].data[8],
        solar_production: data[i].data[9],
        hydro_production: data[i].data[11],
        wind_production: data[i].data[10],
        wheat_production: data[i].data[12],
        rice_production: data[i].data[13],
        maize_production: data[i].data[14],
        fruit_production: data[i].data[15],
        vegetable_production: data[i].data[16],
        ownership: data[i].data[17],
      });
    }
    this.setState({ allTags: csvcards, infoOpen: false }, () =>
      console.log("multiple", this.state.allTags)
    );
  };

  handleCompanyDialog = (e) => {
    if (companyRef.current) {
      companyRef.current.open(e);
    }
  };

  handelOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleIndicesOpen = () => this.setState({ modalIndicesOpen: true });
  handlebuildOpen = () => this.setState({ buildingmodalOpen: true });
  handlevideoOpen = () => this.setState({ videoOpen: true });
  handleinfoOpen = () => this.setState({ infoOpen: true });
  handleBuildinginfoOpen = () => this.setState({ buildinginfoOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
      videoOpen: false,
      infoOpen: false,
      restrictOpen: false,
      buildinginfoOpen: false,
      buildingmodalOpen: false,
      modalIndicesOpen: false,
    });

  handleContinent = (e, { value }) => {
    console.log("Continents", value);
    this.setState({
      continent: value[0],
      long: value[1],
      lat: value[2],
      zoom: value[3],
    });
  };

  componentDidMount() {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/widgets/Legend",
        "esri/Graphic",
        "esri/widgets/Search",
        "esri/tasks/Locator",
      ],
      { css: true }
    ).then(
      ([
        ArcGISMap,
        MapView,
        FeatureLayer,
        Legend,
        Graphic,
        Search,
        Locator,
      ]) => {
        let that = this;

        const map = new ArcGISMap({
          basemap: "streets-night-vector",
        });

        const view = new MapView({
          container: "viewDiv",
          map: map,
          center: [this.state.long, this.state.lat],
          zoom: this.state.zoom,
        });

        var search = new Search({
          view: view,
        });
        view.ui.add(search, "top-right");

        view.on("click", function(event) {
          console.log(event.mapPoint.latitude, event.mapPoint.longitude);
          createGraphic(event.mapPoint.latitude, event.mapPoint.longitude);
          search.clear();

          if (search.activeSource) {
            var geocoder = search.activeSource.locator;
            var locator = new Locator(
              "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN"
            );
            var params = {
              location: event.mapPoint,
            };

            locator.locationToAddress(params).then(
              function(response) {
                var address = response.address;
                console.log("addressssss", response);
                mapcards.push([
                  address,
                  event.mapPoint.latitude,
                  event.mapPoint.longitude,
                  "NA",
                  "NA",
                ]);
                console.log("mapcard", mapcards);
                that.setState({ locations: mapcards }, () =>
                  console.log("locations", that.state.locations)
                );
              },
              function(err) {
                console.log("errror", err);
              }
            );
          }
        });
        function createGraphic(lat, long) {
          // First create a point geometry
          var point = {
            type: "point", // autocasts as new Point()
            longitude: long,
            latitude: lat,
          };

          // Create a symbol for drawing the point
          var markerSymbol = {
            type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
            color: [255, 0, 0],
          };

          // Create a graphic and add the geometry and symbol to it
          var pointGraphic = new Graphic({
            geometry: point,
            symbol: markerSymbol,
          });

          // Add the graphics to the view's graphics layer
          view.graphics.add(pointGraphic);
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
    mapcards = [];
  }

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };
  handleRemoveLocation = (index) => {
    var array = [...this.state.locations];

    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ locations: array });
      mapcards.splice(index, 1);
    }
  };
  handleRemoveNewLocation = (index) => {
    var array = [...this.state.newlocations];
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ newlocations: array });
      newmapcards.splice(index, 1);
    }
  };
  handleSearch = (e, { value }) => {
    this.setState({ company: value }, this.handleAsync);
  };
  handleSearchType = (e, { value }) => {
    this.setState({ search_type: value });
  };
  /* handleAsync=()=>{
  		console.log("ye his",this.state.company);
  		mapcards=[];
  		for(let i=0;i<this.state.company.length;i++){
  		let LocationList = companyList.filter(company=>company.NAME===this.state.company[i])
  		  	
  		  	console.log("Location company",LocationList);
  		  	
  		  		console.log("working",LocationList[0].coord)
  		  		if(LocationList[0].ADDRESS.length>0){
  		  		let a = JSON.parse(LocationList[0].ADDRESS.replace(/'/g,'"'));
  		  		console.log("working",a);
  		  		let b = JSON.parse(LocationList[0].coord.replace(/'/g,'"'));
  		  		console.log("working",b[0].split(',')[0].replace(/[\])}[{(]/g, ''),b[0].split(',')[1].replace(/[()]/g,''));
  		  		
  		  		for(let j=0;j<b.length;j++){
  		  			mapcards.push([a[j],b[j].split(',')[0].replace(/[\])}[{(]/g, ''),b[j].split(',')[1].replace(/[\])}[{(]/g, ''),"NA","NA"])
  		  		}
  		  		this.setState({locations:mapcards},()=>console.log("comapnay",mapcards))
  		  		}
  		  		

  		  	

		}

  }*/
  handleAddress = (e) => {
    let formdata = new FormData();
    formdata.append("website", this.state.address);
    formdata.append("parser", this.state.search_type);
    this.setState({ companyloading: true }, () => this.props.getName(formdata));
  };

  handleOptions = (e) => {
    if (e.target.value.length > 3) {
      let formdata = new FormData();
      formdata.append("search_term", e.target.value);
      this.props.getName(formdata);

      let searchList = [];
      if (this.props.name.length > 0) {
        console.log("name", this.props.name);

        for (let i = 0; i < this.props.name.length; i++) {
          searchList.push({
            key: this.props.name[i],
            text: this.props.name[i],
            value: this.props.name[i],
          });
        }

        this.setState({ option: searchList }, () =>
          console.log("option", this.state.option)
        );
      }

      this.setState({ query: e.target.value }, () =>
        console.log(this.state.query)
      );
    }
  };
  /*handleOptions =(e)=>{
  	
  	if(e.target.value.length>1){
  		this.selectList=companyList.filter(company=>company.NAME.slice(0,2).toLowerCase()==e.target.value.slice(0,2).toLowerCase())
  		
  		let searchList = this.selectList.map(({NAME}) =>{
	return{
		key:NAME,
		value:NAME,
		text:NAME
	}
})      
  		
  		this.setState({option:searchList},()=>console.log("option",this.state.option));
  		
  	}
  	
  	  		this.setState({query:e.target.value},()=>console.log(this.state.query))

  }*/

  handleLogout = () => {
    this.props.logout();
  };
  onChange = (checkedValues) => {
    console.log("checked", checkedValues);
    this.setState({ variables: checkedValues });
  };
  onBuildingChange = (checkedValues) => {
    this.setState({ buildingVariables: checkedValues });
  };
  handleOptional = (checkedValues) => {
    this.setState({ optional: checkedValues }, () =>
      console.log("optional", this.state.optional)
    );
  };
  handleCompanyLoad = (data) => {
    console.log("filename", data[1].data[0]);
    for (let i = 1; i < 3; i++) {
      this.props.getCoordinates(data[i].data[0]);

      if (this.props.point.type) {
        minedata.push(this.props.point.features[i].geometry.coordinates);
        console.log("mine data", minedata);
      }

      /*	let LocationList = companyList.filter(company=>company.NAME==data[i].data[0])
  		  	
  		  	console.log("Location company",LocationList,data[i].data[0]);
  		  	
  		  		console.log("chal raha hai ",LocationList[0].ADDRESS)
  		  		if(LocationList[0].ADDRESS.length>0){
  		  		let a = JSON.parse(LocationList[0].ADDRESS.replace(/'/g,'"'));
  		  		console.log("working",a);
  		  		let b = JSON.parse(LocationList[0].coord.replace(/'/g,'"'));
  		  		console.log("working",b[0].split(',')[1].replace(/[()]/g,''));
  		  		
  		  		for(let j=0;j<b.length;j++){
  		  			mapcards.push([a[j],b[j].split(',')[0].replace(/[()]/g,''),b[j].split(',')[1].replace(/[()]/g,''),"NA","NA"])
  		  		}
  		  		this.setState({locations:mapcards},()=>console.log("csvcomapnay",mapcards))
  		  		}*/
    }
  };
  handleAsset = (address, latitude, longitude) => {
    mapcards.push({
      name: address,
      latitude: latitude,
      longitude: longitude,
    });
    this.setState({ locations: mapcards }, () =>
      console.log("map", this.state.locations, mapcards.length)
    );
  };
  handleClear = () => {
    companycards = [];
    this.setState({ locations: [], address: "" });
  };

  addtoSelected(asset) {
    selectedList.push(asset);
    var array = [...this.state.allTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ allTags: array });
    }
    this.setState({ selectedTags: selectedList, locations: selectedList });
  }
  remove(asset) {
    var array = [...this.state.selectedTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array, locations: array }, () =>
        console.log("remove", this.state.selectedTags)
      );
    }
    this.state.allTags.push(asset);
  }
  addAll = () => {
    var array = [...this.state.allTags];
    this.setState({ selectedTags: array, locations: array });
  };
  clearAll = () => {
    this.setState({ selectedTags: "", locations: "", allTags: "" });
  };

  addtobuildingSelected(asset) {
    selectedList.push(asset);
    var array = [...this.state.allbuildingTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ allbuildingTags: array });
    }
    this.setState({
      buildingselectedTags: selectedList,
      locations: selectedList,
    });
  }
  buildingremove(asset) {
    var array = [...this.state.buildingselectedTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ buildingselectedTags: array, locations: array }, () =>
        console.log("remove", this.state.buildingselectedTags)
      );
    }
    this.state.allbuildingTags.push(asset);
  }
  addbuildingAll = () => {
    var array = [...this.state.allbuildingTags];
    this.setState({ buildingselectedTags: array, locations: array });
  };
  clearbuildingAll = () => {
    this.setState({
      buildingselectedTags: "",
      locations: "",
      allbuildingTags: "",
    });
  };

  render() {
    const { Text, Link } = Typography;

    const isDarkMode = localStorage.getItem("theme") == "dark";

    console.log("state clear", this.props.transition);

    const { activeIndex, others } = this.state;

    if (this.props.point.type) {
      minedata.push([
        this.props.point.features[0].place_name,
        this.props.point.features[0].geometry.coordinates[0],
        this.props.point.features[0].geometry.coordinates[1],
      ]);
      console.log("minedata", minedata);
    }

    if (this.props.location.state) {
      let newmapcards = this.props.location.state.assets.assets;

      var newcards = [];

      if (newmapcards.length > 0) {
        this.state.newlocations = newmapcards;

        console.log("this.s", this.state.newlocations);
        for (let i = 0; i < this.state.newlocations.length; i++) {
          newcards.push(
            <Grid.Column width="2" className="cont">
              <Label className="card">
                <div className="front">
                  <div className="img-cont">
                    <Image
                      src={home}
                      alt=""
                      style={{ float: "center" }}
                      verticalAlign="middle"
                    />
                  </div>
                  <div className="content-cont">
                    <p
                      style={{
                        textAlign: "center",
                        color: "#015edc",
                        fontSize: "12px",
                      }}
                    >
                      {this.state.newlocations[i].name}
                    </p>
                  </div>
                </div>
                <div className="back">
                  <button
                    style={{
                      float: "right",
                      backgroundColor: "white",
                      border: "0px",
                      fontSize: "10px",
                      color: "grey",
                      marginLeft: "55%",
                    }}
                    onClick={() => this.handleRemoveNewLocation(i)}
                  >
                    <Image
                      src={search}
                      style={{ float: "right", padding: "8px", opacity: "0.5" }}
                      color="grey"
                      size="mini"
                    />
                  </button>

                  <p
                    style={{
                      textAlign: "center",
                      color: "#015edc",
                      fontSize: "12px",
                    }}
                  >
                    <Icon
                      name="map marker alternate"
                      style={{ color: "#015edc" }}
                      size="large"
                    />
                    <br />
                    Lat {this.state.newlocations[i].latitude}
                    <br />
                    Long {this.state.newlocations[i].longitude}
                  </p>
                </div>
              </Label>
            </Grid.Column>
          );
        }
      }
    }

    const { value, continent } = this.state;
    var cards = [];
    var csvtags = [];
    var buildingcsvtags = [];
    if (mapcards.length > 0) {
      for (let i = 0; i < this.state.locations.length; i++) {
        cards.push(
          <div className="table__row">
            <div className="table__cell">{i + 1}</div>
            <div className="table__cell">
              {this.state.locations[i].name.slice(0, 20)}
            </div>
            <div className="table__cell">
              {this.state.locations[i].latitude.toFixed(3)}
            </div>
            <div className="table__cell">
              {this.state.locations[i].longitude.toFixed(3)}
            </div>
            <div className="table__cell">
              <Icon
                name="trash alternate"
                onClick={() => this.handleRemoveLocation(i)}
                style={{ cursor: "pointer" }}
              ></Icon>
            </div>
          </div>
        );
      }
    }

    console.log("props", this.props.name);
    if (this.props.name.length > 0 && this.state.address.length > 0) {
      this.state.companyloading = false;
      this.state.locations = [];
      companycards = [];
      for (let i = 0; i < this.props.name.length; i++) {
        this.state.locations.push({
          name: this.props.name[i].Address,
          latitude: this.props.name[i].latitude,
          longitude: this.props.name[i].longitude,
          revenue: "NA",
          property_type: "NA",
          country_code: "NA",
        });
        let src = "//logo.clearbit.com/";
        companycards.push(
          <Grid.Column width="2" className="cont">
            <Label className="card">
              <div className="front">
                <div className="img-cont">
                  <Image
                    src={src + this.props.name[i].domain}
                    alt=""
                    style={{ float: "center" }}
                    verticalAlign="middle"
                  />
                </div>
                <div className="content-cont">
                  <p
                    style={{
                      textAlign: "center",
                      color: "#18202F",
                      fontSize: "12px",
                    }}
                  >
                    {this.props.name[i].address}
                  </p>
                </div>
              </div>
              <div className="back">
                <button
                  style={{
                    float: "right",
                    backgroundColor: "white",
                    border: "0px",
                    fontSize: "10px",
                    color: "grey",
                    marginLeft: "55%",
                  }}
                  onClick={() => this.handleRemoveLocation(i)}
                >
                  <Image
                    src={search}
                    style={{ float: "right", padding: "8px", opacity: "0.5" }}
                    color="grey"
                    size="mini"
                  />
                </button>

                <p
                  style={{
                    textAlign: "center",
                    color: "#18202F",
                    fontSize: "12px",
                  }}
                >
                  <Icon
                    name="map marker alternate"
                    style={{ color: "#18202F  " }}
                    size="large"
                  />
                  <br />
                  Lat {this.props.name[i].lat}
                  <br />
                  Long {this.props.name[i].lon}
                </p>
              </div>
            </Label>
          </Grid.Column>
        );
      }
      console.log("company location", this.state.locations);
    }

    if (csvcards.length > 0) {
      for (let i = 0; i < this.state.allTags.length; i++) {
        csvtags.push(
          <Label style={{ display: "block" }}>
            {this.state.allTags[i]["name"]}{" "}
            <Icon
              name="plus"
              onClick={() => this.addtoSelected(this.state.allTags[i])}
              style={{ float: "right" }}
            />
          </Label>
        );
      }
    }
    if (buildingcsvcards.length > 0) {
      for (let i = 0; i < this.state.allbuildingTags.length; i++) {
        buildingcsvtags.push(
          <Label style={{ display: "block" }}>
            {this.state.allbuildingTags[i]["name"]}{" "}
            <Icon
              name="plus"
              onClick={() =>
                this.addtobuildingSelected(this.state.allbuildingTags[i])
              }
              style={{ float: "right" }}
            />
          </Label>
        );
      }
    }

    return (
      <div>
        <Container
          fluid
          style={{ backgroundColor: "#18202F" }}
          className="sticky-top"
        >
          <Row>
            <br />
            <Breadcrumb>
              <Breadcrumb.Item>RealEstate</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <br />
        <Grid padded verticalAlign="middle">
          <Row>
            <Col>
              <Tabs className="location" defaultActiveKey="Asset">
                <Tab title="Asset" eventKey="Asset">
                  <br />
                  <Row>
                    <Col lg={8} sm={12} xs={12}>
                      {/* <Header
                        className="asset"
                        as="h5"
                        style={{ width: "100%" }}
                      >
                        <span className={"dark-mode-primary-color"}>
                          Select location to analyze climate risk on map{" "}
                          <Icon name="map marker alternate" />
                        </span>
                        <Form.Select
                          placeholder={continent}
                          value={continent}
                          onChange={this.handleContinent}
                          options={ContinentOptions}
                          style={{ float: "right", minHeight: "1.7em" }}
                        />
    </Header>*/}

                      <RealEstateMap
                        className="location-map-container"
                        location={this.handleAsset}
                      />
                    </Col>

                    <Col lg={4} className="asset">
                      <Header
                        className="asset"
                        as="h5"
                        style={{ width: "100%" }}
                      >
                        <span className={"dark-mode-primary-color"}>
                          Selected Asset
                        </span>
                      </Header>

                      <div className="table">
                        <div className="table__body">
                          <div className="table__row table__heading">
                            <div className="table__cell">No.</div>
                            <div
                              className="table__cell"
                              style={{ color: "black" }}
                            >
                              Name
                            </div>
                            <div className="table__cell">Lat</div>
                            <div className="table__cell">Long</div>
                            <div className="table__cell">
                              <Icon name="trash alternate" />
                            </div>
                          </div>
                          {cards}
                        </div>
                      </div>

                      <Button
                        primary
                        onClick={this.handleOpen}
                        style={{
                          float: "right",
                          fontSize: "16px",
                          borderRadius: 5,
                          backgroundColor: "#18202F",
                          color: "white",
                        }}
                      >
                        SUBMIT
                      </Button>

                      <br />
                      {this.props.location.state ? (
                        <div>
                          <Header as="h2" textAlign="left">
                            My Assets
                          </Header>
                          <Grid.Row>{newcards}</Grid.Row>
                        </div>
                      ) : null}
                      <br />
                      <br />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  className="location"
                  title="Multiple Asset CSV"
                  eventKey="Multiple Asset CSV"
                >
                  <Row>
                    <Col lg={8} sm={12} xs={12}>
                      <h3>
                        <span className={"dark-mode-primary-color"}>
                          Upload CSV <Icon name="file alternate" />
                        </span>
                      </h3>

                      <CSVLink data={csvData}>
                        <Button
                          style={{
                            color: "#18202F",
                            backgroundColor: "#f6f8fa",
                            border: "0.5px solid #18202F",
                          }}
                        >
                          <span className={"dark-mode-primary-color"}>
                            Download Sample CSV
                          </span>
                        </Button>
                      </CSVLink>

                      <br />
                      <br />

                      <CSVReader
                        ref={buttonRef}
                        onFileLoad={this.handleFileLoad}
                        onError={this.handleOnError}
                        noClick
                        noDrag
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: isDarkMode ? "white" : "black",
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 0,
                                marginBottom: 0,
                                paddingLeft: 5,
                                paddingTop: 3,
                                width: "80%",
                                borderRadius: 0,
                                backgroundColor: "transparent",
                              }}
                            >
                              <Header
                                class="asset"
                                as="h5"
                                style={{ padding: "10px" }}
                              >
                                <span className="dark-mode-primary-color">
                                  No file selected
                                </span>
                              </Header>
                              <button
                                style={{
                                  float: "right",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                  fontSize: "10px",
                                  color: "grey",
                                  marginTop: "-50px",
                                }}
                                onClick={this.handleRemoveFile}
                              >
                                <Icon
                                  name="close"
                                  style={{ color: "white", padding: "8px" }}
                                  size="big"
                                />
                              </button>
                            </div>
                            <button
                              type="button"
                              onClick={this.handleOpenDialog}
                              style={{
                                borderRadius: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                width: "10%",

                                paddingRight: 0,
                                border: 0,
                                borderWidth: 1,
                                borderColor: isDarkMode ? "white" : "black",
                                borderStyle: "solid",
                                backgroundColor: "white",
                                color: "grey",

                                height: "45px",
                              }}
                            >
                              <Image
                                src={upload}
                                style={{
                                  padding: "10%",
                                  width: "30px",
                                  height: "30px",
                                }}
                                color="grey"
                                centered
                              />
                            </button>
                          </aside>
                        )}
                      </CSVReader>
                      <br />
                      <br />
                      <Grid.Row>
                        <Grid.Column
                          className="csvbox"
                          style={{ width: "42.5%" }}
                        >
                          <h3>All Assets</h3>
                          <div class="border">{csvtags}</div>
                        </Grid.Column>
                        <Grid.Column
                          style={{ width: "12.5%" }}
                          verticalAlign="middle"
                        >
                          <Button
                            onClick={this.addAll}
                            style={{
                              position: "absolute",
                              top: "60%",
                              padding: "1%",
                              marginLeft: "1%",
                              backgroundColor: "#18202F",
                              color: "white",
                              fontSize: "0.9rem",
                            }}
                          >
                            {" "}
                            Add All
                          </Button>
                          <Button
                            onClick={this.clearAll}
                            style={{
                              position: "absolute",
                              top: "65%",
                              padding: "1%",
                              marginLeft: "1%",
                              backgroundColor: "#18202F",
                              color: "white",
                              fontSize: "0.9rem",
                            }}
                          >
                            Clear All
                          </Button>
                        </Grid.Column>
                        <Grid.Column
                          className="csvbox"
                          style={{ width: "42.5%" }}
                        >
                          <h3>Selected Assets</h3>
                          <div class="border">
                            {this.state.selectedTags.length > 0
                              ? this.state.selectedTags.map((asset, index) => (
                                  <Label
                                    key={index}
                                    style={{ display: "block" }}
                                  >
                                    {asset["name"]}
                                    <Icon
                                      name="close"
                                      onClick={() => this.remove(asset)}
                                    />
                                  </Label>
                                ))
                              : null}
                          </div>
                          <Button
                            primary
                            onClick={this.handleOpen}
                            style={{
                              float: "right",
                              fontSize: "16px",
                              borderRadius: 5,
                              backgroundColor: "#18202F",
                              color: "white",
                              marginTop: "22%",
                            }}
                          >
                            SUBMIT
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Col>
                  </Row>
                </Tab>

                {/*  <Tab title="Non-Building" eventKey="Nonbuilding">
      <h3><span className={"dark-mode-primary-color"}>Upload CSV  <Icon name="file alternate"/></span></h3>
              <Button style={{backgroundColor:'#f88b8b',color:'white',float:'right'}} onClick={this.handlevideoOpen}><Icon name="lightbulb"/>How to Upload</Button>
      

      <CSVLink data={csvData}><Button style={{color:'#18202F',backgroundColor:'#f6f8fa',border:'0.5px solid #18202F'}}><span className={"dark-mode-primary-color"}>Download Sample CSV</span></Button></CSVLink>
      
      <br/>
      <br/>
      
      
       <CSVReader
      
        ref={buildingRef}
        onFileLoad={this.handleBuildingFileLoad}
        onError={this.handleOnError}
        noClick
        noDrag
        
      >
        {({ file }) => (
          <aside
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10

            }}
          >
            
              
            <div
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isDarkMode?'white':'black',
                height: 45,
                lineHeight: 2.5,
                marginTop: 0,
                marginBottom: 0,
                paddingLeft: 5,
                paddingTop: 3,
                width: '80%',
                borderRadius:0,
                backgroundColor:'transparent',
                
                

              }}
            >
              <Header class="asset" as="h5"  style={{padding:'10px'}}><span className="dark-mode-primary-color">No file selected</span></Header>
             <button style={{float:'right',backgroundColor:'transparent',border:'0px',fontSize:'10px',color:'grey',marginTop:'-50px'}} onClick={this.handleRemoveFile}><Icon name='close' style={{ color:'white',padding:'8px'}} size="big"/></button>
            </div>
            <button
              type='button'
              onClick={this.handleBuildinginfoOpen}
              style={{
                borderRadius: 0,
                marginLeft:0,
                marginRight: 0,
                width: '10%',
                
                paddingRight: 0,
                border:0,
                borderWidth:1,
                borderColor:isDarkMode?'white':'black',
                borderStyle: 'solid',
                backgroundColor:'white',
                color:'grey',
                
                height:'45px'
                
              }}
            >
              <Image src={upload} style={{padding:'10%',width:'30px',height:'30px'}} color="grey" centered/>
            </button>
          </aside>
        )}
      </CSVReader>
      <br/><br/>
      <Grid.Row>
        
        <Grid.Column className="csvbox" style={{width:'42.5%'}}>
        <h3>All Assets</h3>
        <div class="border">{buildingcsvtags}</div>
        </Grid.Column>
        <Grid.Column style={{width:'12.5%'}} verticalAlign="middle">
        <Button  onClick={this.addbuildingAll} style={{position:'absolute',top:'60%',padding:'1%',marginLeft:'1%',backgroundColor:'#18202F',color:'white',fontSize:'0.9rem'}}> Add All</Button>
        <Button onClick={this.clearbuildingAll} style={{position:'absolute',top:'65%',padding:'1%',marginLeft:'1%',backgroundColor:'#18202F',color:'white',fontSize:'0.9rem'}}>Clear All</Button>
        
        </Grid.Column>
        <Grid.Column  className="csvbox" style={{width:'42.5%'}}>
          <h3>Selected Assets</h3>
         <div class="border" >{this.state.buildingselectedTags.length>0?this.state.buildingselectedTags.map((asset,index)=>(
          <Label key={index} style={{display:'block'}}>{asset['name']}<Icon name="close" onClick={()=>this.buildingremove(asset)}/></Label>)):null}</div>
                 <Button primary onClick={this.handlebuildOpen}style={{float:'right',fontSize:'16px',borderRadius:5,backgroundColor:'#18202F',color:'white',marginTop:'22%'}}>SUBMIT</Button>

        </Grid.Column>

      </Grid.Row>
         </Tab>    */}
              </Tabs>
            </Col>
          </Row>
        </Grid>

        <Modal open={this.state.infoOpen} onClose={this.handleClose} closeIcon>
          <Modal.Header style={{ backgroundColor: "white" }}>
            Confirmation
          </Modal.Header>
          <Modal.Content>
            <p>
              Do you want to upload CSV with these{" "}
              <Label>Optional Input </Label>?
            </p>
            <br />

            <Checkbox.Group
              style={{ paddingLeft: "1%" }}
              onChange={this.handleOptional}
            >
              <Checkbox value="Area"> Area</Checkbox>
              <br />
              <Checkbox value="Valuation"> Valuation</Checkbox>
              <br />
              <Checkbox value="Floors">Number of Floors</Checkbox>
              <Checkbox value="Solar">Solar Power Production (in gwh)</Checkbox>
              <Checkbox value="Wind">Wind Power Production (in gwh)</Checkbox>
              <Checkbox value="Hydro">Hydro Power Production (in gwh)</Checkbox>
              <Checkbox value="Construction Cost">
                Repairing / Construction Cost (in mil $)
              </Checkbox>

              <Checkbox value="Wheat">Wheat Production (in kg)</Checkbox>
              <Checkbox value="Rice">Rice Production (in kg)</Checkbox>
              <Checkbox value="Maize">Maize Production (in kg)</Checkbox>
              <Checkbox value="Fruit">Fruit Production (in kg)</Checkbox>
              <Checkbox value="Veg">Vegetable Production (in kg)</Checkbox>
              <Checkbox value="Ownership">OwnerShip</Checkbox>
            </Checkbox.Group>
            <br />
            <Button
              style={{
                float: "right",
                backgroundColor: "#18202F",
                color: "white",
              }}
              onClick={this.handleOpenDialog}
            >
              Submit
            </Button>
            <br />
            <br />
          </Modal.Content>
        </Modal>

        {/* <Modal
      open={this.state.buildinginfoOpen}
      onClose={this.handleClose}
      closeIcon
      >
      <Modal.Header style={{backgroundColor:'white'}}>Confirmation</Modal.Header>
          <Modal.Content>
            <p>Do you want to upload CSV with these  <Label >Optional Input </Label>?</p><br/>
           
      <Checkbox.Group style={{paddingLeft:'1%'}} onChange={this.handleOptional}>
      <Checkbox value="Area" > Area</Checkbox><br/>
      <Checkbox value="Valuation" > Valuation</Checkbox><br/>
      <Checkbox value="Floors">Number of Floors</Checkbox>
     


      


      </Checkbox.Group><br/>
      <Button style={{float:'right',backgroundColor:'#18202F',color:'white'}} onClick={this.handlebuildingOpen}>Submit</Button>
      <br/>
      <br/>
          </Modal.Content>
     </Modal>*/}
        <Modal
          open={this.state.restrictOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Error
          </Modal.Header>
          <Modal.Content>
            {/*<video autoPlay loop muted id="video">
                <source src={video} type="video/mp4"/>
              </video>*/}
            <p>
              You are only allowed to add{" "}
              {this.props.permissions.assets_allowed} assets for Analysis
            </p>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.videoOpen} onClose={this.handleClose} closeIcon>
          <Modal.Header style={{ backgroundColor: "white" }}>
            How to Upload CSV
          </Modal.Header>
          <Modal.Content>
            {/*<video autoPlay loop muted id="video">
                <source src={video} type="video/mp4"/>
              </video>*/}
            <Image src={video1} />
          </Modal.Content>
        </Modal>
        <Modal open={this.state.modalOpen} onClose={this.handleClose} closeIcon>
          <Modal.Header className="portfolio-header">
            Create Project
          </Modal.Header>
          <Modal.Content scrolling>
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Portfolio Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Project Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                </div>
              )}

              <br />
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <Button style={{ backgroundColor: "#18202F" }}>
                    <Spinner />
                  </Button>
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.onSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalIndicesOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header>Create Portfolio</Modal.Header>
          <Modal.Content scrolling>
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Portfolio Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Portfolio Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                </div>
              )}

              <br />
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <Button style={{ backgroundColor: "#18202F" }}>
                    {" "}
                    <Spinner />
                  </Button>
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.onIndicesSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>

        {/*  	<Modal
            open={this.state.buildingmodalOpen}
            onClose={this.handleClose}
            closeIcon
            
          >
            <Modal.Header>Create Portfolio</Modal.Header>
            <Modal.Content scrolling>
              	<Form>
				{(this.props.location.state)?
					<div>
						<p style={{marginLeft:'35%'}}>New Portfolio <Checkbox label="OverWrite Existing" value={this.state.overwrite} onChange={e=>this.setState({overwrite:!this.state.overwrite},()=>console.log("ovewrite",this.state.overwrite))} toggle/></p>
						{(this.state.overwrite)?<Form.Field control={Input} label='Portfolio Name' value={this.state.portfolio} onChange={e=>this.setState({portfolio:e.target.value})}/>:<Form.Field control={Input} label='Portfolio' defaultValue={this.props.location.state.assets.name} disabled/>}
					</div>:
          <div>
				<Form.Field 
					 id="form-input-control-portfolio"
					 control={Input}
					 
					 value={this.state.portfolio}
					 onChange={e=>this.setState({portfolio:e.target.value})}
					 style={{border:'0px'}}
					 label="Portfolio Name"
					 
					 />

         <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> 
         <Label style={{backgroundColor:'white',color:'#18202F',fontSize:'16px',padding:'0px'}}>Select Variables for Analysis</Label><br/><br/>
         <Checkbox.Group onChange={this.onChange}>
         <Checkbox checked value="solr_production">Solar Production</Checkbox>
         <Checkbox checked value="wind_production">Wind Production</Checkbox>
         <Checkbox checked value="wheat_production">Wheat Production</Checkbox><br/>
         <Checkbox value="rice_production">Rice Production</Checkbox>
         
         <Checkbox value="maize_production">Maize Production</Checkbox>
         <Checkbox value="fruit_production">Fruit Production</Checkbox>
         <Checkbox value="vegetable_production">Vegetable Production</Checkbox>
         
         </Checkbox.Group>
         <br/>
         
         
          </div>
}
				
				
				
				
				
			    <br/>
			    <br/>

				{(this.state.loading && this.props.errors.error==undefined)?<div style={{marginTop:'-40px'}}><PortfolioSpinner/></div>:
				<Button disabled={this.state.portfolio.length<1} style={{backgroundColor:'#18202F',marginLeft:'40%',color:'white'}} onClick={this.onbuildingSubmit} primary>Submit</Button>}
			</Form>
            </Modal.Content>
</Modal>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,
    locus: state.locus,
    company: state.company.company,
    permissions: state.locus.permissions,
    name: state.locus.name,
    point: state.locus.point,
    indices: state.locus.indices,
    transition: state.locus.transition,
    property: state.locus.property,
  };
};
const mapDispatchToPros = (dispatch) => {
  return {
    addLocations: (formdata) => {
      dispatch(locus.addLocations(formdata));
    },
    addBuildingLocations: (formdata) => {
      dispatch(locus.addBuildingLocations(formdata));
    },
    getCompany: () => {
      dispatch(company.getCompanies());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    changeTitle: (title) => {
      dispatch(changeTitle(title));
    },
    getName: (formdata) => {
      dispatch(locus.getName(formdata));
    },
    getCoordinates: (formdata) => {
      dispatch(locus.getCoordinates(formdata));
    },
    getIndices: (formdata) => {
      dispatch(locus.getIndices(formdata));
    },
    addIndex: (formdata) => {
      dispatch(locus.addIndex(formdata));
    },
    getTransition: (formdata) => {
      dispatch(locus.getTransition(formdata));
    },
    PropertyRisk: (formdata) => {
      dispatch(locus.PropertyRisk(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToPros)(Realestate);
