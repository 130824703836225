import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { locus } from "../actions";
import Badge from "react-bootstrap/Badge";

const LearningOptions = ({ keywords, setKeywords, actionProvider }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    // Fetch keywords data from the API
    setKeywords();
  }, [setKeywords]);

  useEffect(() => {
    // Check if any options are selected
    setSubmitButtonDisabled(selectedOptions.length === 0);
  }, [selectedOptions]);

  const handleTagClick = (tag) => {
    const newTags = selectedOptions.includes(tag)
      ? selectedOptions.filter((selectedTag) => selectedTag !== tag)
      : [...selectedOptions, tag];
    setSelectedOptions(newTags);
  };

  const handleButtonClick = () => {
    // Call the handler function with the selected options
    actionProvider.handleJavascriptList(selectedOptions);
  };

  return (
    <div
      className="learning-options-container"
      style={{ position: "relative" }}
    >
      {keywords &&
        keywords.map((keyword) => (
          <Badge
            key={keyword}
            variant={
              selectedOptions.includes(keyword) ? "primary" : "secondary"
            }
            style={{
              cursor: "pointer",
              fontSize: "1em",
              marginRight: "5px",
              marginBottom: "5px",
              padding: "5px 10px", // Add padding
              backgroundColor: selectedOptions.includes(keyword)
                ? "#18202F"
                : "",
              color: selectedOptions.includes(keyword) ? "#ffffff" : "", // Change text color
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#18202F"; // Change background color on hover
              e.target.style.color = "#ffffff"; // Change text color on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = selectedOptions.includes(keyword)
                ? "#18202F"
                : ""; // Reset background color
              e.target.style.color = selectedOptions.includes(keyword)
                ? "#ffffff"
                : ""; // Reset text color
            }}
            onClick={() => handleTagClick(keyword)}
          >
            {keyword}
          </Badge>
        ))}
      <button
        onClick={handleButtonClick}
        className="learning-options-button"
        style={{
          position: "absolute",
          bottom: "-210px",
          right: "10px",
          backgroundColor: "#18202F",
          color: "white",
          padding: "10px 20px",
          border: "none",
          opacity: submitButtonDisabled ? 0.5 : 1, // Adjust opacity

          cursor: "pointer",
        }}
        disabled={submitButtonDisabled}
      >
        Submit
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  keywords: state.locus.keyword.keywords,
});

const mapDispatchToProps = (dispatch) => ({
  setKeywords: () => dispatch(locus.fetchKeywords()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningOptions);
