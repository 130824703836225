import React, { useState, useCallback, useEffect, Component } from "react";
import LandingMap from "./landing_map";
import "./landingpage.css";
import {
  Tabs,
  Tab,
  Row,
  Nav,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import { Form } from "semantic-ui-react";
import Carousel from "react-bootstrap/Carousel";
import carousel1 from "../assets/carousel1.png";
import carousel2 from "../assets/carousel2.png";
import carousel3 from "../assets/carousel3.png";
import carousel4 from "../assets/carousel4.png";
import carousel5 from "../assets/carousel5.png";
import map_landing from "../assets/map_landing.jpg";
import news_1 from "../assets/landing_news_1.webp";
import news_2 from "../assets/landing_news_2.webp";
import news_3 from "../assets/landing_news_3.webp";
import geoData from "./geodata";
import HomeMap from "./homemap";
import data from "./numdata";

import "react-accessible-accordion/dist/fancy-example.css";
import { connect } from "react-redux";
import { Document, Page } from "react-pdf";
import { locus, auth, project } from "../actions";

const VariableOptions = [
  { key: "Extrme Heat", value: "extreme_heat", text: "Extreme Heat" },
  { key: "Landslide", value: "landslide", text: "Landslide" },
  { key: "Rainfall Flood", value: "rainfall_flood", text: "Rainfall Flood" },
  { key: "Sea Level Rise", value: "sea_level_rise", text: "Sea Level Rise" },
  { key: "Storm Surge", value: "storm_surge", text: "Storm Surge" },
  { key: "Typhoon", value: "typhoon", text: "Typhoon" },
];

const ScenarioOptions = [
  { key: "26", value: "26", text: "26" },
  { key: "45", value: "45", text: "45" },
  { key: "85", value: "85", text: "85" },
];

const YearOptions = [
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
];

class Landingpage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    variable: "typhoon",
    year: "2050",
    scenario: "85",
  };
  handleChangePro = (value, key) => {
    this.setState(
      {
        [key]: value,
      },
      () => console.log("variable", this.state.variable)
    );
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <Container fluid style={{ backgroundColor: "#18202F" }}>
          <Row>
            <br />
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={carousel1} height="800" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={carousel2} height="800" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={carousel3} height="800" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={carousel4} height="800" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={carousel5} height="800" />
          </Carousel.Item>
        </Carousel>
        <br />
        <Container fluid className="know_container">
          <h1>Know More about </h1>

          <Row>
            <Carousel>
              <Carousel.Item>
                <Row>
                  <Col>
                    <h2>CVaR</h2>
                    <p>
                      Conditional Value at Risk (CVaR) ,also known as the
                      expected shortfall ,is a risk assessment measure that
                      quantifies the amount of trail risk an investment
                    </p>
                  </Col>
                  <Col>
                    <h2>CVaR</h2>
                    <p>
                      Conditional Value at Risk (CVaR) ,also known as the
                      expected shortfall ,is a risk assessment measure that
                      quantifies the amount of trail risk an investment
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col>
                    <h2>CVaR</h2>
                    <p>
                      Conditional Value at Risk (CVaR) ,also known as the
                      expected shortfall ,is a risk assessment measure that
                      quantifies the amount of trail risk an investment
                    </p>
                  </Col>
                  <Col>
                    <h2>CVaR</h2>
                    <p>
                      Conditional Value at Risk (CVaR) ,also known as the
                      expected shortfall ,is a risk assessment measure that
                      quantifies the amount of trail risk an investment
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col>
                    <h2>CVaR</h2>
                    <p>
                      Conditional Value at Risk (CVaR) ,also known as the
                      expected shortfall ,is a risk assessment measure that
                      quantifies the amount of trail risk an investment
                    </p>
                  </Col>
                  <Col>
                    <h2>CVaR</h2>
                    <p>
                      Conditional Value at Risk (CVaR) ,also known as the
                      expected shortfall ,is a risk assessment measure that
                      quantifies the amount of trail risk an investment
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Row>
        </Container>
        <br />
        <Container fluid className="landingmap_container">
          <h1>Physical Risk Maps</h1>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3}>
              <p>Climate Variable</p>
              <Form.Select
                placeholder="Climate Variable"
                onChange={(e, { value }) =>
                  this.handleChangePro(value, "variable")
                }
                value={this.state.variable}
                options={VariableOptions}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={3}>
              <p>Scenario </p>
              <Form.Select
                placeholder="Scenario"
                onChange={(e, { value }) =>
                  this.handleChangePro(value, "scenario")
                }
                value={this.state.scenario}
                options={ScenarioOptions}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={3}>
              <p>Year</p>
              <Form.Select
                placeholder="Year"
                onChange={(e, { value }) => this.handleChangePro(value, "year")}
                value={this.state.year}
                options={YearOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {/*<LandingMap
                geoData={geoData}
                numData={data}
                width={1000}
                height={600}
    />*/}
              <HomeMap
                variable={this.state.variable}
                year={this.state.year}
                rcp={this.state.scenario}
              />
            </Col>
          </Row>
        </Container>
        <br />
        <Container fluid className="news_container">
          <h1>News</h1>
          <Row>
            <Col>
              <Row>
                {" "}
                <img src={news_1} />
              </Row>
              <Row>
                <h2></h2>
                <p>
                  Climate Tech company, Intensel, to join Taronga Ventures’ ESG
                  Impact innovation program in 2023
                  <br />
                  <a
                    target="_blank"
                    href="https://www.intensel.net/post/climate-tech-company-intensel-to-join-taronga-ventures-esg-impact-innovation-program-in-2023"
                  >
                    ...Read More
                  </a>
                </p>
              </Row>
            </Col>
            <Col>
              <Row>
                {" "}
                <img src={news_2} />
              </Row>
              <Row>
                <h2></h2>
                <p>
                  Intensel: Providing Cutting-Edge Climate Risk Intelligence
                  with Machine Learning
                  <br />
                  <a
                    target="_blank"
                    href="https://www.intensel.net/post/intensel-providing-cutting-edge-climate-risk-intelligence-with-machine-learning"
                  >
                    ...Read More
                  </a>
                </p>
              </Row>
            </Col>
            <Col>
              <Row>
                {" "}
                <img src={news_3} />
              </Row>
              <Row>
                <h2></h2>
                <p>
                  Intensel Selected as Top Ten Finalist for Accenture’s 2023
                  FinTech Innovation Lab APAC Program
                  <br />
                  <a
                    target="_blank"
                    href="https://www.intensel.net/post/intensel-selected-for-accenture-s-2023-fintech-innovation-lab-asia-pacific"
                  >
                    ...Read More
                  </a>
                </p>
              </Row>
            </Col>
          </Row>
        </Container>

        {/*<Container fluid className="events_container">
          <h1>Events</h1>
          <Carousel>
            <Carousel.Item>
              <Row>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {" "}
                    <img src={carousel2} width="200" height="150" />
                  </Row>
                  <Row>
                    <h2>Lorem Ipsum</h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book
                    </p>
                  </Row>
                </Col>
              </Row>
            </Carousel.Item>
          </Carousel>
    </Container>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insurance: state.project.insurance,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInsurance: (formdata) => {
      dispatch(project.getInsurance(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Landingpage);
